


































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'
import { omit } from 'lodash'

// Mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import WindowIsFocusMixin from '@/mixins/WindowIsFocusMixin'
// Components
import BriefNotifications from '@/components/notifications/BriefNotifications.vue'
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import MobileMenu from '@/components/layout/MobileMenu.vue'
import NotesDrawer from '@/components/layout/drawers/NotesDrawer.vue'
import NotificationsDrawer from '@/components/layout/drawers/NotificationsDrawer.vue'
import SidenavToggle from '@/components/layout/sidenavs/SidenavToggle.vue'
import UserCard from '@/components/cards/UserCard.vue'
// Store
import AuthModule from '@/store/modules/auth'
import MasterTutorialsModule from '@/store/modules/master/tutorials'
import NotificationModule from '@/store/modules/notification'
import SupportModule from '@/store/modules/support'
import SystemModule from '@/store/modules/system'
import Timer from '@/components/Timer.vue'
// Types
import { SelfResource, NewNotificationEvent, LocalStorageKeys } from '@/store/types'
import { UserCheckGetRequest } from '@/store/types/schema.progress'
// import { SelfResource } from '@/store/types'

const camelCaseKeys = require('camelcase-keys')

interface DeleteNotificationEvent {
  count: number,
  id: string[],
}

@Component({
  components: {
    BriefNotifications,
    ButtonArrow,
    MobileMenu,
    NotesDrawer,
    NotificationsDrawer,
    SidenavToggle,
    Timer,
    UserCard,
  },
})
export default class Header extends Mixins(NotifyMixin, WindowIsFocusMixin) {
  @Prop({ default: false })
  private isMobile!: boolean

  @Prop({ default: false })
  private isNewYear!: boolean

  @Prop({ default: false })
  private isShowEndYearAnimation!: boolean

  @Prop({ default: false })
  private isShowBirthdayAnimation!: boolean

  @Prop({ default: false })
  private isShowLoveDay!: boolean

  private get interface () {
    return SystemModule.interface
  }

  private get user () {
    return AuthModule.self as SelfResource
  }

  private get notificationsVisibility () {
    return NotificationModule.drawerVisibility
  }

  private get notesVisibility() {
    return SystemModule.notesVisibility
  }

  private get isTinyView() {
    if (this.$vuetify.breakpoint.name === 'xs') {
      return true
    }
    return false
  }

  private get notifyCount() {
    return AuthModule.notifyCount
  }

  private get isShowTimer() {
    return SystemModule.isShowTimer
  }

  private get isMainTourActive() {
    return MasterTutorialsModule.mainTutorialIsActive
  }

  private get mainTourStep() {
    return MasterTutorialsModule.mainTutorialStep
  }

  private get isMiniTourActive() {
    return MasterTutorialsModule.lessonExerciseTutorialActive
  }

  private get isCalendarTourStart() {
    return MasterTutorialsModule.calendarTutorialActive
  }

  private isSnowflakeActive = localStorage.getItem('snowflake') === null ? true : localStorage.getItem('snowflake') === '1'
  private isEndYearAnimationActive = localStorage.getItem('endYearAnimationActive') === null ? true : localStorage.getItem('endYearAnimationActive') === '1'
  private isBirthdayAnimationActive = localStorage.getItem('birthdayAnimationActive') === null ? true : localStorage.getItem('birthdayAnimationActive') === '1'
  private isLovedayAnimationActive = localStorage.getItem('lovedayAnimationActive') === null ? true : localStorage.getItem('lovedayAnimationActive') === '1'

  private sub: any = null

  private mounted () {
    this.sub = this.$centrifuge.newSubscription(`notifications.${this.user.id}`)
    this.sub.on('publication', (ctx: any) => {
      if (ctx.data?.event === 'Broadcast\\NewNotificationEvent') {
        const response = camelCaseKeys(omit(ctx.data, ['event', 'socket']), { deep: true }) as NewNotificationEvent

        if (response.notification.kind === 'messages') {
          const messageNormalized = response.notification
          // Если пользователь под мастером, находится в табе сообщений внутри дз, и по этому дз пришло уведомление, то не показывать его
          if (this.interface === 'master' &&
            this.$route.name === 'master.exercises.item.task.messages' &&
            this.$route.params.exerciseUUID === response.notification.values.masterExercise?.value &&
            this.$route.params.taskUUID === response.notification.values.masterTask?.value) {
            messageNormalized.isViewed = true
            NotificationModule.addMessage(messageNormalized)
            NotificationModule.readMessageMaster({
              exerciseUUID: this.$route.params.exerciseUUID,
              groupID: +this.$route.params.groupID,
              taskUUID: this.$route.params.taskUUID,
            })
            return
          }

          // Если пользователь под наставником, находится в табе сообщений внутри дз, и по этому дз пришло уведомление, то не показывать его
          if (this.interface === 'manager' &&
            this.$route.name === 'manager.education.exercises.item.task.master.messages' &&
            this.$route.params.exerciseUUID === response.notification.values.masterExercise?.value &&
            this.$route.params.taskUUID === response.notification.values.masterTask?.value) {
            messageNormalized.isViewed = true
            NotificationModule.addMessage(messageNormalized)
            NotificationModule.readMessageManager({
              groupID: +this.$route.params.groupID,
              masterID: +(response.notification.values.master?.value || 0),
              taskUUID: this.$route.params.taskUUID,
            })
            return
          }
        }

        AuthModule.setNotificationsCount(response.notificationsCount || 0)
        AuthModule.setMessagesCount(response.messagesCount || 0)

        if (response.notification) {
          const notificationNormalized = response.notification
          NotificationModule.addNotification(notificationNormalized)
          NotificationModule.addNotificationToBuffer(notificationNormalized)
          if (notificationNormalized.kind === 'news') {
            this.$bus.$emit('openNews', notificationNormalized.values.news)
          }

          if (notificationNormalized.kind === 'sales') {
            this.$bus.$emit('openSalesNews', { sales: notificationNormalized.values.sales, uuid: notificationNormalized.id })
          }
        }

        // Обновить список тикетов у мастера, когда он находится на этой странице (/master/support)
        if (response.notification.values.ticket && this.$route.name === 'master.support') {
          const tickets = SupportModule.tickets.data
          const findTicket = tickets.find(ticket => ticket.id === response.notification.values.ticket?.value)

          if (findTicket) {
            SupportModule.setTickets({
              data: [{ ...findTicket, hasNewMessages: true }, ...SupportModule.tickets.data.filter(ticket => ticket.id !== findTicket.id)],
              meta: SupportModule.tickets.meta,
            })
          }
        }

      } else if (ctx.data?.event === 'Broadcast\\DeleteNotificationEvent') {
        const response = omit(ctx.data, ['event', 'socket']) as DeleteNotificationEvent

        AuthModule.setNotificationsCount(response.count || 0)
        NotificationModule.deleteNotification(response.id)
      }
    })
    this.sub.subscribe()
  }

  private handleSwitchInterface () {
    this.$router.push({ name: this.interface === 'master' ? 'manager' : 'master' })
      .catch(() => {return})
  }

  private handleToggleNotificationsDrawer () {
    SystemModule.hideDrawer()
    NotificationModule.toggleDrawerVisibility()

    if (this.notificationsVisibility) {
      this.fetchNotifications()
      this.fetchMessages()
    }
  }

  private handleSnowflakeSwitch(value: boolean) {
    this.isSnowflakeActive = value
    this.$emit('handleSnowflakeSwitch', value)
  }

  private handleEndYearAnimationSwitch(value: boolean) {
    this.isEndYearAnimationActive = value
    this.$emit('handleEndYearAnimationSwitch', value)
  }

  private handleBirthdayAnimationSwitch(value: boolean) {
    this.isBirthdayAnimationActive = value
    this.$emit('handleBirthdayAnimationSwitch', value)
  }

  private handleLovedayAnimationSwitch(value: boolean) {
    this.isLovedayAnimationActive = value
    this.$emit('handleLovedayAnimationSwitch', value)
  }

  @Bind
  @Debounce(300)
  private fetchNotifications () {
    NotificationModule.fetchNotifications()
      .catch(this.notifyError)
  }

  @Bind
  @Debounce(300)
  private fetchMessages() {
    NotificationModule.fetchMessages()
      .catch(this.notifyError)
  }

  private handleToggleExercisesDrawer () {
    NotificationModule.hideDrawer()
    SystemModule.hideDrawer()
  }

  private handleToggleLessonsDrawer () {
    NotificationModule.hideDrawer()
    SystemModule.hideDrawer()
  }

  private handleToggleNotesDrawer() {
    NotificationModule.hideDrawer()
    SystemModule.toggleDrawerVisibility()
  }

  @Watch('$route.fullPath')
  private watchRoute (to: string) {

    /** обновляем информацию о последнем посещении мастером
     *  не чаще, чем раз в 30 минут */

    if (this.$route.path.startsWith('/master')){
      const lastVisit = localStorage.getItem(LocalStorageKeys.LAST_VISIT)
      const interval = 1800000 // 30 minutes

      if ((!lastVisit || (Date.now() - +lastVisit) > interval) && this.isWindowInFocus) {
        UserCheckGetRequest({ loading: false })
        localStorage.setItem(LocalStorageKeys.LAST_VISIT, Date.now().toString())
      }
    }

    if (to.indexOf('master') !== 1)
      SystemModule.hideDrawer()
  }
}
