import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import Cookies from 'js-cookie'

import store from '@/store'
import {
  AgreementsPostRequest,
  AgreementsPostResponse,
  AuthByTgRequest,
  AuthLogin,
  AuthLoginPostRequest,
  AuthLogoutPostRequest,
  AuthRegister,
  AuthRegisterPostRequest,
  AuthRegisterTokenPostRequest,
  AuthRestore,
  AuthRestorePostRequest,
  AuthRestoreTokenPostRequest,
  AuthToken,
  AuthVkPostRequest,
  CityGetRequest,
  ProfileAttachTelegramPatchRequest,
  ProfileSocialPatchRequest,
  SelfResource,
  SelfResourceAgreements,
  UserFlag,
  UserGetRequest,
  UserLargeResource,
  UserSetFlagPatchRequest,
  VkAuthLogin,
} from '@/store/types'
import { authTokenName } from '@/utils/services/config'

/**
 * Работа с авторизацией пользователя
 * - авторизация пользователя
 * - регистрация пользователя
 * - забыл пароль
 * - подтверждения регистрации и смены пароля
 * - получение пользователя
 *
 * Изменение настроек профиля описана в @/store/modules/profile
 */

@Module({
  dynamic: true,
  name: 'Auth',
  namespaced: true,
  store,
})
class Auth extends VuexModule {
  // ---------------------------- Token ---------------------------- >>

  token = ''

  @Mutation
  setToken (payload: string) {
    this.token = payload
    if (process.env.NODE_ENV === 'development') {
      Cookies.set(authTokenName, payload)
    }
  }

  // ---------------------------- User ---------------------------- >>

  self: SelfResource | null = null
  unreadTicketsCount = 0

  vkPayload: any = null
  isVKRedirect = false

  get isManager () {
    return this.self && this.self.permissions.length > 0
  }

  get notifyCount() {
    return this.self ? this.self.notificationsCount + this.self.messagesCount : 0
  }

  get isLocalTimezone() {
    return this.self?.isLocalTimezone ?? false
  }

  @Mutation
  setSelf (payload: SelfResource) {
    this.self = Object.assign({}, payload)
  }

  @Mutation
  setNotificationsCount (payload: number) {
    if (this.self) {
      this.self.notificationsCount = payload
    }
  }

  @Mutation
  setMessagesCount(payload: number) {
    if (this.self) {
      this.self.messagesCount = payload
    }
  }

  @Mutation
  setSelfFromProfile (payload: UserLargeResource) {
    if (this.self) {
      this.self = {
        ...this.self,
        avatar: payload.avatar ? payload.avatar.url : '',
        email: payload.email,
        name: payload.name,
        surname: payload.surname,
      }
    }
  }

  @Mutation
  setAgreements(payload: SelfResourceAgreements) {
    if (this.self) {
      this.self.agreements = payload
    }
  }

  @Mutation
  clearSelf () {
    this.self = null
  }

  @Mutation
  setUnreadTicketsCount(payload: number) {
    this.unreadTicketsCount = payload
  }

  @Mutation
  decreaseUnreadTicketsCount() {
    this.unreadTicketsCount--
  }

  @Action({ rawError: true })
  async fetchUser(updateProfile = false) {
    if (!this.self || updateProfile) {
      const { data } = await UserGetRequest()

      this.setSelf(data)
      this.setUnreadTicketsCount(data.ticketMessagesCount)

      return data
    }

    return this.self
  }

  // Запрос на получение пользователя после успешной привязки ВК
  @Action({ rawError: true })
  async getUser() {
    const { data } = await UserGetRequest()

    this.setSelf(data)
    this.setUnreadTicketsCount(data.ticketMessagesCount)

    return data
  }

  // ---------------------------- Authorization ---------------------------- >>

  @Action({ rawError: true })
  async login (payload: AuthLogin) {
    const { data } = await AuthLoginPostRequest({
      ...payload,
      email: payload.email.trim(),
      password: payload.password.trim(),
    })

    await this.setSelf(data.user)
    await this.setToken(data.token)

    return data.user
  }

  @Action({ rawError: true })
  async registration (payload: AuthRegister) {
    const { data } = await AuthRegisterPostRequest({
      ...payload,
      email: payload.email.trim(),
      password: payload.password.trim(),
      passwordConfirmation: payload.passwordConfirmation.trim(),
    })

    await this.setSelf(data.user)
    await this.setToken(data.token)

    return data.user
  }

  @Action({ rawError: true })
  async registrationConfirmation (payload: AuthToken) {
    const { data } = await AuthRegisterTokenPostRequest(payload)

    await this.setSelf(data.user)
    await this.setToken(data.token)

    return data
  }

  @Action({ rawError: true })
  async logout () {
    const { data } = await AuthLogoutPostRequest()

    return data
  }

  @Action({ rawError: true })
  async agreement(payload: AgreementsPostResponse) {
    const { data } = await AgreementsPostRequest(payload)

    this.setAgreements(data.agreements)

    return data
  }

  // ---------------------------- VKontakte ---------------------------- >>
  @Mutation
  setVKPayload (payload: string) {
    if (payload?.length) {
      this.vkPayload = JSON.parse(payload)
      this.isVKRedirect = true
    }
  }

  @Mutation
  resetVKPayload () {
    this.vkPayload = null
    this.isVKRedirect = false
  }

  @Action({ rawError: true })
  async loginVK(payload: VkAuthLogin) {
    const { data } = await AuthVkPostRequest(payload)

    await this.setSelf(data.user)
    await this.setToken(data.token)

    const res: { isNewUser?: boolean, user: SelfResource } = {
      user: data.user,
    }

    if (data.isNewUser)
      res.isNewUser = data.isNewUser

    return res
  }

  @Action({ rawError: true })
  async patchVK(payload: VkAuthLogin) {
    const { data } = await ProfileSocialPatchRequest(payload)

    return data
  }

  @Action({ rawError: true })
  async patchTG(payload: AuthByTgRequest) {
    const { data } = await ProfileAttachTelegramPatchRequest(payload)

    return data
  }

  // ---------------------------- Restore password ---------------------------- >>

  @Action({ rawError: true })
  async restore (payload: AuthRestore) {
    const { data } = await AuthRestorePostRequest(payload)

    return data
  }

  @Action({ rawError: true })
  async restoreConfirmation (payload: AuthRestore) {
    await AuthRestoreTokenPostRequest(payload)
  }

  // -------------------------------- Tutorial ---------------------------------- >>

  @Action({ rawError: true })
  async completeTour() {
    const { data } = await UserSetFlagPatchRequest({ flag: UserFlag.BASE_TUTORIAL_PASSED }, { loading: false })
    this.setSelf(data)
    return data
  }

  @Action({ rawError: true })
  async completeLessonExerciseTour() {
    const { data } = await UserSetFlagPatchRequest({ flag: UserFlag.NEW_MONTH_TUTORIAL_PASSED },{ loading: false })
    this.setSelf(data)
    return data
  }

  @Action({ rawError: true })
  async completeCalendarTour() {
    const { data } = await UserSetFlagPatchRequest({ flag: UserFlag.CALENDAR_TUTORIAL_PASSED },{ loading: false })
    this.setSelf(data)
    return data
  }

  // -------------------------------- New Year ---------------------------------- >>
  @Action({ rawError: true })
  async completeNewYearModal() {
    const { data } = await UserSetFlagPatchRequest({ flag: UserFlag.NEW_YEAR_MODAL_WINDOW_PASSED }, { loading: false })
    this.setSelf(data)
    return data
  }

  // -------------------------------- Love Day ---------------------------------- >>
  @Action({ rawError: true })
  async completeLoveDayModal() {
    const { data } = await UserSetFlagPatchRequest({ flag: UserFlag.VALENTINE_DAY_MODAL_WINDOW_PASSED }, { loading: false })
    this.setSelf(data)
    return data
  }

  // -------------------------------- City User ---------------------------------- >>
  @Action({ rawError: true })
  async fetchUserCity() {
    const { data } = await CityGetRequest()
    return data
  }
}

const AuthModule = getModule(Auth)

export default AuthModule
