















import { Component, Prop, Vue } from 'vue-property-decorator'

// store
import ProfileModule from '@/store/modules/profile'
// utils
import { isNewYear, isShowLoveDay } from '@/utils/constants'

@Component
export default class AvatarList extends Vue {
  @Prop({ required: true })
  private value!: number

  private get avatarList() {
    return ProfileModule.avatarList
  }

  private get isNewYear() {
    return isNewYear
  }

  private get isShowLoveDay() {
    return isShowLoveDay
  }
}
