/**
 * Этот файл сгенерирован автоматически. Не вносите изменения вручную.
 */

import { request } from '@/utils/services/client'

export interface CourseFolderListResource {
  id: number,
  title: string,
  folderCount: number,
  filesCountTotal: number,
}

export interface SaveFolderRequest {
  media?: number[],
  folder: string,
  parentId?: number | null,
}

export interface ShortKnowledgeResource {
  id: number,
  folder: string,
  parentId: number | null,
  media: MediaResource[],
}

export interface CreateFolderRequest {
  folder: string,
  parentId?: number,
  media?: number[],
  courseFolderId?: number,
}

export interface LargeKnowledgeResource {
  id: number,
  folder: string,
  parentId: number | null,
  courseFolderId: number,
  media: MediaResource[],
}

export interface KnowledgeTreeResource {
  id: number,
  title: string,
  parentId: number,
  isSystem: number,
  courseFolderId: number,
  children: KnowledgeTreeResource[],
}

export interface KnowledgeCoursesResource {
  id: number,
  title: string,
  subject: string,
  usefulMaterials: number,
  trainingFiles: number,
}

export interface KnowledgeCoursesPaginatorResource {
  meta: PaginatorMeta,
  data: KnowledgeCoursesResource[],
}

export interface CourseFoldersPaginator {
  meta: PaginatorMeta,
  data: CourseFoldersStaffResource[],
}

export interface CourseFoldersStaffResource {
  id: number,
  title: string,
  foldersCount: number,
  filesTotal: number,
}

export interface AgreementsPostResponse {
  agreements: AgreementsTypeEnum[],
}

export interface LessonCloneData {
  courseId: number,
  spendingAt: string,
  ignorePubDate?: boolean,
  startPublishedAt?: string,
  title?: string,
}

export interface UserOrderStatsResponse {
  years: number[],
}

export interface UserNotificationResource {
  notifications: NameValueContainerResource[],
  checked: string[],
  telegramChecked: string[],
	isDisabledSite: boolean,
	isDisabledTelegram: boolean,
}

export interface UserNotificationsRequest {
	checked: string[],
	telegramChecked: string[],
	isDisabledSite: boolean,
	isDisabledTelegram: boolean,
}

export interface CourseSubjectLargeResource {
  id: number,
  title: string,
  alias: string,
  ambulanceUrl?: string,
}

export interface SaleResourcePaginator {
  meta: PaginatorMeta,
  data: SaleResource[],
}

export interface SaleTargetCource {
  id: number,
  title: string,
  price: number,
  originalPrice: number,
}

export interface SaleBannerResource {
  bannerText: string,
  salesEndAt: string,
  priceType: SalePriceType,
  salePrice: number,
}
export interface SaleNotificationResource {
  salesEndAt: string,
  priceType: SalePriceType,
  salePrice: number,
}


export interface SaleResource {
  id: number,
  title: string,
  salesStartAt: string,
  salesEndAt: string,
  salePrice: number,
  sourceType: NameValueResource,
  targetType: NameValueResource,
  sources: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>,
  targets: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>,
  isRequiredWhenBuy: boolean,
  priceType: SalePriceType,
  createdAt: string,
  salesSourceAt: string|null,
  status: SaleStatus,
  handleSalesSourceAt: boolean,
  bannerText: string,
}

export interface OrderSaleLargeResource {
  id: number,
  title: string,
  salesStartAt: string,
  salesEndAt: string,
  salePrice: number,
  sourceType: NameValueResource,
  targetType: NameValueResource,
  sources: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>,
  targets: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>,
  isRequiredWhenBuy: boolean,
  priceType: SalePriceType,
  createdAt: string,
  salesSourceAt: string|null,
  status: SaleStatus,
  handleSalesSourceAt: boolean,
  bannerText: string,
  realDiscount: number,
  isApplied: boolean,
}

export interface SalePackageUnitResource {
  id: number,
  title: string,
  groupType: NameValueResource,
  price: number,
  subject: NameValueResource,
  months: NameValueResource[],
  course: NameValueTypeResource,
}

export interface SaleCourseUnitResource {
  id: number,
  title: string,
  subject: NameValueResource,
  groups: GroupShortResource[],
  educationStartAt: string,
  educationEndAt: string,
}

export interface SaleUnitsListResource {
  units: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>,
}

export interface ManualSaleResource {
  sale: SaleResource|null,
  totalPrice: number|null,
  salePrice: number|null,
  saleSourceCourse: string,
}

export interface SaleStoreData {
  title?: string,
  salesStartAt: string,
  salesEndAt: string,
  salePrice: number,
  isRequiredWhenBuy: boolean,
  sourceType: SaleUnitType,
  targetType: SaleUnitType,
  sourceIds: number[],
  targetIds: number[],
  priceType: SalePriceType,
  salesSourceAt?: string,
  handleSalesSourceAt: boolean,
  bannerText: string,
}

export interface UnitsRequest {
  type: SaleUnitType,
  query?: string|null,
  subjectIds?: number[]|null,
  groupTypes?: string[]|null,
  courseIds?: number[],
}

export interface ProgressResourcePaginator {
  meta: PaginatorMeta,
  data: ExerciseMasterResource[],
}

export interface ConstantResource {
  currentDateTime: string,
}

export interface NewNotificationEvent {
  notification: NotificationResource,
  notificationsCount: number,
  messagesCount: number,
}

export interface NotificationResourcePaginator {
  meta: PaginatorMeta,
  data: NotificationResource[],
}

export interface NotificationResource {
  id: string,
  message: string,
  isViewed: boolean,
  kind: string,
  placeholders: NotificationPlaceholderType[],
  values: NotificationValuesResource,
  createdAt: string,
}

export interface NotificationValuesResource {
  course?: NameValueResource,
  masterExercise?: NameValueResource,
  mentorExercise?: NameValueResource,
  hearts?: NameValueResource,
  oldMasterGroup?: NameValueResource,
  newMasterGroup?: NameValueResource,
  masterGroup?: NameValueResource,
  mentorLesson?: NameValueResource,
  masterLesson?: NameValueResource,
  ticket?: NameValueResource,
  month?: NameValueResource,
  master?: NameValueResource,
  mentor?: NameValueResource,
  manager?: NameValueResource,
  news?: NewsShortResource,
  masterTask?: NameValueResource,
  mentorTask?: NameValueResource,
  sales?: SaleNotificationResource,
}

export interface MasterGroupNameValueResource {
  name: string,
  value: number | string,
  groupType: NameValueResource,
}

export interface LessonEntityResourceContainer {
  name: string,
  values: LessonEntityResource[],
}

export interface LessonEntityResource {
  id: number,
  title: string,
  description: string,
  spendingAt: string,
  deadlineAt: string,
  ratedCount: number,
  mastersCount: number,
  checkedCount: number,
  hasVideo: boolean,
  isWebinar: boolean,
}

export interface MasterDashboardResource {
  masterNeedNextMonthBlock?: DashboardMasterNeedNextMonthBlockResource,
  educationStatsBlock: DashboardEducationStatsBlockResource,
  educationLessonBlock: DashboardEducationLessonBlockResource,
  educationExerciseBlock: DashboardEducationExerciseBlockResource,
  masterGroupBlock: DashboardMasterGroupBlockResource,
  ambulanceBlock?: AmbulanceResource,
  recurringPaymentBlock?: RecurringPaymentBlockResource,
}

export interface RecurringPaymentBlockResource {
  nextPaymentDate: string,
  courseId: number,
}

export interface AmbulanceResource {
  id: number,
  title: string,
  alias: string,
  url: string,
}

export interface DashboardMasterNeedNextMonthBlockResource {
  month: string,
  courseId: number,
  isExpired: boolean,
}

export interface ManagerDashboardResource {
  masterGroupBlock?: DashboardMasterGroupBlockResource,
  managerLessonBlock?: LessonShortResource[],
  managerExerciseBlock: DashboardManagerExerciseBlockResource[],
  lostHeartBlock: DashboardLostHeartBlockResource[],
}

export interface DashboardLostHeartBlockResource {
  user: UserShortResource,
  exercise: ExerciseShortResource,
  hearts: number,
}

export interface DashboardManagerExerciseBlockResource {
  id: number,
  uuid: string,
  title: string,
  tasks: DashboardEducationExerciseBlockTasksResource[],
  isSpending: boolean,
  isVerificationPassed: boolean,
  verificationAt: string,
  spendingAt: string,
  lostHearts: number,
  isDeadlinePassed: boolean,
  accessLevel: NameValueResource,
  deadlineAt: string,
  lostHeartsCount: number,
  newMessagesCount: number,
  checked: number,
  onCheck: number,
  wait: number,
  completed: number,
}

export interface DashboardMasterGroupBlockResource {
  id: number,
  title: string,
  groupUrl: string | null,
  type: GroupType,
  chatUrl: string | null,
  mentor?: UserShortResource,
  seniors: UserShortResource[],
}

export interface DashboardEducationExerciseBlockResource {
  completedCount: number,
  exercises: DashboardEducationExerciseBlockExerciseResource[],
}

export interface DashboardEducationExerciseBlockExerciseResource {
  id: number,
  uuid: string,
  title: string,
  tasks: DashboardEducationExerciseBlockTasksResource[],
  isSpending: boolean,
  spendingAt: string,
  lostHeart: string,
  isDeadlinePassed: boolean,
  deadlineAt: string,
  position: number,
}

export interface DashboardEducationExerciseBlockTasksResource {
  id: number,
  title: string,
  uuid: string,
  type: NameValueResource,
}

export interface DashboardEducationExerciseBlockTasksTypeResource {
  name: string,
  value: string,
}

export interface DashboardEducationLessonBlockResource {
  checkedCount: number,
  totalCount: number,
  lessons: DashboardEducationLessonBlockLessonResource[],
}

export interface DashboardEducationLessonBlockLessonResource {
  id: number,
  position?: number,
  title: string,
  spendingAt: string,
  isSpending?: boolean,
  isDone?: boolean,
  broadcastUrl?: string,
  hasVideo: boolean,
  video?: MediaResource,
}

export interface DashboardEducationStatsBlockResource {
  heartsCount: number,
  checkedLessons: number,
  totalLessons: number,
  availableLessons: number,
  completeExercises: number,
  totalExercises: number,
  availableExercises: number,
  progressValue: number,
}

export interface SmartSearchFilter {
  status: OrderStatus,
  courseId?: number,
  groupId?: number,
  monthIds?: number[],
}

export interface SmartSearchRequest {
  type: SearchType,
  filters?: SmartSearchFilter[],
  operator?: SearchOperator,
  name?: string,
  phone?: string,
  cityId?: number,
  email?: string,
  vkId?: string,
  tgUsername?: string,
}

export interface CalendarFiltersData {
  filters: CalendarFilter[],
}

export interface CalendarFilter {
  masterGroupId: number,
  entities: CalendarEntityType[],
}

export interface CalendarPageResource {
  lesson: CalendarPageLessonResource[],
  exercise: CalendarPageExerciseResource[],
  usefulMaterial: CalendarPageMaterialResource[],
}

export interface CalendarPageItemResource {
  id: number,
  title: string,
  type: CalendarEntityType,
  isPassed: boolean,
  spendingAt: string,
  subjectId: number,
  isAvailable: boolean, // если месяц, к которому относится сущность, оплачен то true иначе false
}

export interface CalendarPageExerciseResource {
  id: number,
  title: string,
  type: CalendarEntityType,
  isPassed: boolean,
  spendingAt: string,
  subjectId: number,
  isAvailable: boolean, // если месяц, к которому относится сущность, оплачен то true иначе false
  masterGroup: NameValueResource,
  deadlineAt: string,
  uuid: string,
  courseType: CourseType, // Нужен для правильного построения ссылки на ДЗ
  tasks: NameValueResource[],
}

export interface CalendarPageLessonResource {
  id: number,
  title: string,
  type: CalendarEntityType,
  isPassed: boolean,
  spendingAt: string,
  subjectId: number,
  isAvailable: boolean, // если месяц, к которому относится сущность, оплачен то true иначе false
  masterGroup: NameValueResource,
}

export interface CalendarPageMaterialResource {
  id: number,
  title: string,
  type: CalendarEntityType,
  isPassed: boolean,
  spendingAt: string,
  subjectId: number,
  isAvailable: boolean, // если месяц, к которому относится сущность, оплачен то true иначе false
  courseId: number,
}

export interface PromotionShortResourcePaginator {
  meta: PaginatorMeta,
  data: PromotionShortResource[],
}

export interface PromotionShortResource {
  id: number,
  name: string,
  count: number,
  startAt: string,
  endAt: string,
  discount: number,
  discountType: NameValueResource,
  productType: NameValueResource,
}

export interface PromotionLargeResource {
  id: number,
  name: string,
  count: number,
  discount: number,
  startAt: string,
  endAt: string,
  discountType: NameValueResource,
  productType: NameValueResource,
  courses: CourseShortResource[],
  packages: PackageShortResource[],
  codes: PromotionCodeResource[],
}

export interface OrderPromotionShortResource {
  id: number,
  name: string,
  startAt: string,
  endAt: string,
  discount: number,
  discountType: NameValueResource,
  productType: NameValueResource,
}

export interface OrderCodeResource {
  id: number,
  code: string,
  promotion: PromotionShortResource,
  type: NameValueResource,
  realDiscount: number,
}

export interface PromotionCodeResource {
  id: number,
  code: string,
  status: boolean,
  type: NameValueResource,
  users: UserShortResource[],
}

export interface PromotionUserResource {
  id: number,
  name: string,
  surname: string,
}

export interface PromotionData {
  name: string,
  discountType: PromotionDiscountType,
  productType: PromotionProductType,
  discount: number,
  startAt?: string,
  endAt?: string,
  productIds: number[],
  codes: PromotionCodeData[],
}

export interface PromotionCodeData {
  id?: number,
  code: string,
  type: PromoCodeType,
}

export interface EducationCourseShortResource {
  closedForSales: boolean,
  id: number,
  title: string,
  annotation: string,
  educationStartAt: string,
  educationEndAt: string,
  salesEndAt: string,
  salesEndPassed: boolean,
  hiddenAt: string,
  createdAt: string,
  publishedAt?: string,
  type: NameValueResource,
  groupTypes: NameValueResource[],
  materials: number,
  subject: NameValueResource,
  masterGroup: NameValueResource,
  groupType: NameValueResource,
  notPassedExercises: number,
  notCheckedLessons: number,
  extendedTo: string,
  hasProlongation: boolean,
  teachers: TeacherShortResource[],
}

export interface EducationMasterGroupResourceDates {
  start: string,
  end: string,
  open: string | null,
  assigningStartAt: string|null,
  assigningEndAt: string|null,
}

export interface EducationMasterGroupResource {
  id: number,
  title: string,
  course: CourseLabelResource,
  subject: NameValueResource,
  groupType: NameValueResource,
  months: NameValueResource[],
  dates: EducationMasterGroupResourceDates,
  dashboardShow: boolean,
  position: number | null,
  isVisible: boolean,
  hasMentor: boolean,
}

export interface CalendarLargeResource {
  lesson: CalendarLargeItemResource[],
  exercise: CalendarLargeItemResource[],
  usefulMaterial: CalendarLargeItemResource[],
}

export interface CalendarLargeItemResource {
  id: number,
  uuid: string,
  title: string,
  type: CalendarEntityType,
  materialType?: string,
  spendingAt: string,
  deadlineAt: string,
  isWebinar?: boolean,
  exerciseAccessLevel?: ExerciseAccessLevel,
  tasks?: TaskLabelResource[],
  isPassed: boolean,
}

export interface JournalLargeResource {
  hearts: number,
  maxPoints: number,
  points: number,
  totalCount: number,
  checkedCount: number,
  exercises: JournalLargeExerciseResource[],
}

export interface ManagerJournalRowResource {
  user: UserShortResource,
  hearts: number,
  exercises: JournalLargeExerciseResource[],
}

export interface JournalLargeExerciseResource {
  id: number,
  uuid?: string,
  title: string,
  newMessagesCount?: number,
  deadlineAt: string,
  maxPoints: number,
  isDeadlinePassed?: boolean,
  accessLevel: NameValueResource,
  tasks: TaskLabelResource[],
  points: number,
  status: NameValueResource,
  lostHeart?: string,
}

export interface TaskLabelResource {
  id: number,
  title: string,
  uuid: string,
  type: NameValueResource,
}

export interface ManagerEducationCodeExecuteData {
  questionId: number,
  formId: number,
}

export interface MasterEducationCodeExecuteData {
  questionId: number,
  code: string|null,
}

export interface CodeExecutionResult {
  success: boolean,
  result: string,
}

export interface MentorContentData {
  formId: number,
  content: string,
}

export interface EducationAnswerRateComment {
	questionId: number,
  answerLine?: number|null,
	mentorComment: string|null,
  uuid?: string|null,
}

export interface EducationRateMasterData {
  draftHash: string | null,
  points: string,
  comment: string,
  answer: string,
  mediaIds: number[],
  mentorRateComments: EducationAnswerRateComment[],
  content?: string,
  sendKey?: boolean,
  answersRatePoints?: EducationAnswerRate[]|null,
}

export interface EducationAnswerRate {
  questionId: number,
  points: number,
}

export interface MasterWhoCompleteTaskResource {
  id: number,
  name: string,
  surname: string,
  onCheckAt: string,
}

export interface ExerciseMasterResource {
  id: number,
  name: string,
  lostHeart?: string,
  surname: string,
  hasSocials: boolean,
  socials: SocialResource[],
  avatar: string,
  hearts: number,
  status: NameValueResource,
  points: number,
  newMessagesCount: number,
  onCheckAt: string | null,
  hasMentor?: boolean,
}

export interface ExerciseManagerCardResourceContainer {
  name: string,
  values: ExerciseManagerCardResource[],
}

export interface ExerciseMasterCardResourceContainer {
  name: string,
  values: ExerciseMasterCardResource[],
}

export interface ExerciseMasterCardResource {
  id: number,
  title: string,
  uuid: string,
  spendingAt: string,
  deadlineAt: string,
  avgExecutionSeconds: number | null,
  isDeadlinePassed: boolean,
  isTemplate: boolean,
  isProbe: boolean,
  subject: NameValueResource,
  accessLevel: NameValueResource,
  tasks: ExerciseMasterCardTasksResource[],
  questionsCount: number,
  materialsCount: number,
  lostHeart: string,
  status: NameValueResource,
  points: number,
  maxPoints: number,
  autoCheck: boolean,
}

export interface ExerciseMasterCardTasksResource {
  id: number,
  title: string,
  uuid: string,
  type: NameValueResource,
  questionsCount: number,
  mediaCount: number,
  comment: string,
  newMessagesCount: number,
  maxPoints: number,
  points: number,
}

export interface ExerciseManagerCardResource {
  id: number,
  uuid: string,
  spendingAt: string,
  course: CourseLabelResource,
  deadlineAt: string,
  verificationAt: string,
  isVerificationPassed: boolean,
  isDeadlinePassed: boolean,
  accessLevel: NameValueResource,
  title: string,
  tasks: TaskShortResource[],
  newMessagesCount: number,
  lostHearts: number,
  checked: number,
  onCheck: number,
  wait: number,
  completed: number,
  autoCheck: boolean,
}

export interface TaskMessageResource  {
  id: number,
  author: UserShortResource,
  type: string | null,
  message: string,
  parentId: number,
  createdAt: string,
  isEdited: boolean,
  media: MediaResource[],
  reaction: string|null,
  reactionAuthorId: number|null,
}

export interface TicketMessageResource {
  id: number,
  author: UserShortResource,
  message: string,
  parentId: number,
  createdAt: string,
  media: MediaResource[],
}

export interface ExerciseProgressResource {
  id: number,
  status: NameValueResource,
  lostHeart: string,
  completedAt: string | null,
  onCheckAt: string | null,
  workStartedAt: string | null,
}

export interface StartWorkingOnExerciseResource {
  workStartedAt: string,
}

export interface EducationLargeExerciseResource {
  id: number,
  title: string,
  monthId: number,
  spendingAt: string,
  uuid: string,
  deadlineAt: string,
  verificationAt: string,
  avgExecutionSeconds: number,
  isDeadlinePassed: boolean,
  course: CourseLabelResource,
  isVerificationPassed: boolean,
  isTemplate: boolean,
  forAllMasters: boolean,
  questionsCount: number,
  subject: NameValueResource,
  accessLevel: NameValueResource,
  isProbe: boolean,
  tasks: TaskShortResource[],
  materialsCount: number,
  autoCheck: boolean,
  original: NameValueResource,
  isTimerEnabled: boolean,
  progress: ExerciseProgressResource,
}

export interface EducationDrawerExerciseResource {
  id: number,
  uuid: string,
  title: string,
  lostHeart?: string,
  tasks: TaskLabelResource[],
  spendingAt: string,
  deadlineAt: string,
  status: NameValueResource,
}

export interface EducationLargeTaskQuestionAnswerResource {
  id: number,
  text: string,
  position: number,
  rightPosition?: number,
  isCorrect: string,
  sequencePosition?: string,
}

export interface EducationSpeakingSubQuestionResource {
  id: number,
  questionId: number,
  text?: string,
  required: boolean,
  position: number,
  media?: MediaResource,
}

export interface EducationLargeTaskQuestionSequenceResource {
  id: number,
  text: string,
  position: number,
}

export interface EducationLargeTaskQuestionUserAnswerResource {
  value: number,
  sequenceId: number,
}

export interface EducationLargeTaskSpeakingQuestionUserAnswerResource {
  id: number,
  questionId: number,
  media: MediaResource,
}

export interface EducationLargeTaskTestQuestionResource {
  id: number,
  uuid: string,
  question: string,
  type: NameValueResource,
  rateType: NameValueResource,
  points: number,
  oneErrorPoints: number,
  manyErrorPoints: number,
  media: MediaResource[],
  hintAnswer?: string,
  hintQuestion?: string,
  answers: EducationLargeTaskQuestionAnswerResource[],
  sequences: EducationLargeTaskQuestionSequenceResource[],
  nameAnswerTable?: string,
  nameSequenceTable?: string,
  ratePoints: number,
  userAnswers: EducationLargeTaskQuestionUserAnswerResource[],
  displayType?: string,
  relatedUuid: string | null,
  position: number,
}

export interface EducationLargeTaskSpeakingQuestionResource {
  id: number,
  taskId: number,
  question: string,
  text?: string,
  type: SpeakingQuestionType,
  position: number,
  media: MediaResource[],
  questions: EducationSpeakingSubQuestionResource[],
  answers: EducationLargeTaskSpeakingQuestionUserAnswerResource[],
  rateComment: string | null,
}

export interface EducationLargeTaskPracticeQuestionResource {
  id: number,
  question: string,
  ratePoints: number,
  maxPoints: number,
  rightAnswer: string|null,
  position: number,
  displayedPosition: number,
  hintQuestion?: string,
  media: MediaResource[],
}

export interface EducationLargeTaskCodingQuestionResource {
  id: number,
  uuid: string,
  question: string,
  maxPoints: number,
  position: number,
  displayedPosition: number,
  hintQuestion: string|null,
  media: MediaResource[],
  userAnswers?: CodingUserAnswerResource[],
  rateComments?: CodingRateCommentResource[],
  hintAnswer: string|null,
  ratePoints?: number|null,
}

export interface CodingUserAnswerResource {
  questionId: number,
  answer: string,
}

export interface CodingRateCommentResource {
  uuid: string,
  questionId: number,
  answerLine: number,
  mentorComment: string,
}

export interface EducationLargeTaskResource {
  id: number,
  isDeadlinePassed: boolean,
  isDeadlineFailed: boolean,
  dialogInitialized: boolean,
  comment?: string,
  uuid: string,
  title: string,
  description: string,
  formId?: number|null,
  masterAnnotation?: string,
  managerAnnotation?: string,
  annotation?: string,
  type: NameValueResource,
  maxPoints: number,
  lostHeart?: string | null,
  verificationHours: number,
  verificationAt: string,
  isTimerEnabled: boolean,
  onCheckAt: string | null,
  workStartedAt: string | null,
  createdAt: string,
  exerciseStatus?: NameValueResource,
  status: NameValueResource,
  media: MediaResource[],
  exerciseUuid: string,
  points: number,
  isCured: boolean,
  hasKey?: boolean,
  canBeRefused: boolean,
  content: string,
  hasDraft?: boolean,
  draftHash?: string,
  dataTimestamp: number,
  completedAt?: string,
  ratedAt?: string,
  test?: EducationLargeTaskTestResource,
  practice?: EducationLargeTaskPracticeResource,
  speaking?: EducationLargeTaskSpeakingResource,
  coding?: EducationLargeTaskCodingResource,
  results: EducationLargeTaskResultResource[],
  forms: EducationLargeTaskFormResource[],
}

export interface EducationLargeTaskFormResource {
  id: number,
  questions: number,
  correct: number,
  rate: number,
  completedAt: string,
  ratedAt: string,
  isWorkOnMistakesRequired: boolean,
}

export interface EducationLargeTaskRepetitionResource {
  questions: EducationLargeTaskTestQuestionResource[],
  firstRepetition: boolean,
}

export interface EducationLargeTaskTestResource {
  questions: EducationLargeTaskTestQuestionResource[],
}

export interface EducationLargeTaskPracticeResource {
  questions: EducationLargeTaskPracticeQuestionResource[],
}

export interface EducationLargeTaskCodingResource {
  questions: EducationLargeTaskCodingQuestionResource[],
}

export interface EducationLargeTaskSpeakingResource {
  questions: EducationLargeTaskSpeakingQuestionResource[],
}

export interface EducationLargeTaskResultResource {
  id: number,
  comment?: string,
  media: SortableMediaResource[],
  createdAt?: string,
}

export interface EducationLargeTaskResultRequest {
  comment?: string,
  media: SortableMediaData[],
}

export interface SortableMediaData {
  id: number,
  position: number|null,
}

export interface VisibleMasterGroupRequest {
  courseId: number,
  position: number,
  isVisible: boolean,
}

export interface VisibleCourseRequest {
  courses: VisibleMasterGroupRequest[],
}

export interface EducationLargeTaskDecideRepetitionQuestionRequest {
  questions: EducationLargeTaskDecideQuestionRequest[],
}

export interface EducationLargeTaskDecideQuestionRequest {
  id: number,
  answers: EducationLargeTaskDecideQuestionAnswerRequest[],
}

export interface EducationLargeTaskDecideCodingQuestionRequest {
  questionId: number,
  answer: string|null,
}

export interface EducationLargeTaskDecideSpeakingQuestionRequest {
  id: number,
  type: SpeakingQuestionType,
  answers: EducationLargeTaskDecideSpeakingQuestionAnswerRequest[],
}

export interface EducationLargeTaskDecideQuestionAnswerRequest {
  value?: number | string,
  sequenceId?: number,
}

export interface EducationLargeTaskDecideSpeakingQuestionAnswerRequest {
  id?: number,
  questionsId: number,
  mediaId: number,
}

export interface SetRecommendationsRequest {
	recommendedByDefault: boolean,
	recommendations: SetRecommendationsRequestPart[],
}

export interface SetRecommendationsRequestPart {
	id: number,
	position: number,
}

export interface EducationLargeTaskDecideRequest {
  draftHash: string | null,
  complete: boolean,
  firstDecide: boolean,
  content: string,
  questions: Array<EducationLargeTaskDecideQuestionRequest|EducationLargeTaskDecideSpeakingQuestionRequest|EducationLargeTaskDecideCodingQuestionRequest>,
}

export enum RecommendationType {
	TARGET = 'target',
	DEFAULT = 'default',
}

export enum CalendarEntityType {
  LESSON = 'lesson',
  EXERCISE = 'exercise',
  USEFUL_MATERIAL = 'useful_material',
}

export interface MasterLessonRateRequest {
  rating?: number,
  comment: string,
}

export interface StorePlaybackMarkerRequest {
  seconds: number,
}

export interface SeniorStoreRequest {
  seniorId: number,
  mentorIds: number[],
}

export enum AgreementsTypeEnum {
  OFFER_AGREEMENT = 'offer_agreement',
  PROCESSING_OF_PERSONAL_DATA = 'processing_of_personal_data',
  CONFIDENTIALITY_OF_PERSONAL_DATA = 'confidentiality_of_personal_data',
  SALE_PROMOTION_AGREEMENT = 'sale_promotion_agreement',
}

export interface SeniorShortResource {
  id: number,
  uuid: string,
  hasSocials: boolean,
  socials: SocialResource[],
  surname: string,
  email: string,
  name: string,
  avatar: string,
  city: string,
  status: NameValueResource,
  isDelete: boolean,
  mentors: UserShortResource[],
}

export interface MasterLessonLargeResource {
  lesson: LessonLargeResource,
  checkedAt: string,
  rating: number,
  comment: string,
  previous: AdjacentLessonResource | null,
  next: AdjacentLessonResource | null,
  playbackMarker: number | null,
  playbackTimestamp: number | null,
}

export interface MasterLessonDrawerResource {
  id: number,
  title: string,
  description?: string,
  spendingAt: string,
  checkedAt?: string,
}

export interface MasterLessonShortResource {
  id: number,
  title: string,
  description?: string,
  spendingAt: string,
  isWebinar: boolean,
  checkedAt?: string,
  comment?: string,
  rating?: number,
  ratedAt?: string,
}

export interface ManagerOrderStoreRequest {
  status?: OrderStatus,
  userId?: number,
  managerId?: number | null,
  packages: ManagerOrderPackageRequest[],
  isManual: boolean,
}

export interface ManagerOrderPackageRequest {
  packageId: number,
  price: number,
}

export interface MasterOrderStoreRequest {
  courseId: number,
  groupId: number,
  packageId: number,
}

export interface OfferStoreRequest {
  courseId: number,
  groupId: number,
  packageId: number,
}

export interface OfferWithAuthRequest {
  courseId: number,
  packageId: number,
  email: string,
  password: string,
  cityId: number,
}

export interface OfferWithRegistrationRequest {
  courseId: number,
  packageId: number,
  email: string,
  password: string,
  cityId: number,
  passwordConfirmation: string,
  recaptchaToken: string,
}

export interface ManagerOrderShortResourcePaginator {
  meta: PaginatorMeta,
  data: ManagerOrderShortResource[],
}

export interface ManagerOrderShortResource {
  id: number,
  packages: PackageShortResource[],
  status: NameValueResource,
  manager?: UserShortResource,
  user: UserShortResource,
  totalPrice: number,
  originalPrice: number,
  createdAt: string,
  paidAt: string,
  uuid: string,
  hasSale?: boolean,
}

export interface ManagerOrderLargeResource {
  id: number,
  packages: PackageShortResource[],
  status: NameValueResource,
  manager?: UserShortResource,
  user: UserShortResource,
  totalPrice: number,
  originalPrice: number,
  createdAt: string,
  paidAt: string|null,
  hasSale?: boolean,
  promoCode?: OrderCodeResource,
  groups: PurchaseGroupResource[],
  sales: OrderSaleLargeResource[],
  isAutoCharged: boolean,
  isInitRecurringPayment: boolean,
  cardPan: string|null,
  isUserCreated: boolean,
  cashbox: NameValueResource,
  packageOfferPrice: number,
  saleParentOrderId: number|null,
  isManagerPrice: boolean|null,
}

export interface SaleStatShortResourcePaginator {
  meta: PaginatorMeta,
  data: SaleStatShortResource[],
}

export interface SaleStatShortResource {
  email: string|null,
  target: string,
  monthsCount: number,
  status: OrderStatus,
  targetOrderId: number,
}

export interface MasterOrderShortResourcePaginator {
  meta: PaginatorMeta,
  data: MasterOrderShortResource[],
}

export interface MasterOrderShortResource {
  id: number,
  uuid: string, // Нужен для формирования ссылки на оплату третьим лицом и запроса /api/payment/orders/{order_uuid}
  user: UserShortResource,
  status: NameValueResource,
  totalPrice: number,
  originalPrice: number,
  createdAt: string,
  paidAt: string,
  isPaidByThirdParty: boolean,
}

export interface OrderStatusResource {
  status: OrderStatus,
  isInitRecurringPayment: boolean,
  courseId: number,
  courseType: CourseType,
  total: number,
}

export interface MasterOrderLargeResource {
  id: number,
  uuid: string, // Нужен для формирования ссылки на оплату третьим лицом и запроса /api/payment/orders/{order_uuid}
  user: UserShortResource,
  status: NameValueResource,
  totalPrice: number,
  originalPrice: number,
  createdAt: string,
  paidAt: string|null,
  isPaidByThirdParty: boolean,
  promoCode?: OrderCodeResource,
  sales: OrderSaleLargeResource[],
  groups: PurchaseGroupResource[],
  isAutoCharged: boolean,
  isInitRecurringPayment: boolean,
  cardPan: string | null,
  isAllowRecurringPayments: boolean,
  isUserCreated: boolean,
  cashbox: NameValueResource,
  packageOfferPrice: number,
  isManagerPrice: boolean|null,
}

export interface DiscountResource {
  saleSources: string[],
  salePrice: number,
  packagesWithDiscounts: PackageShortResource[],
}

export interface PurchaseGroupResource {
  id: number,
  type: NameValueResource,
  course: NameValueTypeResource,
  descriptions: GroupStoreDescriptionData[],
  discounts: DiscountResource | null,
  packages: PackageShortResource[],
  fakePrice: number | null,
}

export interface PurchaseLargeResource {
  closedForSales: boolean,
  id: number,
  title: string,
  annotation: string,
  educationStartAt: string,
  educationEndAt: string,
  salesEndAt: string,
  salesEndPassed: boolean,
  hiddenAt: string,
  createdAt: string,
  publishedAt?: string,
  type: NameValueResource,
  groupTypes: NameValueResource[],
  materials: number,
  subject: NameValueResource,
  groups: PurchaseGroupResource[],
  masterGroup?: NameValueResource,
  months: MonthShortResource[],
  shopLabel: string | null,
}

export interface CourseLinkResourcePaginator {
  meta: PaginatorMeta,
  data: CourseLinkResource[],
}

export interface CourseLinkResource {
  id: number,
  type: NameValueResource,
  months: MonthShortResource[],
  course: CourseShortResource,
  hash: string,
}

export interface MasterMoveReasonRequest {
  reason: string,
  comment: string,
}

export interface MasterShortResourcePaginator {
  meta: PaginatorMeta,
  data: MasterShortResource[],
}

export interface MasterShortResource {
  user: UserShortResource,
  hearts: number,
  lessonsTotalCount: number,
  lessonsViewedCount: number,
  completeExerciseCount: number,
  checkedExerciseCount: number,
  totalExerciseCount: number,
  messagesCount: number,
  transfer?: MasterTransferResource,
}

export interface MasterTransferResource {
  isAllocated: boolean,
  reason: string | null,
  newMasterGroup: string | null,
  masterGroup: string,
}

export interface MasterLargeResource {
  user: UserShortResource,
  hearts: number,
  lessonsTotalCount: number,
  lessonsViewedCount: number,
  completeExerciseCount: number,
  checkedExerciseCount: number,
  totalExerciseCount: number,
  messagesCount: number,
  transfer?: MasterTransferResource,
  activeGroups?: string,
  groups?: MasterGroupLargeResource[],
}

export interface MasterGroupCreateRequest {
  title?: string,
  timezoneGroupId: number,
  groupUrl?: string,
  chatUrl?: string,
  courseId: number,
  groupType?: GroupType | null,
  groupId?: number | null,
  forAllGroups?: boolean,
  mentorIds?: number[],
  masterIds: number[],
}

export interface MasterGroupUpdateRequest {
  title: string,
  groupUrl?: string,
  chatUrl?: string,
  forAllGroups?: boolean,
  mentorIds?: number[],
}

export interface MasterGroupTransferMastersRequest {
  masterGroupId: number,
  masterIds: number[],
}

export interface RejectFromUnallocatedRequest {
  userId: number,
  courseId: number,
  courseGroupId: number,
}

export interface MasterAlreadyInGroupResource {
  userId: number,
  name: string,
  surname: string,
  masterGroupTitle: string,
  courseGroupType: string,
  hasSocials: boolean,
}

export interface MasterGroupShortResourcePaginator {
  meta: PaginatorMeta,
  data: MasterGroupShortResource[],
}

export interface MasterGroupShortResource {
  id: number,
  course: CourseLabelResource,
  timezoneGroup?: NameValueResource,
  group: GroupLabelResource,
  mentors: UserShortResource[],
  title: string,
  chatUrl?: string,
  groupUrl?: string,
  mastersCount: number,
}

export interface MasterGroupLargeResource {
  id: number,
  course: CourseLabelResource,
  timezoneGroup?: NameValueResource,
  group: GroupLabelResource,
  mentors: UserShortResource[],
  title: string,
  chatUrl?: string,
  groupUrl?: string,
  mastersCount: number,
  groupType: NameValueResource,
  educationStartAt: string,
  educationEndAt: string,
}

export interface TaskStore {
  verificationHours: number,
  exerciseId: number,
  title: string,
  description: string,
  type: string,
  maxPoints: number,
}


// Из card берется основная инфа, из groups инфа для модалки с выбором пакета
export interface LinkShopResource {
	card: CourseShopCardResource,
	info: PurchaseLargeResource,
}

export interface MasterCourseShortResource {
  id: number,
  title: string,
  annotation: string,
  type: NameValueResource,
  price: number,
  originalPrice: number | null,
  hasDiscount: boolean,
  subjectId: number,
  teachers: TeacherShortResource[],
  educationEnd: string,
  educationStart: string,
  groups: GroupLabelResource[],
}

export interface CourseShortResourcePaginator {
  meta: PaginatorMeta,
  data: CourseShortResource[],
}

export interface CourseShortResource {
  closedForSales: boolean,
  id: number,
  title: string,
  annotation: string,
  educationStartAt: string,
  educationEndAt: string,
  salesEndAt: string,
  salesEndPassed: boolean,
  hiddenAt: string,
  createdAt: string,
  publishedAt?: string,
  type: NameValueResource,
  groupTypes: NameValueResource[],
  materials: number,
  subject: NameValueResource,
}

export interface CourseLabelResource {
  id: number,
  title: string,
  type: NameValueResource,
  hasGamification: boolean,
}

export interface PackageCourseLabelResource {
  id: number,
  title: string,
  type: NameValueResource,
  hasGamification: boolean,
  groupType: NameValueResource,
}

export interface CourseCloneData {
  title?: string,
}

export interface CourseStoreData {
  subjectId: number,
  type: CourseType,
  title: string,
  link?: string,
  annotation: string,
  educationStartAt: string,
  educationEndAt: string,
  salesEndAt: string,
  hiddenAt: string,
  hasGamification: boolean,
  maxExercises?: number,
  maxLessons?: number,
  shopLabel: string,
  teacherIds: number[],
  openDashboardAt: string,
  assigningStartAt: string|null,
  assigningEndAt: string|null,
  hasMentor: boolean,
}

export interface CourseLargeResource {
  id: number,
  type: NameValueResource,
  subject: NameValueResource,
  title: string,
  annotation: string,
  educationStartAt: string,
  educationEndAt: string,
  salesEndAt: string,
  salesEndPassed: boolean,
  hasGamification: boolean,
  maxExercises: number | null,
  maxLessons: number | null,
  hiddenAt: string,
  createdAt: string,
  publishedAt?: string,
  groups: GroupShortResource[],
  reviewsCount: number,
  teachers: TeacherShortResource[],
  shopLabel: string,
  openDashboardAt: string,
  assigningStartAt: string|null,
  assigningEndAt: string|null,
  hasMentor: boolean,
}

export interface CreateNoteRequest {
  title: string,
  content: string,
  color: NoteColors,
  favorite: boolean,
}

export interface UpdateNoteRequest {
  title: string,
  content: string,
  color: NoteColors,
  favorite: boolean,
  id: number,
}

export interface NoteResource {
  id: number,
  title: string,
  color: NoteColors,
  content: string,
  favorite: boolean,
  createdAt: string,
}

export interface NoteFavoriteResource {
  favorite: boolean,
}

export interface NoteShortResource {
  id: number,
  title: string,
  color: NoteColors,
  favorite: boolean,
  createdAt: string,
}

export interface NoteListResourcePaginator {
  meta: PaginatorMeta,
  data: NoteShortResource[],
}

// daily - для показа новости заданное количество раз в определенный период, schedule - показ в заданные даты и время
export enum NewsDisplayMode {
  DAILY = 'daily',
  SCHEDULE = 'schedule',
}

export interface NewsScheduleDate {
  id: number,
  datetime: string,
  isExpired: boolean,
}

export interface NewsLargeResource {
  id: number,
  name: string,
  text: string,
  url: string | null,
  displayMode: NewsDisplayMode,
  viewStartAt: string,
  viewEndAt: string,
  viewLimit: number|null,
  courses: CourseLabelResource[],
  desktopMedia: MediaResource | null,
  mobileMedia: MediaResource | null,
  media: MediaResource[]|null,
  scheduleDates: NewsScheduleDate[],
}

export interface NewsListResourcePaginator {
  meta: PaginatorMeta,
  data: NewsListResource[],
}

export interface NewsListResource {
  id: number,
  name: string,
  viewStartAt: string,
  viewEndAt: string,
  courses: CourseLabelResource[],
  desktopMedia: MediaResource | null,
  mobileMedia: MediaResource | null,
  media: MediaResource[]|null,
}

export interface NewsShortResource {
  id: number,
  name: string,
  text: string,
  url: string | null,
  desktopMedia: MediaResource | null,
  mobileMedia: MediaResource | null,
  media: MediaResource[]|null,
}

export interface MistakeShortResource {
  formId: number,
  questionId: number,
  correction: string,
  completed: boolean,
}

export interface MistakeStoreResource {
  finished: boolean,
  questionId: number | null,
}

export interface GroupShortResource {
  id: number,
  type: NameValueResource,
  months: MonthShortResource[],
}

export interface MonthShortResource {
  id: number,
  name: string,
  price: number,
  isDisabled: boolean,
  isVisibleInShop: boolean,
}

export interface GroupStoreData {
  mastersAllocateDay?: number,
  mastersCount: number,
  description?: string,
  standardDiff?: string,
}

export interface GroupPackagesData {
  cashboxId: number,
  pricePerMonth: number,
  fakePrice: number,
  closedForSales: boolean,
  customMonthPrice: boolean,
  months: GroupStoreMonthsData[],
  prolongation: PackageStoreData[],
  starter: PackageStoreData[],
  transfer: PackageStoreData[],
}

export interface PackageStoreData {
  id?: number,
  isDefault?: boolean,
  packageId?: number,
  price?: number,
  monthIds: number[],
}

export interface GroupStoreMonthsData {
  id: number,
  isVisibleInShop: boolean,
  price: number,
}

export interface GroupStoreDescriptionData {
  title: string,
  description: string,
  newDescriptions: string,
  standardDiff: string,
}

export interface GroupDescriptionResource {
  id: number,
  title: string,
  description: string,
  newDescriptions: string,
  standardDiff: string,
}

export interface GroupLargeResource {
  id: number,
  type: NameValueResource,
  months: MonthShortResource[],
  mastersCount: number,
  mastersAllocateDay: number,
  hash: number,
  pricePerMonth: number,
  closedForSales: boolean,
  description: string,
  standardDiff: string,
}

export interface GroupPackagesResource {
  mastersCount: number,
  mastersAllocateDay: number | null,
  hash: string,
  pricePerMonth: number,
  closedForSales: boolean,
  description?: string,
  standardDiff?: string,
  cashbox?: NameValueResource,
  customMonthPrice: boolean,
  fakePrice: number | null,
  prolongation: PackageShortResource[],
  starter: PackageShortResource[],
  transfer: PackageShortResource[],
  type: PackageShortResource[],
  id: number,
  months: MonthShortResource[],
}

export interface PackageShortResource {
  id: number,
  course: NameValueTypeResource,
  title: string | null,
  price: number,
  originalPrice: number,
  type: PackageType,
  groupType: NameValueResource,
  package: PackageShortResource,
  isDisabled: boolean,
  months: NameValueResource[],
  isDefault: boolean,
  packageOfferPrice: number,
}

export interface PackageListResource {
  id: number,
  title: string,
  course: NameValueTypeResource,
  price: number,
  originalPrice: number,
  type: PackageType,
  groupType: NameValueResource,
  package: PackageShortResource,
  isDisabled: boolean,
  months: NameValueResource[],
  isDefault: boolean,
  packageOfferPrice: number,
  fakePrice: number,
}

export interface PackageShortResourcePaginator {
  meta: PaginatorMeta,
  data: PackageListResource[],
}

export interface PackageLabelResource {
  id: number,
  course: PackageCourseLabelResource,
  price: number,
  months: NameValueResource[],
}

export interface LessonStore {
  title: string,
  description: string,
  comment: string | null,
  spendingAt: string,
  masterGroupId?: number,
  startPublishedAt?: string,
  videoId?: number,
  teacher?: string,
  broadcastUrl?: string,
  videoUrl?: string,
  mediaIds: number[],
  timecodes?: TimecodeStore[],
  forAllMasters: boolean,
  masterIds?: number[],
  scriptIds?: number[],
  ignorePubDate: boolean,
  teacherId: number | null,
}

export interface TimecodeStore {
  id?: number,
  title: string,
  second: number,
}

export interface LessonReviewResource {
  user: UserShortResource,
  checkedAt: string,
  ratedAt: string,
  rating: number,
  comment: string,
}

export interface LessonShortResourcePaginator {
  meta: PaginatorMeta,
  data: LessonShortResource[],
}

export interface LessonShortResource {
  id: number,
  title: string,
  description: string,
  comment: string | null,
  spendingAt: string,
  isSpending: boolean,
  isWebinar: boolean,
  ignorePubDate: boolean,
  canReview: boolean,
  startPublishedAt: string,
  teacher?: string,
  broadcastUrl?: string,
  videoUrl?: string,
  forAllMasters: boolean,
  hasVideo: boolean,
  createdAt: string,
  course: NameValueResource,
  subject: NameValueResource,
  mainTeacher: TeacherShortResource | null,
}

export interface LessonLargeResource {
  id: number,
  title: string,
  description: string,
  comment: string | null,
  spendingAt: string,
  isSpending: boolean,
  isWebinar: boolean,
  ignorePubDate: boolean,
  canReview: boolean,
  startPublishedAt: string,
  teacher?: string,
  broadcastUrl?: string,
  videoUrl?: string,
  forAllMasters: boolean,
  hasVideo: boolean,
  createdAt: string,
  course: NameValueResource,
  subject: NameValueResource,
  mainTeacher: TeacherShortResource | null,
  video?: MediaResource,
  media: MediaResource[],
  scripts: MediaResource[],
  timecodes: LessonTimecodeShortResource[],
  hls: UserLessonHlsPartResource[],
}

export interface UserLessonHlsPartResource {
  label: string,
  link: string,
}

export interface AdjacentLessonResource {
  id: number,
  title: string,
  spendingAt: string,
}

export interface ManagerLessonLargeResource {
  video?: MediaResource,
  media: MediaResource[],
  scripts: MediaResource[],
  timecodes: LessonTimecodeShortResource[],
  id: number,
  title: string,
  description: string,
  comment: string | null,
  spendingAt: string,
  isSpending: boolean,
  isWebinar: boolean,
  ignorePubDate: boolean,
  canReview: boolean,
  startPublishedAt: string,
  teacher?: string,
  broadcastUrl?: string,
  videoUrl?: string,
  forAllMasters: boolean,
  hasVideo: boolean,
  createdAt: string,
  course: NameValueResource,
  subject: NameValueResource,
  mainTeacher: TeacherShortResource | null,
  masters: ManagerLessonLargeMasterResource[],
  previous?: AdjacentLessonResource | null,
  next?: AdjacentLessonResource | null,
}

export interface ManagerLessonLargeMasterResource {
  user: UserShortResource,
  checkedAt?: string,
  ratedAt?: string,
  rating?: number,
  comment?: string,
}

export interface LessonTimecodeShortResource {
  id: number,
  title: string,
  second: number,
}

export interface ProgramMonthShortResource {
  id: number,
  date: string,
  lessonsCount: number,
  exerciseCount: number,
  description: string,
}

export interface GroupLabelResource {
  id: string,
  type: NameValueResource,
}

export interface ProgramMonthLargeResource {
  id: number,
  date: string,
  lessonsCount: number,
  exerciseCount: number,
  course: CourseShortResource,
}

export interface ExerciseCloneManyData {
  courseId?: number,
  masterGroupId?: number,
  spendingAt: string,
  deadlineAt: string,
  exerciseUuids: string[],
}

export interface ExerciseCloneData {
  courseId?: number,
  spendingAt?: string,
  deadlineAt?: string,
  masterGroupId?: number,
}

export interface ExerciseCloneToManyData {
  courseIds: number[],
  spendingAt: string,
  deadlineAt: string,
}

export interface ExerciseStore {
  masterIds?: number[],
  forAllMasters?: boolean,
  title?: string,
  spendingAt: string,
  deadlineAt?: string,
  avgExecutionSeconds?: number,
  subjectId?: number,
  isProbe: boolean,
  accessLevel: ExerciseAccessLevel,
  autoCheck?: boolean,
  isTimerEnabled: boolean, // Если тут true, то поле avg_execution_seconds обязательное
}

export interface ExerciseShortResourcePaginator {
  meta: PaginatorMeta,
  data: ExerciseShortResource[],
}

export interface ExerciseShortResource {
  id: number,
  title: string,
  monthId: number,
  spendingAt: string,
  uuid: string,
  deadlineAt: string,
  avgExecutionSeconds: number,
  verificationAt: string,
  isDeadlinePassed: boolean,
  course: CourseLabelResource,
  isVerificationPassed: boolean,
  isTemplate: boolean,
  forAllMasters: boolean,
  questionsCount: number,
  subject: NameValueResource,
  accessLevel: NameValueResource,
  isProbe: boolean,
  tasks: TaskShortResource[],
  materialsCount: number,
  autoCheck: boolean,
  original: NameValueResource,
  isTimerEnabled: boolean,
}

export interface ExerciseLargeResource {
  id: number,
  title: string,
  monthId: number,
  spendingAt: string,
  uuid: string,
  deadlineAt: string,
  avgExecutionSeconds: number,
  verificationAt: string,
  isDeadlinePassed: boolean,
  course: CourseLabelResource,
  isVerificationPassed: boolean,
  isTemplate: boolean,
  forAllMasters: boolean,
  questionsCount: number,
  subject: NameValueResource,
  accessLevel: NameValueResource,
  isProbe: boolean,
  tasks: Array<TaskCreativeResource|TaskPracticeResource|TaskTestResource|TaskSpeakingResource|TaskCodingResource>,
  materialsCount: number,
  autoCheck: boolean,
  original: NameValueResource,
  isTimerEnabled: boolean,
  masters: UserShortResource[],
  clonesCount: number | null,
}

export interface ExerciseCloneListResource {
  uuid: string,
  monthId: number,
  course: NameValueResource,
  createdAt: string,
  spendingAt: string,
  deadlineAt: string,
  author: UserManagerListResource | null,
}

export interface TaskShortResource {
  id: number,
  uuid: string,
  title: string,
  questionsCount: number,
  mediaCount: number,
  type: NameValueResource,
}

export interface TaskLargeResource {
  id: number,
  uuid: string,
  title: string,
  description: string,
  annotation: string,
  masterAnnotation: string,
  managerAnnotation: string,
  type: NameValueResource,
  answerMedia: MediaResource[],
  maxPoints: number,
  verificationHours: number,
  createdAt: string,
  media: MediaResource[],
  exerciseUuid: string,
}

export interface TestQuestionResource {
  id: number,
  uuid: string,
  question: string,
  type: NameValueResource,
  rateType: NameValueResource,
  points: number,
  oneErrorPoints: number,
  manyErrorPoints: number,
  media: MediaResource[],
  hintAnswer?: string,
  hintQuestion?: string,
  answers: TestQuestionAnswerResource[],
  sequences: TestQuestionSequenceResource[],
  nameAnswerTable?: string,
  nameSequenceTable?: string,
  displayType?: string,
  position?: number,
  relatedUuid: string | null,
}

export interface PracticeQuestionResource {
  id: number,
  question: string,
  rightAnswer: string,
  hintQuestion?: string,
  uuid: string,
  maxPoints: number,
  answerId?: string,
  media: MediaResource[],
  position: number,
  displayedPosition: number,
  points: number,
}

export interface CodingQuestionResource {
  id: number,
  question: string,
  hintQuestion: string|null,
  hintAnswer: string|null,
  uuid: string,
  maxPoints: number,
  media: MediaResource[],
  position: number,
  displayedPosition: number,
}

export interface SpeakingQuestionResource {
  id: number,
  taskId: number,
  position: boolean,
  type: string,
  question: string,
  text: string,
  media?: MediaResponse[],
  questions?: SpeakingSubQuestionResource[],
}

export interface SpeakingSubQuestionResource {
  id: number,
  questionId: number,
  required: boolean,
  position: number,
  text?: string,
  media?: MediaResponse,
  createdAt?: string,
}

export interface TestQuestionAnswerResource {
  id: number,
  text: string,
  position: number,
  rightPosition?: number,
  isCorrect: boolean,
  sequencePosition?: number,
}

export interface TestQuestionSequenceResource {
  id: number,
  text: string,
  position: number,
}

export interface TaskTestResource {
  id: number,
  uuid: string,
  title: string,
  description: string,
  annotation: string|null,
  masterAnnotation: string|null,
  managerAnnotation: string|null,
  type: NameValueResource,
  answerMedia: MediaResource[],
  maxPoints: number,
  verificationHours: number,
  createdAt: string,
  media: MediaResource[],
  exerciseUuid: string,
  questions: TestQuestionResource[],
}

export interface TaskPracticeResource {
  id: number,
  uuid: string,
  title: string,
  description: string,
  annotation: string|null,
  masterAnnotation: string|null,
  managerAnnotation: string|null,
  type: NameValueResource,
  answerMedia: MediaResource[],
  maxPoints: number,
  verificationHours: number,
  createdAt: string,
  media: MediaResource[],
  exerciseUuid: string,
  questions: PracticeQuestionResource[],
}

export interface TaskSpeakingResource {
  id: number,
  uuid: string,
  title: string,
  description: string,
  annotation: string|null,
  masterAnnotation: string|null,
  managerAnnotation: string|null,
  type: NameValueResource,
  answerMedia: MediaResource[],
  maxPoints: number,
  verificationHours: number,
  createdAt: string,
  media: MediaResource[],
  exerciseUuid: string,
  questions: SpeakingQuestionResource[],
}

export interface TaskCodingResource {
  id: number,
  uuid: string,
  title: string,
  description: string,
  annotation: string|null,
  masterAnnotation: string|null,
  managerAnnotation: string|null,
  type: NameValueResource,
  answerMedia: MediaResource[],
  maxPoints: number,
  verificationHours: number,
  createdAt: string,
  media: MediaResource[],
  exerciseUuid: string,
  questions: CodingQuestionResource[],
}

export interface TaskCreativeResource {
  id: number,
  uuid: string,
  title: string,
  description: string,
  annotation: string,
  masterAnnotation: string,
  managerAnnotation: string,
  type: NameValueResource,
  answerMedia: MediaResource[],
  maxPoints: number,
  verificationHours: number,
  createdAt: string,
  media: MediaResource[],
  exerciseUuid: string,
}

export interface TaskCloneRequest {
  taskUuid: string,
  exerciseUuid: string,
}

export interface TaskStoreRequest {
  uuid: string,
  verificationHours: number,
  exerciseUuid: string,
  title: string,
  description: string,
  masterAnnotation: string,
  annotation: string,
  managerAnnotation: string,
  answerMediaIds: number[],
  mediaIds: number[],
  type: string,
  maxPoints: number,
  test: TaskStoreTestRequest,
  practice: TaskStorePracticeRequest,
  speaking: TaskStoreSpeakingRequest,
  coding: TaskStoreCodingRequest,
}

export interface TaskStoreTestRequest {
  questions: TestQuestionRequest[],
}

export interface TestQuestionRequest {
  id?: number,
  uuid: string,
  type: TaskQuestionType,
  rateType: TaskRateType,
  question: string,
  points: number,
  oneErrorPoints?: number,
  manyErrorPoints?: number,
  mediaIds: number[],
  hintAnswer?: string,
  hintQuestion?: string,
  answers: TestAnswerRequest[],
  sequences: TestSequenceRequest[],
  nameAnswerTable?: string,
  nameSequenceTable?: string,
  displayType?: string,
  position?: number,
  relatedUuid?: string | null,
}

export interface TestSequenceRequest {
  id?: number,
  text: string,
  position: number,
}

export interface TestAnswerRequest {
  id?: number,
  text: string,
  position: number,
  isCorrect: boolean,
  sequencePosition?: number,
}

export interface SpeakingSubQuestionRequest {
  id?: number,
  position: number,
  required: boolean,
  text?: string,
  mediaId?: number,
}

export interface CreateTeacherRequest {
  name: string,
  surname?: string,
  subjectId?: number,
  achievements: TeacherAchievementsRequestPart[],
}

export interface UpdateTeacherRequest {
  name: string,
  surname?: string,
  subjectId?: number,
  achievements: TeacherAchievementsRequestPart[],
  id: number,
}

export interface TeacherAchievementsRequestPart {
  name: string,
  value: string,
  position: number,
  slug: TeacherAchievementSlug,
}

export interface CreateReviewRequest {
  name: string,
  surname: string,
  examScore: number,
  subjectId: number,
  courseId: number,
  content: string,
  videoUrl: string,
  photoId: number,
}

export interface UpdateReviewRequest {
  name: string,
  surname: string,
  examScore: number,
  subjectId: number,
  courseId: number,
  content: string,
  videoUrl: string,
  photoId: number,
  id: number,
}

export interface CourseProgramRequest {
  programId: number | null,
  descriptions: CourseProgramDescriptionsPart[],
}

export interface CourseProgramDescriptionsPart {
  monthId: number,
  description: string,
}

export interface TaskStorePracticeRequest {
  questions: PracticeQuestionRequest[],
}

export interface TaskStoreSpeakingRequest {
  questions: SpeakingQuestionRequest[],
}

export interface PracticeQuestionRequest {
  id?: number,
  uuid?: string|null,
  position: number,
  displayedPosition: number,
  hintQuestion?: string,
  maxPoints: number,
  mediaIds: number[],
  question: string,
  rightAnswer: string,
}

export interface TaskStoreCodingRequest {
  questions: CodingQuestionRequest[],
}

export interface CodingQuestionRequest {
  id?: number,
  uuid?: string|null,
  position: number,
  displayedPosition: number,
  hintQuestion: string|null,
  maxPoints: number,
  mediaIds: number[],
  question: string,
  hintAnswer: string|null,
}

export interface SpeakingQuestionRequest {
  id?: number,
  position: number,
  type: SpeakingQuestionType,
  question: string,
  text?: string,
  mediaIds: number[],
  questions: SpeakingSubQuestionRequest[],
}

export interface PromotionCollectionResource {
  id: number,
  name: string,
  code: string,
  count: number,
  startAt: string,
  endAt: string,
}

export interface PromotionResource {
  id: number,
  name: string,
  code: string,
  count: number,
  discount: number,
  startAt: string,
  endAt: string,
  courses: PromotionCourseResource[],
  packages: PackageShortResource[],
  codes: PromotionCodeResource[],
}

export interface PromotionCourseResource {
  name: number,
  value: string,
}

export interface LogResource {
  id: number,
  userId: number,
  title: string,
  type: NameValueResource,
  createdAt: string,
}

export interface UploadFile {
  file: Blob,
  folder?: string,
}

export interface VkAuthLogin {
  id: number,
  uuid: string,
  silentToken: string,
}

export interface AuthByTgRequest {
	id: number,
	firstName: string,
	lastName?: string,
	username: string,
	photoUrl?: string,
	authDate: number,
	hash: string,
}


export interface AuthLogin {
  email: string,
  password: string,
}

export interface LogoutResponse {
  message: string,
}

export interface AuthRegister {
  email: string,
  cityId: number,
  password: string,
  passwordConfirmation: string,
  recaptchaToken: string,
}

export interface UserProfile {
  name: string,
  surname: string,
  email: string,
  birthDate: string,
  phone: string,
  socialIds: number[],
  avatarId: number | null,
  cityId?: number,
  timezoneIds: number[],
  isLocalTimezone: boolean,
}

export interface KnowledgeResource {
  id: number,
  title: string,
  subject: NameValueResource,
  media: MediaResource[],
  months: KnowledgeMonthResource[],
}

export interface KnowledgeData {
  mediaIds: number[],
  monthId?: number,
}

export interface KnowledgeMonthResource {
  id: number,
  date: string,
  media: MediaResource[],
}

export interface MentorShortResourcePaginator {
  meta: PaginatorMeta,
  data: MentorShortResource[],
}

export interface MentorShortResource {
  user: UserShortResource,
  seniors: UserShortResource[],
  email: string,
  roleTypes: RoleType[],
  groups: string[],
}

export interface MentorLargeResource {
  id: number,
  uuid: string,
  hasSocials: boolean,
  socialUrl: string,
  surname: string,
  email: string,
  name: string,
  avatar: string,
  city: string,
  status: NameValueResource,
  isDelete: boolean,
  groups: MentorLargeGroupResource[],
  wait: number,
  onCheck: number,
  checked: number,
  total: number,
  complete: number,
}

export interface MentorGroupResource {
  id: number,
}

export interface MentorLargeGroupResource {
  id: number,
  title: string,
  course: CourseShortResource,
  months: NameValueResource[],
}

export interface ManualResource {
  id: number,
  title: string,
  content: string,
  parentId?: number,
}

export interface ManualTreeResource {
  id: number,
  title: string,
  parentId: number,
  children: ManualTreeResource[],
}

export interface ManualData {
  title: string,
  content: string,
  parentId?: string,
}

export interface RoleResource {
  id: number,
  uuid: string,
  title: string,
  usersCount: number,
  permissions: string[],
}

export interface RoleShortResource {
  id: number,
  uuid: string,
  title: string,
  usersCount: number,
}

export interface RoleStore {
  title: string,
  permissions: string[],
}

export interface RoleUsersStore {
  userIds: number[],
}

export interface RoleGroupResource {
  name: string,
  values: NameValueResource[],
}

export interface UserManagerProfile {
  name: string,
  surname: string,
  email: string,
  birthDate: string,
  phone: string,
  socialIds: number[],
  avatarId?: number | null,
  cityId?: number,
  timezoneIds: number[],
  roleIds: number[],
}

export interface UserPassword {
  oldPassword: string,
  newPassword: string,
  newPasswordConfirmation: string,
}

export interface UserSetPassword {
  password: string,
  passwordConfirmation: string,
  email?: string,
}

export interface UserChangeEmail {
  password: string,
  email: string,
}

export interface ChangeEmailAndPassword {
  password: string,
  passwordConfirmation: string,
  email: string,
}

export interface AuthToken {
  token: string,
}

export interface AuthRestore {
  email?: string,
  token?: string,
  password?: string,
}

export interface RestoreDataResourceUser {
  uuid: string,
  name: string,
  surname: string,
}

export interface RestoreDataResource {
  user: RestoreDataResourceUser,
}

export interface UserDataResource {
  user: SelfResource,
  token: string,
  isNewUser?: boolean,
}

export enum SocialType {
  VKONTAKTE = 'vkontakte',
  TELEGRAM = 'telegram',
}

export interface SocialResource {
  id: number,
  name: string,
  type: SocialType,
  username: string|null,
	url: string,
}

export interface UserBoughtGroupResource {
  id: number,
  title: string,
  groupType: NameValueResource,
  course: CourseLabelResource,
  subject: NameValueResource,
  mentor: UserShortResource,
  months: NameValueResource[],
  hasRecurringPayment: boolean,
}

export interface ScheduledPaymentResource {
  orderId: number,
  months: NameValueResource[],
  paymentDate: string,
  status: NameValueResource,
  price: number,
}

export interface CourseRecurringPaymentResource {
  boughtGroup: UserBoughtGroupResource,
  cardPan: string,
  scheduledPayments: ScheduledPaymentResource[],
}

export interface UserManagerListResource {
  uuid: string,
  name: string,
  surname: string,
}

export interface UserLargeResource {
  id: string,
  uuid: string,
  hasSocials?: boolean,
  surname: string,
  email: string,
  name: string,
  avatar?: MediaResource,
  city?: NameValueResource,
  status: NameValueResource,
  isDelete?: boolean,
  phone: string,
  timezones: NameValueResource[],
  socials: SocialResource[],
  seniors: UserShortResource[],
  birthDate: string,
  hasPassword: boolean,
  isLocalTimezone: boolean,
}

export interface UserManagerResourcePaginator {
  meta: PaginatorMeta,
  data: UserManagerResource[],
}

export interface UserManagerResource {
  id: number,
  uuid: string,
  name: string,
  surname: string,
  phone: string,
  status: NameValueResource,
  email: string,
  timezones: NameValueResource[],
  city?: NameValueResource,
  socials: SocialResource[],
  seniors: UserShortResource[],
  birthDate: string,
  avatar?: string,
  hasPassword: boolean,
  isLocalTimezone: boolean,
  masterGroups: NameValueResource[],
  ordersTotal: number,
  roles: UserRoleResource[],
}

export interface UserRoleResource {
  id: number,
  title: string,
  groups: string[],
}

export interface UnallocatedUserResourcePaginator {
  meta: PaginatorMeta,
  data: UnallocatedUserResource[],
}

export interface UnallocatedUserResource {
  id: number,
  uuid: string,
  hasSocials: boolean,
  socials: SocialResource[],
  surname: string,
  email: string,
  name: string,
  avatar: string,
  city: string,
  status: NameValueResource,
  isDelete: boolean,
  courses: NameValueResource[],
}

export interface UserShortResourcePaginator {
  meta: PaginatorMeta,
  data: UserShortResource[],
}

export interface UserShortResource {
  id: number,
  uuid: string,
  hasSocials: boolean,
  socials: SocialResource[],
  surname: string,
  email: string,
  name: string,
  avatar: string,
  city: string,
  status: NameValueResource,
  isDelete: boolean,
}

export interface SelfResourceAgreements {
  offerAgreement: boolean,
  processingOfPersonalData: boolean,
  confidentialityOfPersonalData: boolean,
}

export interface SelfResource {
  id: number,
  uuid: string,
  name: string,
  surname: string | null,
  email: string,
  permissions: string[],
  status: NameValueResource,
  hasSocials: boolean,
  avatar: string | null,
  notificationsCount: number,
  messagesCount: number,
  ticketMessagesCount: number,
  agreements: SelfResourceAgreements,
  tutorialPassed: boolean,
  birthDate: string | null,
  newMonthTutorialPassed: boolean,
  calendarTutorialPassed: boolean,
  newYearModalWindowPassed: boolean,
  valentineDayModalWindowPassed: boolean,
  isLocalTimezone: boolean,
}

export interface ThematicAvatarResource {
  id: number,
  url: string,
  title: string,
  isSelected: boolean,
}

export interface TicketShortResourcePaginator {
  meta: PaginatorMeta,
  data: TicketShortResource[],
}

export interface TicketShortResource {
  id: number,
  theme: string,
  author: UserShortResource,
  status: NameValueResource,
  hasNewMessages: boolean,
  lastMessageAuthor: NameValueResource,
  lastMessageAt: string,
  resolvedAt?: string,
  createdAt: string,
}

export interface TicketLargeResource {
  id: number,
  theme: string,
  author: UserShortResource,
  status: NameValueResource,
  hasNewMessages: boolean,
  lastMessageAuthor: NameValueResource,
  lastMessageAt: string,
  resolvedAt?: string,
  createdAt: string,
  messages: TicketMessageResource[],
}

export interface FaqStoreRequest {
  question: string,
  answer: string,
}

export interface FaqResource {
  id: number,
  question: string,
  answer: string,
}

export interface MessageStore {
  message: string,
  mediaIds: number[],
}

export interface MessageReactionStore {
  reaction: string | null, // Принимает реакции длиной не более 2 символов(👍)️, если передан null, то реакция будет убрана
}

export interface TicketStore {
  message: string,
  mediaIds: number[],
  theme: string,
}

export interface UserFlagStore {
  flag: UserFlag,
}

export interface UploadFileChunk {
  file: Blob,
  uuid: string,
  size: number,
  chunks: number,
  chunk: number,
  filename: string,
  folder?: string,
}

export interface MediaChunkResource {
  result?: MediaResource,
}

export interface CountersResource {
  tickets?: number,
}

export interface DictionaryResource {
  timezoneGroups: NameValueResource[],
  exerciseStatuses: NameValueResource[],
  promotionCodeTypes: NameValueResource[],
  userStatuses: NameValueResource[],
  searchTypes: NameValueResource[],
  searchOperators: NameValueResource[],
  cashboxes: NameValueResource[],
  promotionDiscountTypes: NameValueResource[],
  promotionProductTypes: NameValueResource[],
  orderStatuses: NameValueResource[],
  courseTypes: NameValueResource[],
  ticketStatuses: NameValueResource[],
  subjects: NameValueResource[],
  taskTypes: NameValueResource[],
  exerciseAccessLevels: NameValueResource[],
  taskQuestionTypes: NameValueResource[],
  taskSpeakingQuestionTypes: NameValueResource[],
  taskRateTypes: NameValueResource[],
  groupTypes: NameValueResource[],
  saleUnitTypes: NameValueResource[],
  colors: NameValueResource[],
  usefulMaterialTypes: NameValueResource[],
  paymentErrors: NameValueResource[],
  saleStatuses: NameValueResource[],
  packageTypes: NameValueResource[],
}

export interface CityResource {
  id: number,
  name: string,
  isDefault: boolean,
}

export interface NameValueContainerResource {
  name: string,
  values: NameValueResource[],
}

export interface NameValueResource {
  name: string,
  value: number | string,
}

export interface NameValueChildrenResource {
  name: string,
  value: number | string,
  children: NameValueChildrenResource[],
}

export interface NameValueChildrenCustomResource {
  name: string,
  value: number | string,
  children: NameValueTypeResource[],
}

export interface NameValueChildrenTypeResource {
  name: string,
  value: number | string,
  type: GroupType,
  children: NameValueResource[],
}

export interface NameValueContainer {
  name: string,
}

export interface NameValueTypeResource {
  name: string,
  value: number | string,
  type: string,
}

export interface MediaResource {
  id: number,
  type: string,
  filename: string,
  url: string,
  size: number,
  formats?: MediaFormatResource[],
  hls?: string,
}

export interface SortableMediaResource {
  id: number,
  type: string,
  filename: string,
  url: string,
  size: number,
  formats?: MediaFormatResource[],
  hls?: string,
  position: number|null,
}

export interface MediaFormatResource {
  id: number,
  quality: string,
  label: string,
  url: string,
  size: number,
}

export interface PaginatorMeta {
  currentPage: number,
  from: number|null,
  lastPage: number,
  perPage: number,
  to: number|null,
  total: number,
}

// Base paginator object
export interface BasePaginator {
  meta: PaginatorMeta,
}

export enum UserFlag {
  BASE_TUTORIAL_PASSED = 'base_tutorial_passed',
  NEW_MONTH_TUTORIAL_PASSED = 'new_month_tutorial_passed',
  CALENDAR_TUTORIAL_PASSED = 'calendar_tutorial_passed',
  NEW_YEAR_MODAL_WINDOW_PASSED = 'new_year_modal_window_passed',
  VALENTINE_DAY_MODAL_WINDOW_PASSED = 'valentine_day_modal_window_passed',
}

export enum TeacherEmojiSlugs {
  STAR = 'star',
  BALANCE_SCALE = 'balance_scale',
  BOOKS = 'books',
  MEMO = 'memo',
  UK = 'uk',
  DNA = 'dna',
  ALEMBIC = 'alembic',
  ABACUS = 'abacus',
  ORANGE_BOOK = 'orange_book',
  BLUE_BOOK = 'blue_book',
  SCROLL = 'scroll',
	JUDGE = 'judge',
  ROCKET = 'rocket',
  ROBOT = 'robot',
}

export enum FilterLessonStatus {
  ALL = 'all',
  CHECKED = 'checked',
  NOT_CHECKED = 'not_checked',
}

export enum ExerciseStatus {
  WAIT = 'wait',
  IN_PROCESS = 'in_process',
  ON_CHECK = 'on_check',
  CHECKED = 'checked',
  COMPLETE = 'complete',
}

export enum FilterExerciseStatus {
  WAIT = 'wait',
  ON_CHECK = 'on_check',
  COMPLETE = 'complete',
}

export enum NoteColors {
  _56C0AD = '#56C0AD',
  _FFA552 = '#FFA552',
  _6B9EFF = '#6B9EFF',
  _B495FF = '#B495FF',
  _60AF4C = '#60AF4C',
  _FF6871 = '#FF6871',
  _896051 = '#896051',
  _E14B84 = '#E14B84',
  _8141D0 = '#8141D0',
  _C0D1DB = '#C0D1DB',
}

export enum SearchOperator {
  AND = 'and',
  OR = 'or',
}

export enum SearchType {
  BY_ORDERS = 'by_orders',
  BY_USER_INFO = 'by_user_info',
}

export enum LogType {
  USER_REGISTRATION = 'user_registration',
  USER_EDIT_EMAIL = 'user_edit_email',
  USER_EDIT_PASSWORD = 'user_edit_password',
  MASTER_ADD_GROUP = 'master_add_group',
  MASTER_ALLOCATE = 'master_allocate',
  MASTER_TRANSFER = 'master_transfer',
  MENTOR_ADD_GROUP = 'mentor_add_group',
  MENTOR_EDIT = 'mentor_edit',
  MASTER_CURE_IN_GROUP = 'master_cure_in_group',
}

export enum RoleType {
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin',
  MENTOR = 'mentor',
  SPECIAL_MENTOR = 'special_mentor',
  PLUS_MENTOR = 'plus_mentor',
}

export enum TaskType {
  CREATIVE = 'creative',
  TEST = 'test',
  PRACTICE = 'practice',
  SPEAKING = 'speaking',
  CODING = 'coding',
}

export enum TicketStatus {
  OPEN = 'open',
  IN_WORK = 'in_work',
  CLOSED = 'closed',
}

export enum TaskRateType {
  ONE_ERROR = 'one_error',
  NO_ERRORS = 'no_errors',
  NO_RIGHT = 'no_right',
  MANY_ERRORS = 'many_errors',
  EVERY_RIGHT = 'every_right',
}

export enum TaskQuestionType {
  RATIO = 'ratio',
  TEXT = 'text',
  SEQUENCE = 'sequence',
  MULTIPLE = 'multiple',
  SINGLE = 'single',
  EMPTY = 'empty',
  NO_ANSWER = 'no_answer',
}

export enum GroupType {
  DEFAULT = 'default',
  LITE = 'lite',
  PLUS = 'plus',
}

export enum CourseType {
  DEFAULT = 'default',
  SPECIAL = 'special',
}

export enum ExerciseType {
  CREATIVE = 'creative',
  TEST = 'test',
  PRACTICE = 'practice',
}

export enum ExerciseAccessLevel {
  PUBLIC = 'public',
  PERSONAL = 'personal',
}

export enum SpeakingQuestionType {
  TEXT_QUEUE = 'text_queue',
  AUDIO_QUEUE = 'audio_queue',
  AUDIO_SINGLE = 'audio_single',
}

export enum OrderStatus {
  WAIT = 'wait',
  PAID = 'paid',
  REFUND = 'refund',
  CANCELED = 'canceled',
}

export enum Permission {
  MASTER_VIEW_ALL = 'master.view.all',
  MASTER_VIEW = 'master.view',
  MASTER_LESSONS_VIEW = 'master.lessons.view',
  MASTER_EXERCISES_VIEW = 'master.exercises.view',
  MASTER_TRANSFER_ALL = 'master.transfer.all',
  MASTER_HEALING = 'master.healing',
  MASTER_TRANSFER_LITE = 'master.transfer.lite',
  MG_VIEW_ALL = 'mg.view.all',
  MG_INFO_CREATE = 'mg.info.create',
  MG_INFO_EDIT = 'mg.info.edit',
  MG_MENTOR_CHANGE = 'mg.mentor.change',
  MG_DELETE = 'mg.delete',
  MENTOR_LIST_ALL = 'mentor.list.all',
  MENTOR_LIST_VIEW = 'mentor.list.view',
  MENTOR_APPOINT_SENIOR = 'mentor.appoint.senior',
  MENTOR_MASTER_VIEW = 'mentor.master.view',
  MENTOR_LESSONS_VIEW = 'mentor.lessons.view',
  MENTOR_EXERCISES_VIEW = 'mentor.exercises.view',
  EDUCATION_EXERCISES_VIEW = 'education.exercises.view',
  EDUCATION_EXERCISES_CHECK = 'education.exercises.check',
  EDUCATION_SPECIAL_CHECK = 'education.special.check',
  EDUCATION_PERSONAL_CREATE = 'education.personal.create',
  EDUCATION_LESSON_VIEW = 'education.lesson.view',
  EDUCATION_LESSON_LIST = 'education.lesson.list',
  COURSE_VIEW = 'course.view',
  COURSE_EDIT = 'course.edit',
  COURSE_PUBLISH = 'course.publish',
  COURSE_DELETE = 'course.delete',
  COURSE_LESSONS_EDIT = 'course.lessons.edit',
  COURSE_EXERCISES_EDIT = 'course.exercises.edit',
  BANK_PERSONAL_VIEW = 'bank.personal.view',
  BANK_BASE_VIEW = 'bank.base.view',
  BANK_PERSONAL_EDIT = 'bank.personal.edit',
  BANK_BASE_EDIT = 'bank.base.edit',
  DEPOSITORY_MATERIALS_VIEW = 'depository.materials.view',
  DEPOSITORY_MANUAL_VIEW = 'depository.manual.view',
  DEPOSITORY_MATERIALS_EDIT = 'depository.materials.edit',
  DEPOSITORY_MANUAL_EDIT = 'depository.manual.edit',
  USERS_MASTER_LIST = 'users.master.list',
  USERS_MANAGER_LIST = 'users.manager.list',
  USERS_MASTER_SEARCH = 'users.master.search',
  USERS_MANAGER_SEARCH = 'users.manager.search',
  USERS_VIEW = 'users.view',
  USERS_EDIT = 'users.edit',
  USERS_DELETE = 'users.delete',
  ORDERS_APPOINT_SELF = 'orders.appoint.self',
  ORDERS_CREATE = 'orders.create',
  ORDERS_VIEW = 'orders.view',
  ORDERS_PROMO_CREATE = 'orders.promo.create',
  ORDERS_SALES_CREATE = 'orders.sales.create',
  ORDERS_LINK_LIST = 'orders.link.list',
  SUPPORT_ANSWER = 'support.answer',
  ROLES = 'roles',
  NEWS_EDIT = 'news.edit',
  NEWS_VIEW = 'news.view',
}

export enum PromotionDiscountType {
  PERCENT = 'percent',
  SUM = 'sum',
}

export enum PromotionProductType {
  ALL = 'all',
  COURSES = 'courses',
  PACKAGES = 'packages',
}

export enum PromoCodeType {
  DISPOSABLE = 'disposable',
  REUSABLE = 'reusable',
}

export enum SaleUnitType {
  SUBJECTS = 'subjects',
  COURSES = 'courses',
  PACKAGES = 'packages',
}

export enum SaleStatus {
  DRAW = 'draw',
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export enum SalePriceType {
  PERCENT = 'percent',
  SUM = 'sum',
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum UserType {
  MASTER = 'master',
  MANAGER = 'manager',
}

export enum NotificationPlaceholderType {
  COURSE = 'course',
  MASTER_EXERCISE = 'master_exercise',
  HEARTS = 'hearts',
  OLD_MASTER_GROUP = 'old_master_group',
  NEW_MASTER_GROUP = 'new_master_group',
  MASTER_GROUP = 'master_group',
  PAY_MODAL = 'pay_modal',
  MASTER_LESSON = 'master_lesson',
  MASTER_TICKET = 'master_ticket',
  MONTH = 'month',
  MASTER = 'master',
  MENTOR_LESSON = 'mentor_lesson',
  MENTOR_EXERCISE = 'mentor_exercise',
  MENTOR = 'mentor',
  MANAGER = 'manager',
  MENTOR_LIST = 'mentor_list',
}

export enum PackageType {
  PROLONGATION = 'prolongation',
  STARTER = 'starter',
  TRANSFER = 'transfer',
}

export enum UsefulMaterialType {
  QUIZLET = 'quizlet',
  MANUALS = 'manuals',
  ERROR_ANALYSIS = 'error_analysis',
  USEFUL_MATERIAL = 'useful_material',
  IMPORTANT_INFORMATION = 'important_information',
  OTHER = 'other',
}

export enum TeacherAchievementSlug {
  TEACHING = 'teaching',
  EDUCATION = 'education',
  EXAM_RESULT = 'exam_result',
  GRADUATES = 'graduates',
}

export enum ReviewType {
  VIDEO = 'video',
  TEXT = 'text',
}

export interface UsefulMaterialTypePostBody {
  type: string,
}

export interface UsefulMaterialTypeList {
  name: string,
  value: number,
}

export interface UsefulMaterialPostBody {
  title: string,
  content?: string,
  type: UsefulMaterialType,
  publishDatetime: string,
  courseIds?: number[],
  mediaIds?: number[],
}

export interface UsefulMaterialPatchBody {
  id?: string,
  title: string,
  content?: string,
  type: UsefulMaterialType,
  publishDatetime: string,
  courseIds: number[],
  mediaIds?: number[],
  isNotify?: boolean,
}

export interface FullUsefulMaterialCourses {
  name: string,
  value: number,
}

export interface FullUsefulMaterial {
  id: number,
  type: UsefulMaterialType,
  title: string,
  content: string,
  courses: FullUsefulMaterialCourses[],
  publishDatetime: string,
  media: MediaResponse[],
  isViewed: boolean,
}

export interface MediaResponse {
  id: number,
  type: string,
  filename: string,
  url: string,
  size: number,
}

export interface UsefulMaterialPaginator {
  data: ShortUsefulMaterial[],
  meta: UsefulMaterialPaginatorMeta,
}

export interface UsefulMaterialMasterPaginator {
  data: FullUsefulMaterial[],
  meta: UsefulMaterialPaginatorMeta,
}

export interface ShortUsefulMaterial {
  id: number,
  title: string,
  type: UsefulMaterialType,
  publishDatetime: string,
}

export interface UsefulMaterialPaginatorMetaLinks {
  url: string,
  label: string,
  active: boolean,
}

export interface UsefulMaterialPaginatorMeta {
  currentPage: number,
  from: number,
  lastPage: number,
  perPage: number,
  to: number,
  total: number,
}

export interface PaginatorLinks {
  first: string,
  last: string,
  prev: string,
  next: string,
}

export interface HeartRequest {
  message: string,
}

export interface NewsScheduleData {
  id: number|null,
  datetime: string,
}

export interface NewsStoreData {
  name: string,
  url: string | null,
  text: string,
  displayMode: NewsDisplayMode,
  viewStartAt: string|null, // Если выбран режим показа (display_mode) == schedule, то это поле будет автоматически заполнено на бэке
  viewEndAt: string|null, // Если выбран режим показа (display_mode) == schedule, то это поле будет автоматически заполнено на бэке
  viewLimit: number|null,
  courseIds: number[],
  desktopMediaId: number | null,
  mobileMediaId: number | null,
  mediaIds: number[],
  scheduleDates: NewsScheduleData[], // Если выбран режим показа (display_mode) == schedule, то массив не может быть пустым
}

export interface MistakeStoreData {
  correction: string,
  completed: boolean,
}

export interface TaskDraftStoreData {
  deviceHash: string,
  previousHash: string | null,
  content: string,
  questions: Array<EducationLargeTaskDecideQuestionRequest|EducationLargeTaskDecideCodingQuestionRequest>,
}

export interface TaskRateDraftStoreData {
  deviceHash: string,
  previousHash: string | null,
  points: string,
  comment: string,
  answer: string, // нейминг повторяет запрос выставления оценки, НО почему answer? почему не message, например?
  mentorRateComments: EducationAnswerRateComment[], // Используется для устной части и программирования, для других типов ДЗ передаем пустой массив
  answersRatePoints: EducationAnswerRate[], // Используется для ДЗ с типом программирование
}

export interface StoreMessageDraftRequest {
  deviceHash: string,
  previousHash: string | null,
  content: string,
}

export interface AssignToCourseRequest {
  reviewIds: number[],
  reviewLink?: string,
}

export interface TaskDraftResource {
  deviceHash: string,
  previousHash: string | null,
  hash: string,
  createdAt: string,
  expireAt: string,
  content: string,
  questions?: Array<EducationLargeTaskDecideQuestionRequest|EducationLargeTaskDecideCodingQuestionRequest>,
}

export interface MessageDraftResource {
  deviceHash: string,
  previousHash: string | null,
  hash: string | null,
  expireAt: string | null,
  content: string | null,
  createdAt: string | null,
}

export interface TaskRateDraftResource {
  deviceHash: string,
  previousHash: string | null,
  hash: string,
  createdAt: string,
  expireAt: string,
  points: string,
  comment: string,
  answer: string, // нейминг повторяет запрос выставления оценки, НО почему answer? почему не message, например?
  mentorRateComments: EducationAnswerRateComment[], // Используется для устной части и программирования, для других типов ДЗ передаем пустой массив
  answersRatePoints: EducationAnswerRate[], // Используется для ДЗ с типом программирование
}

export interface TeacherLargeResourcePaginator {
  meta: PaginatorMeta,
  data: TeacherLargeResource[],
}

export interface TeacherLargeResource {
  id: number,
  name: string,
  surname: string,
  introduction: string,
  photo: string,
  achievements: TeacherAchievementResource,
  subject: NameValueResource,
  emojiSlug: TeacherEmojiSlugs,
}

export interface TeacherAchievementResource {
  id: number,
  name: string,
  value: string,
  position: number,
  slug: TeacherAchievementSlug,
}

export interface TeacherShortResource {
  id: number,
  name: string,
  surname: string,
  photo: string,
  subject: NameValueResource,
  emojiSlug: TeacherEmojiSlugs,
}

export interface ReviewResource {
  id: number,
  name: string | null,
  surname: string | null,
  examScore: number,
  content: string | null,
  videoUrl: any | null,
  type: ReviewType,
  subject: NameValueResource,
  parentCourse: string|null,
  photo: MediaResource|null,
  avatar?: MediaResource|null,
  thumbnail: MediaResource|null,
  isCredit: boolean,
}

export interface ReviewPaginator {
  meta: PaginatorMeta,
  data: ReviewResource[],
}

export interface CourseShopCardResource {
  courseTitle: string,
  courseStart: string,
  courseEnd: string,
  teachers: TeacherLargeResource[],
  reviews: ReviewsCourseShopCardPart,
  program: ProgramCourseShopCardPart,
  groups: GroupShopResource[],
  type: NameValueResource,
  subjectAlias: string,
  shopLabel: string | null,
}

export interface MasterRecommendationResource {
	card: MasterCourseShortResource,
	type: RecommendationType,
	position: number,
}

export interface ReviewsCourseShopCardPart {
  reviewLink: string,
  reviews: ReviewResource[],
}

export interface ProgramCourseShopCardPart {
  media: MediaResponse,
  text: ProgramTextPart[],
}

export interface ProgramTextPart {
  date: string,
  description: string,
}

export interface GroupShopResource {
  type: NameValueResource,
  description: string,
  standardDiff: string,
  price: number,
  hasSale: boolean,
}

export interface FullProgramResource {
  programMedia: MediaResource,
  program: ProgramMonthShortResource[],
}

export interface CourseReviewsResource {
  reviewLink: string | null,
  reviews: ReviewResource[],
}

export interface RecommendationPartResource {
	id: number,
	title: string,
	position: number,
}

export interface RecommendationResource {
	recommendedByDefault: boolean,
	recommendations: RecommendationPartResource[],
}

export interface PaymentOrdersOrderUuidPayGetParams {
  isRecurrent?: boolean, // Подключать ли автоплатёж
  email?: string, // Обязателен для не авторизованных пользователей если не передан phone
  phone?: string, // Обязателен для не авторизованных пользователей если не передан email
  paymentSystem?: 'default'|'sbp', // Платежная система
}

export interface ManagerUsersGetParams {
  search?: SmartSearchRequest,
  email?: string,
  type?: UserType,
  query?: string,
  courseId?: number, // При отправке `group_type` без этого параметра - будет ошибка
  groupType?: GroupType, // Будет работать только при наличии `course_id`
  monthId?: number, // Будет зависить только от `course_id`
  status?: 'active' | 'inactive',
  as?: UserType, // Если передать master то будет искать по заказу и почте среди мастеров, а если manager, то по почте и только тех, у кого есть права
  roleId?: number,
}

export interface ManagerUsersSelectGetParams {
  search?: SmartSearchFilter,
  roleType?: RoleType,
  roleTypes?: RoleType[],
  query?: string,
}

export interface ManagerCoursesTreeSelectGetParams {
  isPublished?: boolean,
  subjectId?: number,
  groupType?: GroupType,
  query?: string,
  groupReturnId?: boolean,
  sortField?: 'published_at' | 'created_at',
  sortOrder?: 'asc' | 'desc',
}

export interface ManagerTeachersGetParams {
  search?: string,
  subjectId?: number,
}

export interface ManagerReviewsGetParams {
  search?: string,
  subjectId?: number,
  sortField?: 'author' | 'exam_score' | 'type',
  sortOrder?: 'asc' | 'desc',
}

export interface ManagerCoursesSelectGetParams {
  isPublished?: boolean,
  subjectId?: number,
  subjectIds?: number[],
  groupType?: GroupType,
  query?: string,
  sortField?: 'published_at' | 'created_at',
  sortOrder?: 'asc' | 'desc',
  excludeCourseIds?: number[],
  showHiddenAt?: boolean,
}

export interface ManagerKnowledgeCoursesGetParams {
  isPublished?: boolean,
  subjectId?: number,
  groupType?: GroupType,
  query?: string,
  sortField?: 'published_at' | 'created_at',
  perPage: number,
  sortOrder?: 'asc' | 'desc',
}

export interface ManagerCoursesGetParams {
  isPublished?: boolean,
  subjectId?: number,
  groupType?: GroupType,
  query?: string,
  sortField?: 'published_at' | 'created_at',
  perPage?: number,
  sortOrder?: 'asc' | 'desc',
  marketableOnly?: boolean,
}

export interface ManagerCoursesPackagesSearchGetParams {
  groupType?: GroupType,
  subjectId?: number,
  excludeGroupId?: number,
  groupId?: number,
  courseId?: number,
  excludeCourseId?: number,
  excludeGroupType?: GroupType,
  query?: string,
  perPage?: number,
  packageType?: PackageType,
}

export interface ManagerNewsGetParams {
  onlyAvailable: boolean,
  courseIds?: number[],
  viewStartAt?: string,
  viewEndAt?: string,
  search?: string,
  perPage: number,
  page?: number,
}

export interface SupportGetParams {
  myRequest?: boolean,
  status?: 'closed' | 'in_work' | 'open',
  query?: string,
  perPage?: number,
  sortField?:
  | 'theme'
  | 'author'
  | 'status'
  | 'last_message_at'
  | 'resolved_at'
  | 'created_at',
  sortOrder?: 'asc' | 'desc',
}

export interface FaqGetParams {
  query?: string,
}

export interface ProfileNotificationsGetParams {
  userType?: UserType, // По умолчанию master
}

export interface ProfileNotificationsPatchParams {
  userType?: UserType, // По умолчанию master
}

export interface ManagerMentorsSelectGetParams {
  self?: boolean,
}

export interface ManagerMentorsGetParams {
  perPage?: number,
  query?: string,
  unallocatedOnly?: boolean,
}

export interface ManagerLessonsGetParams {
  courseId?: number,
  query?: string,
  subjectId?: number,
  monthId?: number,
}

export interface ManagerTemplatesExercisesGetParams {
  subjectId?: number,
  accessLevel?: ExerciseAccessLevel,
  taskType?: TaskType,
  query?: string,
}

export interface ManagerCoursesIdProgramMonthIdExercisesGetParams {
  accessLevel?: ExerciseAccessLevel,
}

export interface ManagerMastersSelectGetParams {
  query?: string,
  courseId?: number,
  groupId?: number,
  unallocatedOnly?: boolean,
  timezoneGroupId?: number,
}

export interface ManagerMasterGroupsSelectGetParams {
  self?: boolean,
  query?: string,
  masterIds?: number[], // Этот параметр будет главным
  groupType?: GroupType,
  mentorId?: number,
  masterGroupIds?: number[],
}

export interface ManagerMasterGroupsMasterGroupIdLessonsGetParams {
  monthId?: number,
  status?: FilterLessonStatus,
  self?: boolean,
}

export interface ManagerMasterGroupsMastersUnallocatedGetParams {
  courseId: number,
  query?: string,
  groupType: GroupType,
}

export interface ManagerMasterGroupsGetParams {
  courseId?: number,
  groupType?: GroupType,
  subjectId?: number,
  monthId?: number,
  timezoneGroupId?: number,
}

export interface ManagerMasterGroupsMasterGroupIdMastersGetParams {
  monthId?: number,
  query?: string,
}

export interface ManagerMasterGroupsMasterGroupIdMastersSelectGetParams {
  monthId?: number,
  query?: string,
}

export interface ManagerMasterGroupsMasterGroupIdMastersMasterUuidGetParams {
  monthId?: number,
  healthOnly?: boolean,
}

export interface ManagerMasterGroupsMasterGroupIdMastersMasterIdLessonsGetParams {
  monthId?: number,
  status?: FilterLessonStatus,
}

export interface ManagerActionsGetParams {
  userId: number,
  alias?: LogType,
}

export interface ManagerCoursesLinksGetParams {
  subjectId?: number,
  groupType?: GroupType,
  query?: string,
  educationAt?: string,
  closedForSales?: boolean,
}

export interface ManagerOrdersGetParams {
  courseId?: number,
  userId?: number,
  self?: boolean,
  groupType?: GroupType,
  status?: OrderStatus,
  createdAt?: string,
  paidAt?: string,
  saleIds?: number[],
  price?: number,
  paidStartAt?: string,
  paidEndAt?: string,
  createdStartAt?: string,
  createdEndAt?: string,
  cashboxId?: number,
  packageType?: PackageType,
  packageIds?: number[],
}

export interface ManagerOrdersPricesGetParams {
  courseId?: number,
  saleIds?: number[],
  groupType?: GroupType,
  status?: OrderStatus,
  createdAt?: string,
  paidAt?: string,
  self?: boolean,
  paidStartAt?: string,
  paidEndAt?: string,
  createdStartAt?: string,
  createdEndAt?: string,
}

export interface ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidFormsGetParams {
  status?: ExerciseStatus,
  self?: boolean,
  taken?: boolean,
  userIds?: number[],
}

export interface ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdGetParams {
  formId?: number,
}

export interface ManagerMasterGroupsMasterGroupIdExercisesGetParams {
  status?: ExerciseStatus,
  filterStatus?: FilterExerciseStatus,
  monthId?: number,
  self?: boolean,
  mentorId?: number,
  masterId?: number,
}

export interface ManagerMasterGroupsMasterGroupIdMastersMasterIdExercisesGetParams {
  monthId?: number,
  status?: ExerciseStatus,
  filterStatus?: FilterExerciseStatus,
}

export interface ManagerMasterGroupsMasterGroupIdExercisesDrawerGetParams {
  monthId?: number,
}

export interface ManagerSalesCustomGetParams {
  userId: number,
  packageIds: number[],
}

export interface ManagerSalesGetParams {
  perPage?: number,
  page?: number,
  query?: string,
  startDate?: string,
  endDate?: string,
  status?: SaleStatus,
}

export interface ManagerSalesStatisticGetParams {
  saleSource?: number,
  saleTarget?: number,
  sourceStatus?: OrderStatus,
  targetStatus?: OrderStatus,
  search?: string,
  targetPaidPeriodStart?: string,
  targetPaidPeriodEnd?: string,
  sourcePaidPeriodStart?: string,
  sourcePaidPeriodEnd?: string,
  targetMonthsCount?: number,
  sourceMonthsCount?: number,
  saleId?: number,
  perPage?: number,
  page?: number,
}

export interface MasterCoursesKnowledgeGetParams {
  subjectId?: number,
  query?: string,
}

export interface MasterCoursesGetParams {
  type?: CourseType,
  subjectId?: number,
  hasDiscount?: boolean,
}

export interface MasterOrdersGetParams {
  courseId?: number,
  groupType?: GroupType,
  status?: OrderStatus,
  createdAt?: string,
  paidAt?: string,
  year?: string,
  sortField?: string,
  sortOrder?: 'asc' | 'desc',
  packageIds?: number[],
}

export interface MasterOrdersOrderIdCodePostParams {
  code: string,
}

export interface MasterNotesGetParams {
  search?: string,
  perPage?: number,
  page: number,
}

export interface MasterEducationMasterGroupsGetParams {
  all?: boolean,
}

export interface MasterEducationMasterGroupIdExercisesGetParams {
  monthId?: number,
  type?: TaskType,
  isProbe?: boolean,
}

export interface MasterEducationMasterGroupIdExercisesDrawerGetParams {
  monthId?: number,
  type?: TaskType,
  isProbe?: boolean,
}

export interface MasterEducationMasterGroupIdExercisesJournalGetParams {
  monthId?: number,
}

export interface ManagerMasterGroupsMasterGroupIdJournalGetParams {
  monthId?: number,
}

export interface ManagerCalendarGetParams {
  startAt: string,
  endAt: string,
  filters: CalendarFilter[],
}

export interface ManagerMasterGroupsMasterGroupIdCalendarGetParams {
  startAt: string,
  endAt: string,
}

export interface ManagerMasterGroupsAllocateGetParams {
  courseId: number,
  groupId: number,
}

export interface MasterEducationCalendarGetParams {
  startAt: string,
  endAt: string,
  filters: CalendarFilter[],
}

export interface MasterEducationMasterGroupIdCalendarGetParams {
  startAt: string,
  endAt: string,
  exerciseStatus?: ExerciseStatus,
  lessonStatus?: FilterLessonStatus,
}

export interface MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidGetParams {
  formId?: number,
}

export interface MasterEducationMasterGroupIdLessonsGetParams {
  monthId?: number,
  status?: FilterLessonStatus,
}

export interface MasterEducationMasterGroupIdLessonsDrawerGetParams {
  monthId?: number,
  status?: FilterLessonStatus,
}

export interface MasterEducationMasterGroupIdLessonsLessonIdGetParams {
  monthId?: number,
  month?: string,
}

export interface ManagerPromotionsGetParams {
  perPage?: number,
  query?: string,
  startAt?: string,
  endAt?: string,
}

export interface ManagerUsefulCourseCourseIdGetParams {
  perPage?: number, // кол-во записей на странице пагинатора
  type?: UsefulMaterialType, // кол-во записей на странице пагинатора
  title?: string, // Сортировка по названию, происходит через where like %title&
  publishDate?: string, // Строка времени в формате yyyy-MM-dd
  sortOrder?: 'asc' | 'desc', // Меняем порядок сортировки
}

export interface ManagerUsefulCourseCourseIdSelectGetParams {
  query?: string,
}

export interface MasterCoursesUsefulCourseIdGetParams {
  perPage?: number, // кол-во записей на странице пагинатора
  type?: UsefulMaterialType, // Тип полезного материала
  title?: string, // Сортировка по названию, происходит через where like %title&
  startDate?: string, // Строка времени в формате yyyy-mm-dd
  endDate?: string, // Строка времени в формате yyyy-mm-dd
  sortOrder?: 'asc' | 'desc', // Меняем порядок сортировки
}

export interface MasterCoursesUsefulCourseIdSelectGetParams {
  query?: string,
}

export interface MasterKnowledgeCoursesGetParams {
  perPage: number,
  page?: number,
  subjectId?: number,
  query?: string,
}

export interface ManagerKnowledgeCoursesGetParams {
  perPage: number,
  page: number,
  subjectId?: number,
  query?: string,
}

export interface ManagerKnowledgeCourseIdGetParams {
  perPage: number,
  page?: number,
}

export const AgreementsPostRequest = (
  body: AgreementsPostResponse,
  options?: any,
) => request<any>('/agreements', { body, method: 'POST', ...options })

// Авторизация
export const AuthLoginPostRequest = (body: AuthLogin, options?: any) =>
  request<UserDataResource>('/auth/login', {
    body,
    method: 'POST',
    ...options,
  })

// Выход
export const AuthLogoutPostRequest = (options?: any) =>
  request<LogoutResponse>('/auth/logout', { method: 'POST', ...options })

// Регистрация
export const AuthRegisterPostRequest = (body: AuthRegister, options?: any) =>
  request<UserDataResource>('/auth/register', {
    body,
    method: 'POST',
    ...options,
  })

// Активация пользователя после регистрации
export const AuthRegisterTokenPostRequest = (body: AuthToken, options?: any) =>
  request<UserDataResource>('/auth/register/token', {
    body,
    method: 'POST',
    ...options,
  })

// Восстановление пароля
export const AuthRestorePostRequest = (body: AuthRestore, options?: any) =>
  request<RestoreDataResource>('/auth/restore', {
    body,
    method: 'POST',
    ...options,
  })

// Подтверждение восстановления
export const AuthRestoreTokenPostRequest = (body: AuthRestore, options?: any) =>
  request<RestoreDataResource>('/auth/restore/token', {
    body,
    method: 'POST',
    ...options,
  })

// Авторизация
export const AuthVkPostRequest = (body: VkAuthLogin, options?: any) =>
  request<UserDataResource>('/auth/vk', { body, method: 'POST', ...options })

// Определение города по IP
export const CityGetRequest = (options?: any) => request<CityResource>('/city', { method: 'GET', ...options })

// Запрос на получение базовой информации из системы
export const ConstantGetRequest = (options?: any) =>
  request<ConstantResource>('/constant', { method: 'GET', ...options })

// Все справочники в одном
export const DictionariesGetRequest = (options?: any) =>
  request<DictionaryResource>('/dictionaries', { method: 'GET', ...options })

// Кассы
export const DictionariesCashboxesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/cashboxes', {
    method: 'GET',
    ...options,
  })

// Города
export const DictionariesCitiesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/cities', {
    method: 'GET',
    ...options,
  })

// Цвета для заметок
export const DictionariesColorsGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/colors', {
    method: 'GET',
    ...options,
  })

// типы курсов
export const DictionariesCourseTypesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/course-types', {
    method: 'GET',
    ...options,
  })

// Уровни доступа контейнеров ДЗ
export const DictionariesExerciseAccessLevelsGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/exercise-access-levels', {
    method: 'GET',
    ...options,
  })

// Типы групп
export const DictionariesGroupTypesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/group-types', {
    method: 'GET',
    ...options,
  })

export const DictionariesPackageTypesGetRequest = (options?: any) => request<NameValueResource[]>('/dictionaries/package-types', { method: 'GET', ...options })

// Тексты сообщений об ошибках при оплате
export const DictionariesPaymentErrorsGetRequest = (options?: any) => request<NameValueResource[]>('/dictionaries/payment-errors', { method: 'GET', ...options })

// Типы промо-кодов
export const DictionariesPromotionCodeTypesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/promotion-code-types', {
    method: 'GET',
    ...options,
  })

// Типы скидок промо-кодов
export const DictionariesPromotionDiscountTypesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/promotion-discount-types', {
    method: 'GET',
    ...options,
  })

// На какие продукты действует скидка промо-кода
export const DictionariesPromotionProductTypesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/promotion-product-types', {
    method: 'GET',
    ...options,
  })

// Статусы скидок
export const DictionariesSaleStatusesGetRequest = (options?: any) => request<NameValueResource[]>('/dictionaries/sale-statuses', { method: 'GET', ...options })

// Типы позиций учавствующих в скидках (для target_type и source_type)
export const DictionariesSaleUnitTypesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/sale-unit-types', {
    method: 'GET',
    ...options,
  })

// Предметы
export const DictionariesSubjectsGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/subjects', {
    method: 'GET',
    ...options,
  })

// Типы вопросов в задачах ДЗ
export const DictionariesTaskQuestionTypesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/task-question-types', {
    method: 'GET',
    ...options,
  })

// Типы оценивания задач в ДЗ
export const DictionariesTaskRateTypesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/task-rate-types', {
    method: 'GET',
    ...options,
  })

// Типы вопросов для ДЗ с типом устная часть.
export const DictionariesTaskSpeakingQuestionTypesGetRequest = (
  options?: any,
) =>
  request<NameValueResource[]>('/dictionaries/task-speaking-question-types', {
    method: 'GET',
    ...options,
  })

// Типы задач в ДЗ
export const DictionariesTaskTypesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/task-types', {
    method: 'GET',
    ...options,
  })

// Статусы тикетов
export const DictionariesTicketStatusesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/ticket-statuses', {
    method: 'GET',
    ...options,
  })

// Временные зоны
export const DictionariesTimezonesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/timezones', {
    method: 'GET',
    ...options,
  })

export const DictionariesUsefulMaterialTypesGetRequest = (options?: any) =>
  request<NameValueResource[]>('/dictionaries/useful-material-types', {
    method: 'GET',
    ...options,
  })

// получение драфта сообщения
export const DraftsMessagesFormIdGetRequest = (formId: any, options?: any) => request<MessageDraftResource>(`/drafts/message/${formId}`, { method: 'GET', ...options })


// Сохранение драфта сообщения
export const DraftsMessagesFormIdPostRequest = (formId: any, body: StoreMessageDraftRequest, options?: any) => request<MessageDraftResource>(`/drafts/message/${formId}`, { body, method: 'POST', ...options })

// получение драфта формы выставления оценки
export const DraftsTaskTaskUuidMasterIdRateGetRequest = (taskUuid: string, masterId: number, options?: any) => request<TaskRateDraftResource>(`/drafts/task/${taskUuid}/${masterId}/rate`, { method: 'GET', ...options })

// сохранение драфта формы выставления оценки
export const DraftsTaskTaskUuidMasterIdRatePostRequest = (taskUuid: string, masterId: number, body: TaskRateDraftStoreData, options?: any) => request<TaskRateDraftResource>(`/drafts/task/${taskUuid}/${masterId}/rate`, { body, method: 'POST', ...options })

// получение драфта дз
export const DraftsTaskTaskUuidMasterIdTaskTypeGetRequest = (taskUuid: string, masterId: number, taskType: TaskType, options?: any) => request<TaskDraftResource>(`/drafts/task/${taskUuid}/${masterId}/${taskType}`, { method: 'GET', ...options })

// сохранение драфта дз
export const DraftsTaskTaskUuidMasterIdTaskTypePostRequest = (taskUuid: string, masterId: number, taskType: TaskType, body: TaskDraftStoreData, options?: any) => request<TaskDraftResource>(`/drafts/task/${taskUuid}/${masterId}/${taskType}`, { body, method: 'POST', ...options })

// Список часто задаваемых вопросов
export const FaqGetRequest = (params?: FaqGetParams, options?: any) => request<NameValueResource[]>('/faq', { method: 'GET', params, ...options })

// Создание вопроса и ответа
export const FaqPostRequest = (body: FaqStoreRequest, options?: any) => request<FaqResource>('/faq', { body, method: 'POST', ...options })

// Просмотр вопроса и ответа
export const FaqIdGetRequest = (id: number, options?: any) => request<FaqResource>(`/faq/${id}`, { method: 'GET', ...options })

// Редактирование вопроса и ответа
export const FaqIdPostRequest = (id: number, body: FaqStoreRequest, options?: any) => request<FaqResource>(`/faq/${id}`, { body, method: 'POST', ...options })

// Удаление вопроса и ответа
export const FaqIdDeleteRequest = (id: number, options?: any) => request<any>(`/faq/${id}`, { method: 'DELETE', ...options })

// Просмотр логов. Запрос будет работать только с user_id
export const ManagerActionsGetRequest = (
  params: ManagerActionsGetParams,
  options?: any,
) =>
  request<LogResource[]>('/manager/actions', {
    method: 'GET',
    params,
    ...options,
  })

// Получить события календаря по фильтру
export const ManagerCalendarGetRequest = (params: ManagerCalendarGetParams, options?: any) => request<CalendarPageResource>('/manager/calendar', { method: 'GET', params, ...options })

// Получить сохраненные фильтры календаря
export const ManagerCalendarFiltersGetRequest = (options?: any) => request<CalendarFiltersData>('/manager/calendar/filters', { method: 'GET', ...options })

// Сохранить фильтры календаря
export const ManagerCalendarFiltersPostRequest = (body: CalendarFiltersData, options?: any) => request<any>('/manager/calendar/filters', { body, method: 'POST', ...options })

// Счетчики менеджера
export const ManagerCountersGetRequest = (options?: any) =>
  request<CountersResource>('/manager/counters', { method: 'GET', ...options })

// Просмотр курсов
export const ManagerCoursesGetRequest = (
  params?: ManagerCoursesGetParams,
  options?: any,
) =>
  request<CourseShortResourcePaginator>('/manager/courses', {
    method: 'GET',
    params,
    ...options,
  })

// Создание курса
export const ManagerCoursesPostRequest = (
  body: CourseStoreData,
  options?: any,
) =>
  request<CourseLargeResource>('/manager/courses', {
    body,
    method: 'POST',
    ...options,
  })

// Ссылки на покупку курсов
export const ManagerCoursesLinksGetRequest = (
  params?: ManagerCoursesLinksGetParams,
  options?: any,
) =>
  request<CourseLinkResourcePaginator>('/manager/courses/links', {
    method: 'GET',
    params,
    ...options,
  })

// Поиск по пакетам
export const ManagerCoursesPackagesSearchGetRequest = (
  params?: ManagerCoursesPackagesSearchGetParams,
  options?: any,
) =>
  request<PackageShortResourcePaginator>('/manager/courses/packages/search', {
    method: 'GET',
    params,
    ...options,
  })

// Поиск по курсам
export const ManagerCoursesSelectGetRequest = (
  params?: ManagerCoursesSelectGetParams,
  options?: any,
) =>
  request<NameValueResource[]>('/manager/courses/select', {
    method: 'GET',
    params,
    ...options,
  })

// Поиск по курсам и получение его дерева
export const ManagerCoursesTreeSelectGetRequest = (
  params?: ManagerCoursesTreeSelectGetParams,
  options?: any,
) =>
  request<NameValueChildrenResource[]>('/manager/courses/tree/select', {
    method: 'GET',
    params,
    ...options,
  })

// Получение списка курсов с нераспределенными мастерами
export const ManagerCoursesUnallocatedGetRequest = (options?: any) =>
  request<NameValueChildrenCustomResource[]>('/manager/courses/unallocated', {
    method: 'GET',
    ...options,
  })

// Получить курс
export const ManagerCoursesIdGetRequest = (id: number, options?: any) =>
  request<CourseLargeResource>(`/manager/courses/${id}`, {
    method: 'GET',
    ...options,
  })

// Обновление
export const ManagerCoursesIdPostRequest = (
  id: number,
  body: CourseStoreData,
  options?: any,
) =>
  request<CourseLargeResource>(`/manager/courses/${id}`, {
    body,
    method: 'POST',
    ...options,
  })

// Обновление
export const ManagerCoursesIdDeleteRequest = (id: number, options?: any) =>
  request<any>(`/manager/courses/${id}`, { method: 'DELETE', ...options })

// Клонирование курса
export const ManagerCoursesIdClonePostRequest = (
  id: number,
  body?: CourseCloneData,
  options?: any,
) =>
  request<CourseLargeResource>(`/manager/courses/${id}/clone`, {
    body,
    method: 'POST',
    ...options,
  })

// Получение инфы о группе
export const ManagerCoursesIdGroupGroupTypeGetRequest = (
  id: number,
  groupType: GroupType,
  options?: any,
) =>
  request<GroupLargeResource>(`/manager/courses/${id}/group/${groupType}`, {
    method: 'GET',
    ...options,
  })

// Добавление\обновление группы
export const ManagerCoursesIdGroupGroupTypePostRequest = (
  id: number,
  groupType: GroupType,
  body?: GroupStoreData,
  options?: any,
) =>
  request<GroupLargeResource>(`/manager/courses/${id}/group/${groupType}`, {
    body,
    method: 'POST',
    ...options,
  })

// Добавление\обновление группы
export const ManagerCoursesIdGroupGroupTypeDeleteRequest = (
  id: number,
  groupType: GroupType,
  options?: any,
) =>
  request<any>(`/manager/courses/${id}/group/${groupType}`, {
    method: 'DELETE',
    ...options,
  })

// Просмотр пакетов групп
export const ManagerCoursesIdGroupGroupTypePackagesGetRequest = (
  id: number,
  groupType: GroupType,
  options?: any,
) =>
  request<GroupPackagesResource>(
    `/manager/courses/${id}/group/${groupType}/packages`,
    { method: 'GET', ...options },
  )

// Добавление/обновление пакетов в группе
export const ManagerCoursesIdGroupGroupTypePackagesPostRequest = (
  id: number,
  groupType: GroupType,
  body: GroupPackagesData,
  options?: any,
) =>
  request<GroupPackagesResource>(
    `/manager/courses/${id}/group/${groupType}/packages`,
    { body, method: 'POST', ...options },
  )

// Просмотр пакетов групп
export const ManagerCoursesIdProgramGetRequest = (id: number, options?: any) => request<FullProgramResource>(`/manager/courses/${id}/program`, { method: 'GET', ...options })

// Сохранение программы курса
export const ManagerCoursesIdProgramPostRequest = (id: number, body: CourseProgramRequest, options?: any) => request<FullProgramResource>(`/manager/courses/${id}/program`, { body, method: 'POST', ...options })

// Просмотр пакетов групп
export const ManagerCoursesIdProgramMonthIdGetRequest = (
  id: number,
  monthId: number,
  options?: any,
) =>
  request<ProgramMonthLargeResource>(
    `/manager/courses/${id}/program/${monthId}`,
    { method: 'GET', ...options },
  )

// Список домашек
export const ManagerCoursesIdProgramMonthIdExercisesGetRequest = (
  id: number,
  monthId: number,
  params?: ManagerCoursesIdProgramMonthIdExercisesGetParams,
  options?: any,
) =>
  request<ExerciseShortResource[]>(
    `/manager/courses/${id}/program/${monthId}/exercises`,
    { method: 'GET', params, ...options },
  )

// Добавление ДЗ-контейнера
export const ManagerCoursesIdProgramMonthIdExercisesPostRequest = (
  id: number,
  monthId: number,
  body: ExerciseStore,
  options?: any,
) =>
  request<ExerciseLargeResource>(
    `/manager/courses/${id}/program/${monthId}/exercises`,
    { body, method: 'POST', ...options },
  )

// Просмотр дз
export const ManagerCoursesIdProgramMonthIdExercisesExerciseUuidGetRequest = (
  id: number,
  monthId: number,
  exerciseUuid: string,
  options?: any,
) =>
  request<ExerciseLargeResource>(
    `/manager/courses/${id}/program/${monthId}/exercises/${exerciseUuid}`,
    { method: 'GET', ...options },
  )

// Обновление ДЗ-контейнера
export const ManagerCoursesIdProgramMonthIdExercisesExerciseUuidPostRequest = (
  id: number,
  monthId: number,
  exerciseUuid: string,
  body: ExerciseStore,
  options?: any,
) =>
  request<ExerciseLargeResource>(
    `/manager/courses/${id}/program/${monthId}/exercises/${exerciseUuid}`,
    { body, method: 'POST', ...options },
  )

// Удаление ДЗ-контейнера
export const ManagerCoursesIdProgramMonthIdExercisesExerciseUuidDeleteRequest = (
  id: number,
  monthId: number,
  exerciseUuid: string,
  options?: any,
) =>
  request<any>(
    `/manager/courses/${id}/program/${monthId}/exercises/${exerciseUuid}`,
    { method: 'DELETE', ...options },
  )

// Список уроков
export const ManagerCoursesIdProgramMonthIdLessonsGetRequest = (
  id: number,
  monthId: number,
  options?: any,
) =>
  request<LessonShortResource[]>(
    `/manager/courses/${id}/program/${monthId}/lessons`,
    { method: 'GET', ...options },
  )

// Добавление урока в курс
export const ManagerCoursesIdProgramMonthIdLessonsPostRequest = (
  id: number,
  monthId: number,
  body: LessonStore,
  options?: any,
) =>
  request<LessonLargeResource>(
    `/manager/courses/${id}/program/${monthId}/lessons`,
    { body, method: 'POST', ...options },
  )

// Получение урока в курсе
export const ManagerCoursesIdProgramMonthIdLessonsLessonIdGetRequest = (
  id: number,
  monthId: number,
  lessonId: number,
  options?: any,
) =>
  request<LessonLargeResource>(
    `/manager/courses/${id}/program/${monthId}/lessons/${lessonId}`,
    { method: 'GET', ...options },
  )

// Обновление урока в курсе
export const ManagerCoursesIdProgramMonthIdLessonsLessonIdPostRequest = (
  id: number,
  monthId: number,
  lessonId: number,
  body: LessonStore,
  options?: any,
) =>
  request<LessonLargeResource>(
    `/manager/courses/${id}/program/${monthId}/lessons/${lessonId}`,
    { body, method: 'POST', ...options },
  )

// Удаление урока в курсе
export const ManagerCoursesIdProgramMonthIdLessonsLessonIdDeleteRequest = (
  id: number,
  monthId: number,
  lessonId: number,
  options?: any,
) =>
  request<any>(
    `/manager/courses/${id}/program/${monthId}/lessons/${lessonId}`,
    { method: 'DELETE', ...options },
  )

// Список отзывов пользователей о уроке
export const ManagerCoursesIdProgramMonthIdLessonsLessonIdReviewsGetRequest = (
  id: number,
  monthId: number,
  lessonId: number,
  options?: any,
) =>
  request<LessonReviewResource[]>(
    `/manager/courses/${id}/program/${monthId}/lessons/${lessonId}/reviews`,
    { method: 'GET', ...options },
  )

// Публикация
export const ManagerCoursesIdPublishPostRequest = (id: number, options?: any) =>
  request<CourseLargeResource>(`/manager/courses/${id}/publish`, {
    method: 'POST',
    ...options,
  })

// Просмотр курсов рекомендованных к покупке
export const ManagerCoursesIdRecommendationsGetRequest = (id: number, options?: any) => request<RecommendationResource>(`/manager/courses/${id}/recommendations`, { method: 'GET', ...options })

// Назначение кусров рекомендованных к покупке после приобретения текущего и добавление текущего курса в рекомендации по умолчанию
export const ManagerCoursesIdRecommendationsPostRequest = (id: number, body: SetRecommendationsRequest, options?: any) => request<RecommendationResource>(`/manager/courses/${id}/recommendations`, { body, method: 'POST', ...options })


// Просмотр отзывов прикрепленных к курсу
export const ManagerCoursesIdReviewGetRequest = (id: number, options?: any) => request<CourseReviewsResource>(`/manager/courses/${id}/review`, { method: 'GET', ...options })

// Прикрепление отзывов и ссылки на отзывы к курсу.
export const ManagerCoursesIdReviewAssignPatchRequest = (id: number, body: AssignToCourseRequest, options?: any) => request<CourseReviewsResource>(`/manager/courses/${id}/review/assign`, { body, method: 'PATCH', ...options })

// Снятие курса с публикации
export const ManagerCoursesIdUnpublishPostRequest = (
  id: number,
  options?: any,
) =>
  request<CourseLargeResource>(`/manager/courses/${id}/unpublish`, {
    method: 'POST',
    ...options,
  })

// Дашборд менеджера
export const ManagerDashboardMasterGroupIdGetRequest = (
  masterGroupId: number,
  options?: any,
) =>
  request<ManagerDashboardResource>(`/manager/dashboard/${masterGroupId}`, {
    method: 'GET',
    ...options,
  })

// Создание индивидуального ДЗ из нескольких
export const ManagerExercisesClonePostRequest = (
  body?: ExerciseCloneManyData,
  options?: any,
) =>
  request<ExerciseLargeResource>('/manager/exercises/clone', {
    body,
    method: 'POST',
    ...options,
  })

// Просмотр дз
export const ManagerExercisesExerciseUuidGetRequest = (
  exerciseUuid: string,
  options?: any,
) =>
  request<ExerciseLargeResource>(`/manager/exercises/${exerciseUuid}`, {
    method: 'GET',
    ...options,
  })

// Копирование ДЗ
export const ManagerExercisesExerciseUuidClonePostRequest = (
  exerciseUuid: string,
  body?: ExerciseCloneData,
  options?: any,
) =>
  request<ExerciseLargeResource>(`/manager/exercises/${exerciseUuid}/clone`, {
    body,
    method: 'POST',
    ...options,
  })

// Получение пагинатора курсов в базе знаний
export const ManagerKnowledgeCoursesGetRequest = (params: ManagerKnowledgeCoursesGetParams, options?: any) => request<KnowledgeCoursesPaginatorResource>('/manager/knowledge/courses', { method: 'GET', params, ...options })

// Получение name-value курса
export const ManagerKnowledgeCoursesCourseIdGetRequest = (courseId: number, options?: any) => request<NameValueResource>(`/manager/knowledge/courses/${courseId}`, { method: 'GET', ...options })

// Получение пагинатора папок курса
export const ManagerKnowledgeCourseIdGetRequest = (courseId: number, params: ManagerKnowledgeCourseIdGetParams, options?: any) => request<CourseFoldersPaginator>(`/manager/knowledge/${courseId}`, { method: 'GET', params, ...options })

// Редктирование дерева
export const ManagerKnowledgeCourseIdTreePatchRequest = (courseId: number, body: KnowledgeTreeResource[], options?: any) => request<KnowledgeTreeResource[]>(`/manager/knowledge/${courseId}/tree`, { body, method: 'PATCH', ...options })

// Получение дерева папки
export const ManagerKnowledgeCourseIdTreeCourseFolderIdGetRequest = (courseId: number, courseFolderId: number, options?: any) => request<KnowledgeTreeResource[]>(`/manager/knowledge/${courseId}/tree/${courseFolderId}`, { method: 'GET', ...options })

// Создание папки
export const ManagerKnowledgeCourseIdTreeCourseFolderIdFolderPostRequest = (courseId: number, courseFolderId: number, body: CreateFolderRequest, options?: any) => request<ShortKnowledgeResource>(`/manager/knowledge/${courseId}/tree/${courseFolderId}/folder`, { body, method: 'POST', ...options })

// Получение папки
export const ManagerKnowledgeCourseIdTreeCourseFolderIdFolderMediaFolderIdGetRequest = (courseId: number, courseFolderId: number, mediaFolderId: number, options?: any) => request<LargeKnowledgeResource>(`/manager/knowledge/${courseId}/tree/${courseFolderId}/folder/${mediaFolderId}`, { method: 'GET', ...options })

// Удалить папку
export const ManagerKnowledgeCourseIdTreeCourseFolderIdFolderMediaFolderIdDeleteRequest = (courseId: number, courseFolderId: number, mediaFolderId: number, options?: any) => request<any>(`/manager/knowledge/${courseId}/tree/${courseFolderId}/folder/${mediaFolderId}`, { method: 'DELETE', ...options })

// Сохранение названия и содержимого папки
export const ManagerKnowledgeCourseIdTreeCourseFolderIdFolderMediaFolderIdPatchRequest = (courseId: number, courseFolderId: number, mediaFolderId: number, body: SaveFolderRequest, options?: any) => request<LargeKnowledgeResource>(`/manager/knowledge/${courseId}/tree/${courseFolderId}/folder/${mediaFolderId}`, { body, method: 'PATCH', ...options })

// Список уроков
export const ManagerLessonsGetRequest = (
  params?: ManagerLessonsGetParams,
  options?: any,
) =>
  request<LessonShortResourcePaginator>('/manager/lessons', {
    method: 'GET',
    params,
    ...options,
  })

// Копиорвание урока
export const ManagerLessonsLessonIdClonePostRequest = (
  lessonId: number,
  body: LessonCloneData,
  options?: any,
) =>
  request<LessonLargeResource>(`/manager/lessons/${lessonId}/clone`, {
    body,
    method: 'POST',
    ...options,
  })

// Создание методички
export const ManagerManualPostRequest = (body: ManualData, options?: any) =>
  request<ManualResource>('/manager/manual', {
    body,
    method: 'POST',
    ...options,
  })

// Получения дерева методичек
export const ManagerManualTreeGetRequest = (options?: any) =>
  request<ManualTreeResource[]>('/manager/manual/tree', {
    method: 'GET',
    ...options,
  })

// Изменение расположения методичек
export const ManagerManualTreePostRequest = (
  body: ManualTreeResource[],
  options?: any,
) =>
  request<ManualTreeResource[]>('/manager/manual/tree', {
    body,
    method: 'POST',
    ...options,
  })

// Просмотр методички
export const ManagerManualIdGetRequest = (id: number, options?: any) =>
  request<ManualResource>(`/manager/manual/${id}`, {
    method: 'GET',
    ...options,
  })

// Изменение методички
export const ManagerManualIdPostRequest = (
  id: number,
  body: ManualData,
  options?: any,
) =>
  request<ManualResource>(`/manager/manual/${id}`, {
    body,
    method: 'POST',
    ...options,
  })

// Удаление
export const ManagerManualIdDeleteRequest = (id: number, options?: any) =>
  request<any>(`/manager/manual/${id}`, { method: 'DELETE', ...options })

// Просмотр списка мастер-групп
export const ManagerMasterGroupsGetRequest = (
  params?: ManagerMasterGroupsGetParams,
  options?: any,
) =>
  request<MasterGroupShortResourcePaginator>('/manager/master-groups', {
    method: 'GET',
    params,
    ...options,
  })

// Добавление мастер-группы
export const ManagerMasterGroupsPostRequest = (
  body: MasterGroupCreateRequest,
  options?: any,
) =>
  request<MasterGroupLargeResource>('/manager/master-groups', {
    body,
    method: 'POST',
    ...options,
  })

// Получение мастер групп в которые можно зачислить мастера
export const ManagerMasterGroupsAllocateGetRequest = (
  params: ManagerMasterGroupsAllocateGetParams,
  options?: any,
) =>
  request<NameValueResource[]>('/manager/master-groups/allocate', {
    method: 'GET',
    params,
    ...options,
  })

// Изменение мастеров в мастер-группе
export const ManagerMasterGroupsMastersTransferPostRequest = (
  body: MasterGroupTransferMastersRequest,
  options?: any,
) =>
  request<MasterAlreadyInGroupResource[]>(
    '/manager/master-groups/masters/transfer',
    { body, method: 'POST', ...options },
  )

// Просмотр списка нераспределенных мастеров
export const ManagerMasterGroupsMastersUnallocatedGetRequest = (params: ManagerMasterGroupsMastersUnallocatedGetParams, options?: any) => request<UnallocatedUserResourcePaginator>('/manager/master-groups/masters/unallocated', { method: 'GET', params, ...options })

// Получение списка мастер-групп
export const ManagerMasterGroupsSelectGetRequest = (
  params?: ManagerMasterGroupsSelectGetParams,
  options?: any,
) =>
  request<EducationMasterGroupResource[]>('/manager/master-groups/select', {
    method: 'GET',
    params,
    ...options,
  })

// Получение одной мастер-группы с интерфейсом селекта
export const ManagerMasterGroupsSelectMasterGroupIdGetRequest = (
  masterGroupId: number,
  options?: any,
) =>
  request<EducationMasterGroupResource>(
    `/manager/master-groups/select/${masterGroupId}`,
    { method: 'GET', ...options },
  )

// Исключение мастера из нераспределенных
export const ManagerMasterGroupsUnallocatedRejectPostRequest = (
  body: RejectFromUnallocatedRequest,
  options?: any,
) =>
  request<any>('/manager/master-groups/unallocated/reject', {
    body,
    method: 'POST',
    ...options,
  })

// Просмотр списка мастер-групп
export const ManagerMasterGroupsMasterGroupIdGetRequest = (
  masterGroupId: number,
  options?: any,
) =>
  request<MasterGroupLargeResource>(`/manager/master-groups/${masterGroupId}`, {
    method: 'GET',
    ...options,
  })

// Редактирование мастер-группы
export const ManagerMasterGroupsMasterGroupIdPostRequest = (
  masterGroupId: number,
  body: MasterGroupUpdateRequest,
  options?: any,
) =>
  request<MasterGroupLargeResource>(`/manager/master-groups/${masterGroupId}`, {
    body,
    method: 'POST',
    ...options,
  })

// Удаление мастер-группы
export const ManagerMasterGroupsMasterGroupIdDeleteRequest = (
  masterGroupId: number,
  options?: any,
) =>
  request<any>(`/manager/master-groups/${masterGroupId}`, {
    method: 'DELETE',
    ...options,
  })

// Календарь уроков и ДЗ
export const ManagerMasterGroupsMasterGroupIdCalendarGetRequest = (
  masterGroupId: number,
  params: ManagerMasterGroupsMasterGroupIdCalendarGetParams,
  options?: any,
) =>
  request<CalendarLargeResource>(
    `/manager/master-groups/${masterGroupId}/calendar`,
    { method: 'GET', params, ...options },
  )

// Просмотр домашних заданий
export const ManagerMasterGroupsMasterGroupIdExercisesGetRequest = (
  masterGroupId: number,
  params?: ManagerMasterGroupsMasterGroupIdExercisesGetParams,
  options?: any,
) =>
  request<ExerciseManagerCardResource[]>(
    `/manager/master-groups/${masterGroupId}/exercises`,
    { method: 'GET', params, ...options },
  )

// Добавление ДЗ-контейнера в мастер-группу
export const ManagerMasterGroupsMasterGroupIdExercisesPostRequest = (
  masterGroupId: number,
  body: ExerciseStore,
  options?: any,
) =>
  request<ExerciseLargeResource>(
    `/manager/master-groups/${masterGroupId}/exercises`,
    { body, method: 'POST', ...options },
  )

// Список домашек
export const ManagerMasterGroupsMasterGroupIdExercisesDrawerGetRequest = (
  masterGroupId: number,
  params?: ManagerMasterGroupsMasterGroupIdExercisesDrawerGetParams,
  options?: any,
) =>
  request<EducationDrawerExerciseResource[]>(
    `/manager/master-groups/${masterGroupId}/exercises/drawer`,
    { method: 'GET', params, ...options },
  )

// Получение информации по домашнему заданию
export const ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidGetRequest = (
  masterGroupId: number,
  exerciseUuid: string,
  options?: any,
) =>
  request<ExerciseLargeResource>(
    `/manager/master-groups/${masterGroupId}/exercises/${exerciseUuid}`,
    { method: 'GET', ...options },
  )

// Обновление ДЗ-контейнера
export const ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidPostRequest = (
  masterGroupId: number,
  exerciseUuid: string,
  body: ExerciseStore,
  options?: any,
) =>
  request<ExerciseLargeResource>(
    `/manager/master-groups/${masterGroupId}/exercises/${exerciseUuid}`,
    { body, method: 'POST', ...options },
  )

// Удаление ДЗ-контейнера
export const ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidDeleteRequest = (
  masterGroupId: number,
  exerciseUuid: string,
  options?: any,
) =>
  request<any>(
    `/manager/master-groups/${masterGroupId}/exercises/${exerciseUuid}`,
    { method: 'DELETE', ...options },
  )

// Получение информации по домашкам в рамках МГ группы
export const ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidFormsGetRequest = (
  masterGroupId: number,
  exerciseUuid: string,
  params?: ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidFormsGetParams,
  options?: any,
) =>
  request<ProgressResourcePaginator>(
    `/manager/master-groups/${masterGroupId}/exercises/${exerciseUuid}/forms`,
    { method: 'GET', params, ...options },
  )

// Отказ от ДЗ в работе
export const ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidRefuseMasterIdPostRequest = (
  masterGroupId: number,
  exerciseUuid: string,
  masterId: number,
  options?: any,
) =>
  request<any>(
    `/manager/master-groups/${masterGroupId}/exercises/${exerciseUuid}/refuse/${masterId}`,
    { method: 'POST', ...options },
  )

// Взять ДЗ в работу
export const ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidTakeMasterIdPostRequest = (
  masterGroupId: number,
  exerciseUuid: string,
  masterId: number,
  options?: any,
) =>
  request<any>(
    `/manager/master-groups/${masterGroupId}/exercises/${exerciseUuid}/take/${masterId}`,
    { method: 'POST', ...options },
  )

// Журнал ДЗ
export const ManagerMasterGroupsMasterGroupIdJournalGetRequest = (
  masterGroupId: number,
  params?: ManagerMasterGroupsMasterGroupIdJournalGetParams,
  options?: any,
) =>
  request<ManagerJournalRowResource[]>(
    `/manager/master-groups/${masterGroupId}/journal`,
    { method: 'GET', params, ...options },
  )

// Получить/создать тест повторение
export const MasterEducationMasterGroupIdExercisesRepetitionGetRequest = (
  masterGroupId: number,
  options?: any,
) =>
  request<EducationLargeTaskRepetitionResource>(
    `/master/education/${masterGroupId}/exercises/repetition`,
    { method: 'GET', ...options },
  )

// Сдать тест повторения
export const MasterEducationMasterGroupIdExercisesRepetitionPutRequest = (
  masterGroupId: number,
  body: EducationLargeTaskDecideRepetitionQuestionRequest,
  options?: any,
) =>
  request<EducationLargeTaskTestResource>(
    `/master/education/${masterGroupId}/exercises/repetition`,
    { body, method: 'PUT', ...options },
  )

// Получение списка уроков
export const ManagerMasterGroupsMasterGroupIdLessonsGetRequest = (
  masterGroupId: number,
  params?: ManagerMasterGroupsMasterGroupIdLessonsGetParams,
  options?: any,
) =>
  request<LessonEntityResourceContainer[]>(
    `/manager/master-groups/${masterGroupId}/lessons`,
    { method: 'GET', params, ...options },
  )

// Добавление урока в курс
export const ManagerMasterGroupsMasterGroupIdLessonsPostRequest = (
  masterGroupId: number,
  body: LessonStore,
  options?: any,
) =>
  request<ManagerLessonLargeResource>(
    `/manager/master-groups/${masterGroupId}/lessons`,
    { body, method: 'POST', ...options },
  )

// Получение урока в курсе
export const ManagerMasterGroupsMasterGroupIdLessonsLessonIdGetRequest = (
  masterGroupId: number,
  lessonId: number,
  options?: any,
) =>
  request<ManagerLessonLargeResource>(
    `/manager/master-groups/${masterGroupId}/lessons/${lessonId}`,
    { method: 'GET', ...options },
  )

// Обновление урока в курсе
export const ManagerMasterGroupsMasterGroupIdLessonsLessonIdPostRequest = (
  masterGroupId: number,
  lessonId: number,
  body: LessonStore,
  options?: any,
) =>
  request<ManagerLessonLargeResource>(
    `/manager/master-groups/${masterGroupId}/lessons/${lessonId}`,
    { body, method: 'POST', ...options },
  )

// Удаление урока в курсе
export const ManagerMasterGroupsMasterGroupIdLessonsLessonIdDeleteRequest = (
  masterGroupId: number,
  lessonId: number,
  options?: any,
) =>
  request<any>(`/manager/master-groups/${masterGroupId}/lessons/${lessonId}`, {
    method: 'DELETE',
    ...options,
  })

// Список отзывов пользователей о уроке
export const ManagerMasterGroupsMasterGroupIdLessonsLessonIdReviewsGetRequest = (
  masterGroupId: number,
  lessonId: number,
  options?: any,
) =>
  request<LessonReviewResource[]>(
    `/manager/master-groups/${masterGroupId}/lessons/${lessonId}/reviews`,
    { method: 'GET', ...options },
  )

// Просмотр списка мастеров в мастер-группе
export const ManagerMasterGroupsMasterGroupIdMastersGetRequest = (
  masterGroupId: number,
  params?: ManagerMasterGroupsMasterGroupIdMastersGetParams,
  options?: any,
) =>
  request<MasterShortResource[]>(
    `/manager/master-groups/${masterGroupId}/masters`,
    { method: 'GET', params, ...options },
  )

// Просмотр списка мастеров в мастер-группе для селекта
export const ManagerMasterGroupsMasterGroupIdMastersSelectGetRequest = (
  masterGroupId: number,
  params?: ManagerMasterGroupsMasterGroupIdMastersSelectGetParams,
  options?: any,
) =>
  request<UserShortResource[]>(
    `/manager/master-groups/${masterGroupId}/masters/select`,
    { method: 'GET', params, ...options },
  )

// Перемещение мастера из одной МГ в другую МГ
export const ManagerMasterGroupsMasterGroupIdMastersMasterIdAllocatePostRequest = (
  masterGroupId: number,
  masterId: number,
  body: MasterMoveReasonRequest,
  options?: any,
) =>
  request<any>(
    `/manager/master-groups/${masterGroupId}/masters/${masterId}/allocate`,
    { body, method: 'POST', ...options },
  )

// Получение доступных мастеру групп
export const ManagerMasterGroupsMasterGroupIdMastersMasterIdAvailableGroupsGetRequest = (
  masterGroupId: number,
  masterId: number,
  options?: any,
) =>
  request<NameValueChildrenResource[]>(
    `/manager/master-groups/${masterGroupId}/masters/${masterId}/available-groups`,
    { method: 'GET', ...options },
  )

// Просмотр домашних заданий мастера
export const ManagerMasterGroupsMasterGroupIdMastersMasterIdExercisesGetRequest = (
  masterGroupId: number,
  masterId: number,
  params?: ManagerMasterGroupsMasterGroupIdMastersMasterIdExercisesGetParams,
  options?: any,
) =>
  request<JournalLargeExerciseResource[]>(
    `/manager/master-groups/${masterGroupId}/masters/${masterId}/exercises`,
    { method: 'GET', params, ...options },
  )

// Получить список ДЗ за которые жизнь не снималась
export const ManagerMasterGroupsMasterGroupIdMastersMasterIdExercisesCompletedGetRequest = (
  masterGroupId: number,
  masterId: number,
  options?: any,
) =>
  request<NameValueResource[]>(
    `/manager/master-groups/${masterGroupId}/masters/${masterId}/exercises/completed/`,
    { method: 'GET', ...options },
  )

// Получить список ДЗ за которые жизнь снималась
export const ManagerMasterGroupsMasterGroupIdMastersMasterIdExercisesFailedGetRequest = (
  masterGroupId: number,
  masterId: number,
  options?: any,
) =>
  request<NameValueResource[]>(
    `/manager/master-groups/${masterGroupId}/masters/${masterId}/exercises/failed/`,
    { method: 'GET', ...options },
  )

// Восстановление жизни мастера в конкретной мастер-группе
export const ManagerMasterGroupsMasterGroupIdMastersMasterIdHeartCureExerciseIdPostRequest = (
  masterGroupId: number,
  masterId: number,
  exerciseId: number,
  body: HeartRequest,
  options?: any,
) =>
  request<MasterLargeResource>(
    `/manager/master-groups/${masterGroupId}/masters/${masterId}/heart/cure/${exerciseId}`,
    { body, method: 'POST', ...options },
  )

// Забрать жизнь у мастера конкретной мастер-группе
export const ManagerMasterGroupsMasterGroupIdMastersMasterIdHeartTakeExerciseIdPostRequest = (
  masterGroupId: number,
  masterId: number,
  exerciseId: number,
  body: HeartRequest,
  options?: any,
) =>
  request<MasterLargeResource>(
    `/manager/master-groups/${masterGroupId}/masters/${masterId}/heart/take/${exerciseId}`,
    { body, method: 'POST', ...options },
  )

// Просмотр уроков мастера в мастер-группе
export const ManagerMasterGroupsMasterGroupIdMastersMasterIdLessonsGetRequest = (
  masterGroupId: number,
  masterId: number,
  params?: ManagerMasterGroupsMasterGroupIdMastersMasterIdLessonsGetParams,
  options?: any,
) =>
  request<MasterLessonShortResource[]>(
    `/manager/master-groups/${masterGroupId}/masters/${masterId}/lessons`,
    { method: 'GET', params, ...options },
  )

// Просмотр урока мастера в мастер-группе
export const ManagerMasterGroupsMasterGroupIdMastersMasterIdLessonsLessonIdGetRequest = (
  masterGroupId: number,
  masterId: number,
  lessonId: number,
  options?: any,
) =>
  request<MasterLessonLargeResource>(
    `/manager/master-groups/${masterGroupId}/masters/${masterId}/lessons/${lessonId}`,
    { method: 'GET', ...options },
  )

// Перемещение мастера из одной МГ в другую МГ
export const ManagerMasterGroupsMasterGroupIdMastersMasterIdTransferNewMasterGroupIdPostRequest = (
  masterGroupId: number,
  masterId: number,
  newMasterGroupId: number,
  body: MasterMoveReasonRequest,
  options?: any,
) =>
  request<any>(
    `/manager/master-groups/${masterGroupId}/masters/${masterId}/transfer/${newMasterGroupId}`,
    { body, method: 'POST', ...options },
  )

// Просмотр мастера в мастер-группе
export const ManagerMasterGroupsMasterGroupIdMastersMasterUuidGetRequest = (
  masterGroupId: number,
  masterUuid: string | number,
  params?: ManagerMasterGroupsMasterGroupIdMastersMasterUuidGetParams,
  options?: any,
) =>
  request<MasterLargeResource>(
    `/manager/master-groups/${masterGroupId}/masters/${masterUuid}`,
    { method: 'GET', params, ...options },
  )

// Получение списка месяцев мастер-группы
export const ManagerMasterGroupsMasterGroupIdMonthsSelectGetRequest = (
  masterGroupId: number,
  options?: any,
) =>
  request<NameValueResource[]>(
    `/manager/master-groups/${masterGroupId}/months/select`,
    { method: 'GET', ...options },
  )

// Получение списка мастеров в таске ДЗ
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersGetRequest = (
  masterGroupId: number,
  taskUuid: string,
  options?: any,
) =>
  request<ExerciseMasterResource[]>(
    `/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters`,
    { method: 'GET', ...options },
  )

// Получение сокращенного списка мастеров в таске ДЗ
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersShortGetRequest = (
  masterGroupId: number,
  taskUuid: string,
  options?: any,
) =>
  request<MasterWhoCompleteTaskResource[]>(
    `/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/short`,
    { method: 'GET', ...options },
  )

// Получение списка мастеров в таске ДЗ
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdGetRequest = (
  masterGroupId: number,
  taskUuid: string,
  masterId: number,
  params?: ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdGetParams,
  options?: any,
) =>
  request<EducationLargeTaskResource>(
    `/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/${masterId}`,
    { method: 'GET', params, ...options },
  )

// Запуск кода (ответа мастера на задание по информатике)
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdCodingExecutePostRequest = (masterGroupId: number, taskUuid: string, masterId: number, body: ManagerEducationCodeExecuteData, options?: any) => request<CodeExecutionResult>(`/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/${masterId}/coding/execute`, { body, method: 'POST', ...options })

// Согласие о выполнении домашнего задания
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdCompletePostRequest = (
  masterGroupId: number,
  taskUuid: string,
  masterId: number,
  options?: any,
) =>
  request<EducationLargeTaskResource>(
    `/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/${masterId}/complete`,
    { method: 'POST', ...options },
  )

// Сохранение черновика проверки домашнего задания
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdContentPutRequest = (masterGroupId: number, taskUuid: string, masterId: number, body: MentorContentData, options?: any) => request<EducationLargeTaskResource>(`/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/${masterId}/content`, { body, method: 'PUT', ...options })

// Получения списка сообщений
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesGetRequest = (
  masterGroupId: number,
  taskUuid: string,
  masterId: number,
  options?: any,
) =>
  request<TaskMessageResource[]>(
    `/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/${masterId}/messages`,
    { method: 'GET', ...options },
  )

// Создание нового сообщения
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesPostRequest = (
  masterGroupId: number,
  taskUuid: string,
  masterId: number,
  body: MessageStore,
  options?: any,
) =>
  request<TaskMessageResource>(
    `/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/${masterId}/messages`,
    { body, method: 'POST', ...options },
  )

// Отметить все сообщения в ДЗ как прочитанные
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesPutRequest = (masterGroupId: number, taskUuid: string, masterId: number, options?: any) => request<any>(`/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/${masterId}/messages`, { method: 'PUT', ...options })

// Отредактировать сообщение
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesMessageIdPutRequest = (masterGroupId: number, taskUuid: string, masterId: number, messageId: number, body: MessageStore, options?: any) => request<TaskMessageResource>(`/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/${masterId}/messages/${messageId}`, { body, method: 'PUT', ...options })

// Отправка реакции на сообщение
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesMessageIdReactionPostRequest = (masterGroupId: number, taskUuid: string, masterId: number, messageId: number, body: MessageReactionStore, options?: any) => request<any>(`/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/${masterId}/messages/${messageId}/reaction`, { body, method: 'POST', ...options })

// Получение информации о РНО мастера
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMistakesGetRequest = (
  masterGroupId: number,
  taskUuid: string,
  masterId: number,
  options?: any,
) =>
  request<MistakeShortResource[]>(
    `/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/${masterId}/mistakes`,
    { method: 'GET', ...options },
  )

// Оценка домашнего задания
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdRatePostRequest = (
  masterGroupId: number,
  taskUuid: string,
  masterId: number,
  body: EducationRateMasterData,
  options?: any,
) =>
  request<EducationLargeTaskResource>(
    `/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/${masterId}/rate`,
    { body, method: 'POST', ...options },
  )

// Отправка ключей мастеру
export const ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdSendKeysPostRequest = (
  masterGroupId: number,
  taskUuid: string,
  masterId: number,
  options?: any,
) =>
  request<TaskMessageResource>(
    `/manager/master-groups/${masterGroupId}/tasks/${taskUuid}/masters/${masterId}/send-keys`,
    { method: 'POST', ...options },
  )

// Селект запрос на получение пользователей
export const ManagerMastersSelectGetRequest = (
  params?: ManagerMastersSelectGetParams,
  options?: any,
) =>
  request<UserShortResource[]>('/manager/masters/select', {
    method: 'GET',
    params,
    ...options,
  })

// Просмотр мастер-групп мастера
export const ManagerMastersMasterIdCoursesGetRequest = (
  masterId: number,
  options?: any,
) =>
  request<UserBoughtGroupResource[]>(`/manager/masters/${masterId}/courses`, {
    method: 'GET',
    ...options,
  })

// Мастер-группы мастера
export const ManagerMastersMasterIdMasterGroupsSelectGetRequest = (
  masterId: number,
  options?: any,
) =>
  request<EducationMasterGroupResource[]>(
    `/manager/masters/${masterId}/master-groups/select`,
    { method: 'GET', ...options },
  )

// Просмотр списка наставников
export const ManagerMentorsGetRequest = (
  params?: ManagerMentorsGetParams,
  options?: any,
) =>
  request<MentorShortResourcePaginator>('/manager/mentors', {
    method: 'GET',
    params,
    ...options,
  })

// Селект менторов
export const ManagerMentorsSelectGetRequest = (
  params?: ManagerMentorsSelectGetParams,
  options?: any,
) =>
  request<UserShortResource[]>('/manager/mentors/select', {
    method: 'GET',
    params,
    ...options,
  })

// Просмотр списка старших наставников
export const ManagerMentorsSeniorsGetRequest = (options?: any) =>
  request<SeniorShortResource[]>('/manager/mentors/seniors', {
    method: 'GET',
    ...options,
  })

// Изменение младших наставников у старших
export const ManagerMentorsSeniorsPostRequest = (
  body: SeniorStoreRequest,
  options?: any,
) =>
  request<SeniorShortResource[]>('/manager/mentors/seniors', {
    body,
    method: 'POST',
    ...options,
  })

// Просмотр информации о наставнике
export const ManagerMentorsMentorIdGetRequest = (
  mentorId: number,
  options?: any,
) =>
  request<MentorLargeResource>(`/manager/mentors/${mentorId}`, {
    method: 'GET',
    ...options,
  })

// Фильтр новостей
export const ManagerNewsGetRequest = (
  params: ManagerNewsGetParams,
  options?: any,
) =>
  request<NewsListResourcePaginator>('/manager/news', {
    method: 'GET',
    params,
    ...options,
  })

// Создание новости
export const ManagerNewsPostRequest = (body: NewsStoreData, options?: any) =>
  request<NewsLargeResource>('/manager/news', {
    body,
    method: 'POST',
    ...options,
  })

// Обновление новости
export const ManagerNewsIdPostRequest = (
  id: number,
  body: NewsStoreData,
  options?: any,
) =>
  request<NewsLargeResource>(`/manager/news/${id}`, {
    body,
    method: 'POST',
    ...options,
  })

// Получение новости
export const ManagerNewsIdGetRequest = (id: number, options?: any) =>
  request<NewsLargeResource>(`/manager/news/${id}`, {
    method: 'GET',
    ...options,
  })

// Удаление новости
export const ManagerNewsIdDeleteRequest = (id: number, options?: any) =>
  request<any>(`/manager/news/${id}`, { method: 'DELETE', ...options })

// Список заказов
export const ManagerOrdersGetRequest = (
  params?: ManagerOrdersGetParams,
  options?: any,
) =>
  request<ManagerOrderShortResourcePaginator>('/manager/orders', {
    method: 'GET',
    params,
    ...options,
  })

// Оформление заказа
export const ManagerOrdersPostRequest = (
  body: ManagerOrderStoreRequest,
  options?: any,
) =>
  request<ManagerOrderLargeResource>('/manager/orders', {
    body,
    method: 'POST',
    ...options,
  })

// Значения для селекта цен
export const ManagerOrdersPricesGetRequest = (
  params?: ManagerOrdersPricesGetParams,
  options?: any,
) =>
  request<NameValueResource[]>('/manager/orders/prices', {
    method: 'GET',
    params,
    ...options,
  })

// Список заказов
export const ManagerOrdersOrderIdGetRequest = (
  orderId: number,
  options?: any,
) =>
  request<ManagerOrderLargeResource>(`/manager/orders/${orderId}`, {
    method: 'GET',
    ...options,
  })

// Обновление заказа (только при статусе в ожидании)
export const ManagerOrdersOrderIdPatchRequest = (
  orderId: number,
  body: ManagerOrderStoreRequest,
  options?: any,
) =>
  request<ManagerOrderLargeResource>(`/manager/orders/${orderId}`, {
    body,
    method: 'PATCH',
    ...options,
  })

// Удаление заказа (только при статусе в ожидании)
export const ManagerOrdersOrderIdDeleteRequest = (
  orderId: number,
  options?: any,
) =>
  request<any>(`/manager/orders/${orderId}`, { method: 'DELETE', ...options })

// Обновление статуса заказа
export const ManagerOrdersOrderIdStatusStatusPatchRequest = (
  orderId: number,
  status: OrderStatus,
  options?: any,
) =>
  request<ManagerOrderLargeResource>(
    `/manager/orders/${orderId}/status/${status}`,
    { method: 'PATCH', ...options },
  )

// Просмотр списка промо-акций
export const ManagerPromotionsGetRequest = (
  params?: ManagerPromotionsGetParams,
  options?: any,
) =>
  request<PromotionShortResourcePaginator>('/manager/promotions', {
    method: 'GET',
    params,
    ...options,
  })

// Создание промо-акции
export const ManagerPromotionsPostRequest = (
  body: PromotionData,
  options?: any,
) =>
  request<PromotionLargeResource>('/manager/promotions', {
    body,
    method: 'POST',
    ...options,
  })

// Просмотр промо-акции
export const ManagerPromotionsPromotionIdGetRequest = (
  promotionId: number,
  options?: any,
) =>
  request<PromotionLargeResource>(`/manager/promotions/${promotionId}`, {
    method: 'GET',
    ...options,
  })

// Изменение промо-акции
export const ManagerPromotionsPromotionIdPatchRequest = (
  promotionId: number,
  body: PromotionData,
  options?: any,
) =>
  request<PromotionLargeResource>(`/manager/promotions/${promotionId}`, {
    body,
    method: 'PATCH',
    ...options,
  })

// Удаление промо-акции
export const ManagerPromotionsPromotionIdDeleteRequest = (
  promotionId: number,
  options?: any,
) =>
  request<any>(`/manager/promotions/${promotionId}`, {
    method: 'DELETE',
    ...options,
  })

// Создание отзыва
export const ManagerReviewPostRequest = (body: CreateReviewRequest, options?: any) => request<ReviewResource>('/manager/review', { body, method: 'POST', ...options })

// Редактирование отзывов
export const ManagerReviewPatchRequest = (body: UpdateReviewRequest, options?: any) => request<ReviewResource>('/manager/review', { body, method: 'PATCH', ...options })

// Получение отзыва
export const ManagerReviewReviewIdGetRequest = (reviewId: number, options?: any) => request<ReviewResource>(`/manager/review/${reviewId}`, { method: 'GET', ...options })

// Удаление отзыва
export const ManagerReviewReviewIdDeleteRequest = (reviewId: number, options?: any) => request<any>(`/manager/review/${reviewId}`, { method: 'DELETE', ...options })

// Пагинатор отзывов
export const ManagerReviewsGetRequest = (params?: ManagerReviewsGetParams, options?: any) => request<ReviewPaginator>('/manager/reviews', { method: 'GET', params, ...options })

// Получение списка ролей
export const ManagerRolesGetRequest = (options?: any) =>
  request<RoleShortResource[]>('/manager/roles', { method: 'GET', ...options })

// Создание роли
export const ManagerRolesPostRequest = (body: RoleStore, options?: any) =>
  request<RoleResource>('/manager/roles', { body, method: 'POST', ...options })

// Получение списка ролей (NameValue)
export const ManagerRolesDictionaryGetRequest = (options?: any) =>
  request<NameValueResource[]>('/manager/roles/dictionary', {
    method: 'GET',
    ...options,
  })

// Получение дерева прав
export const ManagerRolesPermissionsGetRequest = (options?: any) =>
  request<RoleGroupResource[]>('/manager/roles/permissions', {
    method: 'GET',
    ...options,
  })

// Просмотр роли
export const ManagerRolesUuidGetRequest = (uuid: string, options?: any) =>
  request<RoleResource>(`/manager/roles/${uuid}`, {
    method: 'GET',
    ...options,
  })

// Изменение роли
export const ManagerRolesUuidPatchRequest = (
  uuid: string,
  body: RoleStore,
  options?: any,
) =>
  request<RoleResource>(`/manager/roles/${uuid}`, {
    body,
    method: 'PATCH',
    ...options,
  })

// Удаление
export const ManagerRolesUuidDeleteRequest = (uuid: string, options?: any) =>
  request<any>(`/manager/roles/${uuid}`, { method: 'DELETE', ...options })

// Просмотр списка пользователей роли
export const ManagerRolesUuidUsersGetRequest = (uuid: string, options?: any) =>
  request<UserShortResource[]>(`/manager/roles/${uuid}/users`, {
    method: 'GET',
    ...options,
  })

// Изменение\удаление списка пользователей в роли
export const ManagerRolesUuidUsersPostRequest = (
  uuid: string,
  body: RoleUsersStore,
  options?: any,
) =>
  request<UserShortResource[]>(`/manager/roles/${uuid}/users`, {
    body,
    method: 'POST',
    ...options,
  })

// Список скидок
export const ManagerSalesGetRequest = (
  params?: ManagerSalesGetParams,
  options?: any,
) =>
  request<SaleResourcePaginator>('/manager/sales', {
    method: 'GET',
    params,
    ...options,
  })

// Создание скидки
export const ManagerSalesPostRequest = (body: SaleStoreData, options?: any) =>
  request<SaleResource>('/manager/sales', { body, method: 'POST', ...options })

// Получение скидок в окне создания заказа менеджером
export const ManagerSalesCustomGetRequest = (params: ManagerSalesCustomGetParams, options?: any) => request<ManualSaleResource>('/manager/sales/custom', { method: 'GET', params, ...options })

// Селект скидок
export const ManagerSalesSelectGetRequest = (options?: any) =>
  request<NameValueResource[]>('/manager/sales/select', {
    method: 'GET',
    ...options,
  })

// Запрос статистики по скидкам
export const ManagerSalesStatisticGetRequest = (params?: ManagerSalesStatisticGetParams, options?: any) => request<SaleStatShortResourcePaginator>('/manager/sales/statistic', { method: 'GET', params, ...options })

// Объекты скидок
export const ManagerSalesUnitsPostRequest = (body: UnitsRequest, options?: any) => request<SaleUnitsListResource>('/manager/sales/units', { body, method: 'POST', ...options })

// Список скидок
export const ManagerSalesSaleIdGetRequest = (saleId: number, options?: any) =>
  request<SaleResource>(`/manager/sales/${saleId}`, {
    method: 'GET',
    ...options,
  })

// Изменение скидки
export const ManagerSalesSaleIdPatchRequest = (
  saleId: number,
  body: SaleStoreData,
  options?: any,
) =>
  request<SaleResource>(`/manager/sales/${saleId}`, {
    body,
    method: 'PATCH',
    ...options,
  })

// Удаление скидки
export const ManagerSalesSaleIdDeleteRequest = (
  saleId: number,
  options?: any,
) => request<any>(`/manager/sales/${saleId}`, { method: 'DELETE', ...options })

// Активация скидки
export const ManagerSalesSaleIdActivatePatchRequest = (saleId: number, options?: any) => request<SaleResource>(`/manager/sales/${saleId}/activate`, { method: 'PATCH', ...options })

// Добавление дз в контейнер
export const ManagerTasksPostRequest = (body: TaskStoreRequest, options?: any) => request<TaskCreativeResource|TaskPracticeResource|TaskTestResource|TaskSpeakingResource|TaskCodingResource>('/manager/tasks', { body, method: 'POST', ...options })

// Клонирование таски в контейнер
export const ManagerTasksClonePostRequest = (body: TaskCloneRequest, options?: any) => request<TaskCreativeResource|TaskPracticeResource|TaskTestResource|TaskSpeakingResource|TaskCodingResource>('/manager/tasks/clone', { body, method: 'POST', ...options })

// Удаление дз в контейнере
export const ManagerTasksTaskUuidDeleteRequest = (
  taskUuid: string,
  options?: any,
) =>
  request<any>(`/manager/tasks/${taskUuid}`, { method: 'DELETE', ...options })

// Создание учителя
export const ManagerTeacherPostRequest = (body: CreateTeacherRequest, options?: any) => request<TeacherLargeResource>('/manager/teacher', { body, method: 'POST', ...options })

// Обновление информации об учителе
export const ManagerTeacherPatchRequest = (body: UpdateTeacherRequest, options?: any) => request<TeacherLargeResource>('/manager/teacher', { body, method: 'PATCH', ...options })

// Получение учителя по ID
export const ManagerTeacherTeacherIdGetRequest = (teacherId: number, options?: any) => request<TeacherLargeResource>(`/manager/teacher/${teacherId}`, { method: 'GET', ...options })

// Удаление учителя по ID
export const ManagerTeacherTeacherIdDeleteRequest = (teacherId: number, options?: any) => request<any>(`/manager/teacher/${teacherId}`, { method: 'DELETE', ...options })

// Получение пагинатора с учителями
export const ManagerTeachersGetRequest = (params?: ManagerTeachersGetParams, options?: any) => request<TeacherLargeResourcePaginator>('/manager/teachers', { method: 'GET', params, ...options })

// Получение сокращенной информации по учителям
export const ManagerTeachersShortGetRequest = (options?: any) => request<TeacherShortResource[]>('/manager/teachers/short', { method: 'GET', ...options })

// Список домашек
export const ManagerTemplatesExercisesGetRequest = (
  params?: ManagerTemplatesExercisesGetParams,
  options?: any,
) =>
  request<ExerciseShortResourcePaginator>('/manager/templates/exercises', {
    method: 'GET',
    params,
    ...options,
  })

// Добавление ДЗ-контейнера
export const ManagerTemplatesExercisesPostRequest = (
  body: ExerciseStore,
  options?: any,
) =>
  request<ExerciseLargeResource>('/manager/templates/exercises', {
    body,
    method: 'POST',
    ...options,
  })

// Просмотр дз
export const ManagerTemplatesExercisesExerciseUuidGetRequest = (
  exerciseUuid: string,
  options?: any,
) =>
  request<ExerciseLargeResource>(
    `/manager/templates/exercises/${exerciseUuid}`,
    { method: 'GET', ...options },
  )

// Обновление ДЗ-контейнера
export const ManagerTemplatesExercisesExerciseUuidPostRequest = (
  exerciseUuid: string,
  body: ExerciseStore,
  options?: any,
) =>
  request<ExerciseLargeResource>(
    `/manager/templates/exercises/${exerciseUuid}`,
    { body, method: 'POST', ...options },
  )

// Удаление ДЗ-контейнера
export const ManagerTemplatesExercisesExerciseUuidDeleteRequest = (
  exerciseUuid: string,
  options?: any,
) =>
  request<any>(`/manager/templates/exercises/${exerciseUuid}`, {
    method: 'DELETE',
    ...options,
  })

// Выдача из Банка ДЗ
export const ManagerTemplatesExercisesExerciseUuidClonePostRequest = (exerciseUuid: string, body: ExerciseCloneToManyData, options?: any) => request<any>(`/manager/templates/exercises/${exerciseUuid}/clone`, { body, method: 'POST', ...options })

// Клонирование ДЗ в банк
export const ManagerTemplatesExercisesExerciseUuidCloneBankPostRequest = (exerciseUuid: string, options?: any) => request<ExerciseLargeResource>(`/manager/templates/exercises/${exerciseUuid}/clone/bank`, { method: 'POST', ...options })

// Список копий из Банка ДЗ
export const ManagerTemplatesExercisesExerciseUuidListClonesGetRequest = (exerciseUuid: string, options?: any) => request<ExerciseCloneListResource[]>(`/manager/templates/exercises/${exerciseUuid}/list-clones`, { method: 'GET', ...options })

// Получить список материалов по ID курса
export const ManagerUsefulCourseCourseIdGetRequest = (
  courseId: number,
  params?: ManagerUsefulCourseCourseIdGetParams,
  options?: any,
) =>
  request<UsefulMaterialPaginator>(`/manager/useful/course/${courseId}`, {
    method: 'GET',
    params,
    ...options,
  })

// Получения списка тайтлов полезных материалов для поиска
export const ManagerUsefulCourseCourseIdSelectGetRequest = (
  courseId: number,
  params?: ManagerUsefulCourseCourseIdSelectGetParams,
  options?: any,
) =>
  request<NameValueResource[]>(`/manager/useful/course/${courseId}/select`, {
    method: 'GET',
    params,
    ...options,
  })

// Получение карточки курса для магазина
export const MasterCoursesCourseIdGetRequest = (courseId: number, options?: any) => request<CourseShopCardResource>(`/master/courses/${courseId}`, { method: 'GET', ...options })

// Получение курсов рекомендованных к покупке
export const MasterCoursesCourseIdRecommendationsGetRequest = (courseId: number, options?: any) => request<MasterRecommendationResource[]>(`/master/courses/${courseId}/recommendations`, { method: 'GET', ...options })

// Удалить материал по его ID
export const ManagerUsefulMaterialMaterialIdCourseIdDeleteRequest = (
  materialId: number,
  courseId: number,
  options?: any,
) =>
  request<any>(`/manager/useful/material/${materialId}/${courseId}`, {
    method: 'DELETE',
    ...options,
  })

// Добваить новый полезный материал.
export const ManagerUsefulCourseIdPostRequest = (
  courseId: number,
  body: UsefulMaterialPostBody,
  options?: any,
) =>
  request<FullUsefulMaterial>(`/manager/useful/${courseId}`, {
    body,
    method: 'POST',
    ...options,
  })

// получить полезный материал по его ИД
export const ManagerUsefulMaterialIdGetRequest = (
  materialId: number,
  options?: any,
) =>
  request<FullUsefulMaterial>(`/manager/useful/${materialId}`, {
    method: 'GET',
    ...options,
  })

// Апдэйт полезного материала
export const ManagerUsefulMaterialIdPatchRequest = (
  materialId: number,
  body: UsefulMaterialPatchBody,
  options?: any,
) =>
  request<FullUsefulMaterial>(`/manager/useful/${materialId}`, {
    body,
    method: 'PATCH',
    ...options,
  })

// Получение списка пользователей
export const ManagerUsersGetRequest = (
  params?: ManagerUsersGetParams,
  options?: any,
) =>
  request<UserManagerResourcePaginator>('/manager/users', {
    method: 'GET',
    params,
    ...options,
  })

// Поиск по пользователям
export const ManagerUsersSelectGetRequest = (
  params?: ManagerUsersSelectGetParams,
  options?: any,
) =>
  request<UserShortResource[]>('/manager/users/select', {
    method: 'GET',
    params,
    ...options,
  })

// Просмотр пользователя
export const ManagerUsersUuidGetRequest = (uuid: string, options?: any) =>
  request<UserManagerResource>(`/manager/users/${uuid}`, {
    method: 'GET',
    ...options,
  })

// Изменение пользователя
export const ManagerUsersUuidPatchRequest = (
  uuid: string,
  body: UserManagerProfile,
  options?: any,
) =>
  request<UserManagerResource>(`/manager/users/${uuid}`, {
    body,
    method: 'PATCH',
    ...options,
  })

// Удаление пользователя
export const ManagerUsersUuidDeleteRequest = (uuid: string, options?: any) =>
  request<any>(`/manager/users/${uuid}`, { method: 'DELETE', ...options })

// Активные скидки пользователя
export const MasterBannerActiveSalesGetRequest = (options?: any) => request<SaleBannerResource[]>('/master/banner/active-sales', { method: 'GET', ...options })

// Закрыть и не показывать баннер со скидками
export const MasterBannerCloseGetRequest = (options?: any) => request<any>('/master/banner/close', { method: 'GET', ...options })

// Список курсов
export const MasterCoursesGetRequest = (
  params?: MasterCoursesGetParams,
  options?: any,
) =>
  request<MasterCourseShortResource[]>('/master/courses', {
    method: 'GET',
    params,
    ...options,
  })

// Просмотр базы знаний по курсу. Будут отображаться только доступные месяца в базе знаний
export const MasterCoursesKnowledgeCourseIdGetRequest = (
  courseId: number,
  options?: any,
) =>
  request<KnowledgeResource>(`/master/courses/knowledge/${courseId}`, {
    method: 'GET',
    ...options,
  })

// Метод для выдачи мастеру достижения
export const MasterCoursesAmbulanceGetRequest = (options?: any) => request<string>('/master/courses/ambulance', { method: 'GET', ...options })

// Получение доступных пакетов по купленному курсу для продления
export const MasterCoursesPurchaseCourseIdContinueGetRequest = (
  courseId: number,
  options?: any,
) =>
  request<PurchaseLargeResource>(
    `/master/courses/purchase/${courseId}/continue`,
    { method: 'GET', ...options },
  )

// Получение доступных пакетов по не купленным курсам
export const MasterCoursesPurchaseCourseIdInfoGetRequest = (
  courseId: number,
  options?: any,
) =>
  request<PurchaseLargeResource>(`/master/courses/purchase/${courseId}/info`, {
    method: 'GET',
    ...options,
  })

// Список курсов мастера
export const MasterCoursesSelfGetRequest = (options?: any) =>
  request<EducationCourseShortResource[]>('/master/courses/self', {
    method: 'GET',
    ...options,
  })

// Список курсов мастера
export const MasterCoursesSelfSelectGetRequest = (options?: any) =>
  request<NameValueResource[]>('/master/courses/self/select', {
    method: 'GET',
    ...options,
  })

// Список курсов для страницы магазина
export const MasterCoursesShopGetRequest = (options?: any) => request<MasterCourseShortResource[]>('/master/courses/shop', { method: 'GET', ...options })

// Список предметов
export const MasterCoursesSubjectsGetRequest = (options?: any) =>
  request<CourseSubjectLargeResource[]>('/master/courses/subjects', {
    method: 'GET',
    ...options,
  })

// Получить пагинатор полезных материалов для мастера
export const MasterCoursesUsefulCourseIdGetRequest = (
  courseId: number,
  params?: MasterCoursesUsefulCourseIdGetParams,
  options?: any,
) =>
  request<UsefulMaterialMasterPaginator>(`/master/courses/useful/${courseId}`, {
    method: 'GET',
    params,
    ...options,
  })

// Получения списка тайтлов полезных материалов для поиска
export const MasterCoursesUsefulCourseIdSelectGetRequest = (
  courseId: number,
  params?: MasterCoursesUsefulCourseIdSelectGetParams,
  options?: any,
) =>
  request<string[]>(`/master/courses/useful/${courseId}/select`, {
    method: 'GET',
    params,
    ...options,
  })

// Пометить полезный материал как просмотренный
export const MasterCoursesUsefulCourseIdViewMaterialIdPatchRequest = (courseId: number, materialId: number, options?: any) => request<any>(`/master/courses/useful/${courseId}/view/${materialId}`, { method: 'PATCH', ...options })


// Дашборд мастера
export const MasterDashboardCourseIdGetRequest = (
  courseId: number,
  options?: any,
) =>
  request<MasterDashboardResource>(`/master/dashboard/${courseId}`, {
    method: 'GET',
    ...options,
  })

// Получить события календаря по фильтру
export const MasterEducationCalendarGetRequest = (params: MasterEducationCalendarGetParams, options?: any) => request<CalendarPageResource>('/master/education/calendar', { method: 'GET', params, ...options })

// Получить сохраненные фильтры календаря
export const MasterEducationCalendarFiltersGetRequest = (options?: any) => request<CalendarFiltersData>('/master/education/calendar/filters', { method: 'GET', ...options })

// Сохранить фильтры календаря
export const MasterEducationCalendarFiltersPostRequest = (body: CalendarFiltersData, options?: any) => request<any>('/master/education/calendar/filters', { body, method: 'POST', ...options })

// Список мастер-групп пользователя
export const MasterEducationMasterGroupsGetRequest = (
  params?: MasterEducationMasterGroupsGetParams,
  options?: any,
) =>
  request<EducationMasterGroupResource[]>('/master/education/master-groups', {
    method: 'GET',
    params,
    ...options,
  })

// Обновить список мастер-групп пользователя
export const MasterEducationMasterGroupsPutRequest = (
  body: VisibleCourseRequest,
  options?: any,
) =>
  request<EducationMasterGroupResource[]>('/master/education/master-groups', {
    body,
    method: 'PUT',
    ...options,
  })

// Получение МГ по курсу
export const MasterEducationMasterGroupsCourseCourseIdGetRequest = (
  courseId: number,
  options?: any,
) =>
  request<EducationMasterGroupResource>(
    `/master/education/master-groups/course/${courseId}`,
    { method: 'GET', ...options },
  )

// Календарь уроков и ДЗ
export const MasterEducationMasterGroupIdCalendarGetRequest = (
  masterGroupId: number,
  params: MasterEducationMasterGroupIdCalendarGetParams,
  options?: any,
) =>
  request<CalendarLargeResource>(
    `/master/education/${masterGroupId}/calendar`,
    { method: 'GET', params, ...options },
  )

// Список домашек
export const MasterEducationMasterGroupIdExercisesGetRequest = (
  masterGroupId: number,
  params?: MasterEducationMasterGroupIdExercisesGetParams,
  options?: any,
) =>
  request<ExerciseMasterCardResourceContainer[]>(
    `/master/education/${masterGroupId}/exercises`,
    { method: 'GET', params, ...options },
  )

// Список домашек
export const MasterEducationMasterGroupIdExercisesDrawerGetRequest = (
  masterGroupId: number,
  params?: MasterEducationMasterGroupIdExercisesDrawerGetParams,
  options?: any,
) =>
  request<EducationDrawerExerciseResource[]>(
    `/master/education/${masterGroupId}/exercises/drawer`,
    { method: 'GET', params, ...options },
  )

// Журнал ДЗ
export const MasterEducationMasterGroupIdExercisesJournalGetRequest = (
  masterGroupId: number,
  params?: MasterEducationMasterGroupIdExercisesJournalGetParams,
  options?: any,
) =>
  request<JournalLargeResource>(
    `/master/education/${masterGroupId}/exercises/journal`,
    { method: 'GET', params, ...options },
  )

// Просмотр домашнего задания
export const MasterEducationMasterGroupIdExercisesExerciseUuidGetRequest = (
  masterGroupId: number,
  exerciseUuid: string,
  options?: any,
) =>
  request<EducationLargeExerciseResource>(
    `/master/education/${masterGroupId}/exercises/${exerciseUuid}`,
    { method: 'GET', ...options },
  )

// Начать выполнение домашнего задания
export const MasterEducationMasterGroupIdExercisesExerciseUuidStartWorkingGetRequest = (masterGroupId: number, exerciseUuid: string, options?: any) => request<StartWorkingOnExerciseResource>(`/master/education/${masterGroupId}/exercises/${exerciseUuid}/start-working`, { method: 'POST', ...options })

// Просмотр домашнего задания
export const MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidGetRequest = (
  masterGroupId: number,
  exerciseUuid: string,
  taskUuid: string,
  params?: MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidGetParams,
  options?: any,
) =>
  request<EducationLargeTaskResource>(
    `/master/education/${masterGroupId}/exercises/${exerciseUuid}/task/${taskUuid}`,
    { method: 'GET', params, ...options },
  )

// Запуск кода (ответа мастера на задание по информатике)
export const MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidCodingExecutePostRequest = (masterGroupId: number, exerciseUuid: string, taskUuid: string, body: MasterEducationCodeExecuteData, options?: any) => request<CodeExecutionResult>(`/master/education/${masterGroupId}/exercises/${exerciseUuid}/task/${taskUuid}/coding/execute`, { body, method: 'POST', ...options })

// Выполнение практического и тестового задания
export const MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidDecidePostRequest = (
  masterGroupId: number,
  exerciseUuid: string,
  taskUuid: string,
  body: EducationLargeTaskDecideRequest,
  options?: any,
) =>
  request<EducationLargeTaskResource>(
    `/master/education/${masterGroupId}/exercises/${exerciseUuid}/task/${taskUuid}/decide`,
    { body, method: 'POST', ...options },
  )

// Получения списка сообщений
export const MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidMessagesGetRequest = (
  masterGroupId: number,
  exerciseUuid: string,
  taskUuid: string,
  options?: any,
) =>
  request<TaskMessageResource[]>(
    `/master/education/${masterGroupId}/exercises/${exerciseUuid}/task/${taskUuid}/messages`,
    { method: 'GET', ...options },
  )

// Создание нового сообщения
export const MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidMessagesPostRequest = (
  masterGroupId: number,
  exerciseUuid: string,
  taskUuid: string,
  body: MessageStore,
  options?: any,
) =>
  request<TaskMessageResource>(
    `/master/education/${masterGroupId}/exercises/${exerciseUuid}/task/${taskUuid}/messages`,
    { body, method: 'POST', ...options },
  )

// Отметить все сообщения в ДЗ как прочитанные
export const MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidMessagesPutRequest = (masterGroupId: number, exerciseUuid: string, taskUuid: string, options?: any) => request<any>(`/master/education/${masterGroupId}/exercises/${exerciseUuid}/task/${taskUuid}/messages`, { method: 'PUT', ...options })

// Отправка реакции на сообщение
export const MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidMessagesMessageIdReactionPostRequest = (masterGroupId: number, exerciseUuid: string, taskUuid: string, messageId: number, body: MessageReactionStore, options?: any) => request<any>(`/master/education/${masterGroupId}/exercises/${exerciseUuid}/task/${taskUuid}/messages/${messageId}/reaction`, { body, method: 'POST', ...options })

// Выполнение творческого домашнего задания
export const MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidResultPostRequest = (
  masterGroupId: number,
  exerciseUuid: string,
  taskUuid: string,
  body: EducationLargeTaskResultRequest,
  options?: any,
) =>
  request<EducationLargeTaskResource>(
    `/master/education/${masterGroupId}/exercises/${exerciseUuid}/task/${taskUuid}/result`,
    { body, method: 'POST', ...options },
  )

// Список доступных мастеру уроков
export const MasterEducationMasterGroupIdLessonsGetRequest = (
  masterGroupId: number,
  params?: MasterEducationMasterGroupIdLessonsGetParams,
  options?: any,
) =>
  request<MasterLessonShortResource[]>(
    `/master/education/${masterGroupId}/lessons`,
    { method: 'GET', params, ...options },
  )

// Список доступных мастеру уроков (программа)
export const MasterEducationMasterGroupIdLessonsDrawerGetRequest = (
  masterGroupId: number,
  params?: MasterEducationMasterGroupIdLessonsDrawerGetParams,
  options?: any,
) =>
  request<MasterLessonDrawerResource[]>(
    `/master/education/${masterGroupId}/lessons/drawer`,
    { method: 'GET', params, ...options },
  )

// Просмотр доступного мастеру урока
export const MasterEducationMasterGroupIdLessonsLessonIdGetRequest = (
  masterGroupId: number,
  lessonId: number,
  params?: MasterEducationMasterGroupIdLessonsLessonIdGetParams,
  options?: any,
) =>
  request<MasterLessonLargeResource>(
    `/master/education/${masterGroupId}/lessons/${lessonId}`,
    { method: 'GET', params, ...options },
  )

// Просмотр доступного мастеру урока
export const MasterEducationMasterGroupIdLessonsLessonIdCheckPostRequest = (
  masterGroupId: number,
  lessonId: number,
  options?: any,
) =>
  request<MasterLessonLargeResource>(
    `/master/education/${masterGroupId}/lessons/${lessonId}/check`,
    { method: 'POST', ...options },
  )

// Отправка кол-ва секунд просмотренного урока.
export const MasterEducationMasterGroupIdLessonsLessonIdMarkPostRequest = (masterGroupId: number, lessonId: number, body: StorePlaybackMarkerRequest, options?: any) => request<MasterLessonLargeResource>(`/master/education/${masterGroupId}/lessons/${lessonId}/mark`, { body, method: 'POST', ...options })

// Просмотр доступного мастеру урока
export const MasterEducationMasterGroupIdLessonsLessonIdRatePostRequest = (
  masterGroupId: number,
  lessonId: number,
  body: MasterLessonRateRequest,
  options?: any,
) =>
  request<MasterLessonLargeResource>(
    `/master/education/${masterGroupId}/lessons/${lessonId}/rate`,
    { body, method: 'POST', ...options },
  )

// Получить РНО пользователя для всего теста
export const MasterEducationMasterGroupIdMistakesFormIdGetRequest = (
  masterGroupId: number,
  formId: number,
  options?: any,
) =>
  request<MistakeShortResource[]>(
    `/master/education/${masterGroupId}/mistakes/${formId}`,
    { method: 'GET', ...options },
  )

// Получить РНО пользователя для определённого задания в тесте
export const MasterEducationMasterGroupIdMistakesFormIdQuestionIdGetRequest = (
  masterGroupId: number,
  formId: number,
  questionId: number,
  options?: any,
) =>
  request<MistakeShortResource>(
    `/master/education/${masterGroupId}/mistakes/${formId}/${questionId}`,
    { method: 'GET', ...options },
  )

// Сохранить РНО пользователя для определённого задания в тесте
export const MasterEducationMasterGroupIdMistakesFormIdQuestionIdPostRequest = (
  masterGroupId: number,
  formId: number,
  questionId: number,
  body: MistakeStoreData,
  options?: any,
) =>
  request<MistakeStoreResource>(
    `/master/education/${masterGroupId}/mistakes/${formId}/${questionId}`,
    { body, method: 'POST', ...options },
  )

// Получение пагинатора курсов
export const MasterKnowledgeCoursesGetRequest = (
  params: MasterKnowledgeCoursesGetParams,
  options?: any,
) =>
  request<KnowledgeCoursesPaginatorResource>('/master/knowledge/courses', {
    method: 'GET',
    params,
    ...options,
  })

// Получение name-value курса
export const MasterKnowledgeCoursesCourseIdGetRequest = (courseId: number, options?: any) => request<NameValueResource>(`/master/knowledge/courses/${courseId}`, { method: 'GET', ...options })

// Получение списка папок курса
export const MasterKnowledgeCourseIdGetRequest = (courseId: number, options?: any) => request<CourseFolderListResource[]>(`/master/knowledge/${courseId}`, { method: 'GET', ...options })

// Получение дерева папки
export const MasterKnowledgeCourseIdTreeCourseFolderIdGetRequest = (courseId: number, courseFolderId: number, options?: any) => request<KnowledgeTreeResource[]>(`/master/knowledge/${courseId}/tree/${courseFolderId}`, { method: 'GET', ...options })

// Получение папки
export const MasterKnowledgeCourseIdTreeCourseFolderIdFolderMediaFolderIdGetRequest = (courseId: number, courseFolderId: number, mediaFolderId: any, options?: any) => request<LargeKnowledgeResource>(`/master/knowledge/${courseId}/tree/${courseFolderId}/folder/${mediaFolderId}`, { method: 'GET', ...options })

// Список актуальных новостей пользователя
export const MasterNewsGetRequest = (options?: any) =>
  request<NewsShortResource[]>('/master/news', { method: 'GET', ...options })

// Получить определённую новость пользователя
export const MasterNewsIdGetRequest = (id: number, options?: any) =>
  request<NewsShortResource>(`/master/news/${id}`, {
    method: 'GET',
    ...options,
  })

// Создание новой заметки
export const MasterNotePostRequest = (body: CreateNoteRequest, options?: any) =>
  request<NoteResource>('/master/note', { body, method: 'POST', ...options })

// Обновление существующей заметки
export const MasterNotePatchRequest = (
  body: UpdateNoteRequest,
  options?: any,
) =>
  request<NoteResource>('/master/note', { body, method: 'PATCH', ...options })

// Получение конкретной заметки
export const MasterNoteNoteIdGetRequest = (noteId: number, options?: any) =>
  request<NoteResource>(`/master/note/${noteId}`, {
    method: 'GET',
    ...options,
  })

// Удаление конкретной заметки
export const MasterNoteNoteIdDeleteRequest = (noteId: number, options?: any) =>
  request<string>(`/master/note/${noteId}`, { method: 'DELETE', ...options })

// Переключение избранности заметки
export const MasterNoteNoteIdFavoritePatchRequest = (
  noteId: number,
  options?: any,
) =>
  request<NoteFavoriteResource>(`/master/note/${noteId}/favorite`, {
    method: 'PATCH',
    ...options,
  })

// Получение всех заметок мастера в пагинаторе
export const MasterNotesGetRequest = (
  params?: MasterNotesGetParams,
  options?: any,
) =>
  request<NoteListResourcePaginator>('/master/notes', {
    method: 'GET',
    params,
    ...options,
  })

// Ссылка на покупка курса
export const MasterOfferLinkGetRequest = (link: string, options?: any) => request<LinkShopResource>(`/master/offer/${link}`, { method: 'GET', ...options })


// Покупка  курса по ссылке с авторизацией
export const MasterOfferAuthLinkPostRequest = (link: string, body: OfferWithAuthRequest, options?: any) => request<MasterOrderLargeResource>(`/master/offer/auth/${link}`, { body, method: 'POST', ...options })

// Покупка  курса по ссылке с регистрацией
export const MasterOfferRegisterLinkPostRequest = (link: string, body: OfferWithRegistrationRequest, options?: any) => request<MasterOrderLargeResource>(`/master/offer/register/${link}`, { body, method: 'POST', ...options })

// Покупка курса по ссылке авторизованным пользователем
export const MasterOfferLinkPostRequest = (link: string, body: OfferStoreRequest, options?: any) => request<MasterOrderLargeResource>(`/master/offer/${link}`, { body, method: 'POST', ...options })

// Список заказов пользователя
export const MasterOrdersGetRequest = (
  params?: MasterOrdersGetParams,
  options?: any,
) =>
  request<MasterOrderShortResourcePaginator>('/master/orders', {
    method: 'GET',
    params,
    ...options,
  })

// Оформление заказа
export const MasterOrdersPostRequest = (
  body: MasterOrderStoreRequest,
  options?: any,
) =>
  request<MasterOrderLargeResource>('/master/orders', {
    body,
    method: 'POST',
    ...options,
  })

// Общая информация по заказам пользовтеля
export const MasterOrdersStatsGetRequest = (options?: any) =>
  request<UserOrderStatsResponse>('/master/orders/stats', {
    method: 'GET',
    ...options,
  })

// Оформление заказа
export const MasterOrdersOrderIdGetRequest = (orderId: number, options?: any) =>
  request<MasterOrderLargeResource>(`/master/orders/${orderId}`, {
    method: 'GET',
    ...options,
  })

// активация промо-кода у заказа
export const MasterOrdersOrderIdCodePostRequest = (
  orderId: number,
  params: MasterOrdersOrderIdCodePostParams,
  options?: any,
) =>
  request<MasterOrderLargeResource>(`/master/orders/${orderId}/code`, {
    method: 'POST',
    params,
    ...options,
  })

// удаление промо-кода у заказа
export const MasterOrdersOrderIdCodeDeleteRequest = (
  orderId: number,
  options?: any,
) =>
  request<MasterOrderLargeResource>(`/master/orders/${orderId}/code`, {
    method: 'DELETE',
    ...options,
  })

// Включение скидки в заказе
export const MasterOrdersOrderIdSaleSaleIdPostRequest = (
  orderId: number,
  saleId: number,
  options?: any,
) =>
  request<MasterOrderLargeResource>(
    `/master/orders/${orderId}/sale/${saleId}`,
    { method: 'POST', ...options },
  )

// Выключение скидки в заказе
export const MasterOrdersOrderIdSaleSaleIdDeleteRequest = (
  orderId: number,
  saleId: number,
  options?: any,
) =>
  request<MasterOrderLargeResource>(
    `/master/orders/${orderId}/sale/${saleId}`,
    { method: 'DELETE', ...options },
  )

// Активация скидок к покупке в заказе
export const MasterOrdersOrderIdSalesPostRequest = (
  orderId: number,
  options?: any,
) =>
  request<MasterOrderLargeResource>(`/master/orders/${orderId}/sales`, {
    method: 'POST',
    ...options,
  })

// Декактивация скидок к покупке в заказе
export const MasterOrdersOrderIdSalesDeleteRequest = (
  orderId: number,
  options?: any,
) =>
  request<MasterOrderLargeResource>(`/master/orders/${orderId}/sales`, {
    method: 'DELETE',
    ...options,
  })

// Запрос для триггера отпаравки новостей в сокет нотификаций
export const MasterSalesNewsGetRequest = (options?: any) => request<any>('/master/sales/news', { method: 'GET', ...options })

// Список уведомлений о новых сообщениях
export const MessagesGetRequest = (options?: any) => request<NotificationResourcePaginator>('/messages', { method: 'GET', ...options })

// Пометить все уведомления о новых сообщениях как прочитанные
export const MessagesReadAllPatchRequest = (options?: any) => request<any>('/messages/read-all', { method: 'PATCH', ...options })

// Список уведомлений (основных)
export const NotificationsGetRequest = (options?: any) =>
  request<NotificationResourcePaginator>('/notifications', {
    method: 'GET',
    ...options,
  })

// Просмотр заказа
export const PaymentOrdersOrderUuidGetRequest = (orderUuid: string, options?: any) => request<MasterOrderLargeResource>(`/payment/orders/${orderUuid}`, { method: 'GET', ...options })

// Переход к оплате заказа
export const PaymentOrdersOrderUuidPayGetRequest = (orderUuid: string, params?: PaymentOrdersOrderUuidPayGetParams, options?: any) => request<any>(`/payment/orders/${orderUuid}/pay`, { method: 'GET', params, ...options })

// Узнать статус заказа
export const PaymentOrdersOrderUuidStatusGetRequest = (orderUuid: string, options?: any) => request<OrderStatusResource>(`/payment/orders/${orderUuid}/status`, { method: 'GET', ...options })

// Отмена автоматического продления курса
export const PaymentRecurrentCancelCourseIdPutRequest = (courseId: number, options?: any) => request<any>(`/payment/recurrent/cancel/${courseId}`, { method: 'PUT', ...options })

// Пометить все уведомления (основные) как прочитанные
export const NotificationsReadAllPatchRequest = (options?: any) => request<any>('/notifications/read-all', { method: 'PATCH', ...options })

// Информация о пользователе
export const ProfileGetRequest = (options?: any) =>
  request<UserLargeResource>('/profile', { method: 'GET', ...options })

// Изменение пользователя
export const ProfilePatchRequest = (body: UserProfile, options?: any) =>
  request<UserLargeResource>('/profile', { body, method: 'PATCH', ...options })

// Привязка профиля Телеграм
export const ProfileAttachTelegramPatchRequest = (body: AuthByTgRequest, options?: any) => request<SocialResource>('/profile/attach-telegram', { body, method: 'PATCH', ...options })

// Информация о курсах/мг пользователя
export const ProfileCoursesGetRequest = (options?: any) =>
  request<UserBoughtGroupResource[]>('/profile/courses', {
    method: 'GET',
    ...options,
  })

// Информация о графике платежей автоматического продления курса
export const ProfileCoursesCourseIdPaymentScheduleGetRequest = (courseId: number, options?: any) => request<CourseRecurringPaymentResource>(`/profile/courses/${courseId}/payment-schedule`, { method: 'GET', ...options })

// Изменение почты пользователя
export const ProfileEmailPatchRequest = (body: UserChangeEmail, options?: any) => request<SelfResource>('/profile/email', { body, method: 'PATCH', ...options })

// Изменение почты без пароля,но с сохранением пароля
export const ProfileEmailCustomPatchRequest = (body: ChangeEmailAndPassword, options?: any) => request<SelfResource>('/profile/email/custom', { body, method: 'PATCH', ...options })

// Информация об уведомлениях пользователя
export const ProfileNotificationsGetRequest = (
  params?: ProfileNotificationsGetParams,
  options?: any,
) =>
  request<UserNotificationResource>('/profile/notifications', {
    method: 'GET',
    params,
    ...options,
  })

// Изменение данных пользователя
export const ProfileNotificationsPatchRequest = (
  body: UserNotificationsRequest,
  params?: ProfileNotificationsPatchParams,
  options?: any,
) =>
  request<UserNotificationResource>('/profile/notifications', {
    body,
    method: 'PATCH',
    params,
    ...options,
  })

// Изменение пароля пользователя
export const ProfilePasswordPatchRequest = (body: UserPassword, options?: any) => request<UserDataResource>('/profile/password', { body, method: 'PATCH', ...options })

// Установка пароля и email если его нет. Используется в случае если пользователь зарегался через ВК.
export const ProfilePasswordPostRequest = (body: UserSetPassword, options?: any) => request<SelfResource>('/profile/password', { body, method: 'POST', ...options })

// Привязка профиля ВК
export const ProfileSocialPatchRequest = (body: VkAuthLogin, options?: any) =>
  request<SocialResource>('/profile/social', {
    body,
    method: 'PATCH',
    ...options,
  })

// Список тематических аватарок (доступен по праздникам)
export const ProfileThematicAvatarListGetRequest = (options?: any) => request<ThematicAvatarResource[]>('/profile/thematic-avatar/list', { method: 'GET', ...options })

// Установить тематическую аватарку (доступен по праздникам)
export const ProfileThematicAvatarSetPutRequest = (body: { thematicAvatarId: number | null }, options?: any) => request<SelfResource>('/profile/thematic-avatar/set', { body, method: 'PUT', ...options })

// Список тикетов в тех. поддержке
export const SupportGetRequest = (params?: SupportGetParams, options?: any) =>
  request<TicketShortResourcePaginator>('/support', {
    method: 'GET',
    params,
    ...options,
  })

// Создание тикета
export const SupportPostRequest = (body: TicketStore, options?: any) =>
  request<TicketShortResource>('/support', {
    body,
    method: 'POST',
    ...options,
  })

// Просмотр тикета и сообщений к нему
export const SupportIdGetRequest = (id: number, options?: any) =>
  request<TicketLargeResource>(`/support/${id}`, { method: 'GET', ...options })

// Закрыть тикет
export const SupportIdClosePostRequest = (id: number, options?: any) =>
  request<TicketLargeResource>(`/support/${id}/close`, {
    method: 'POST',
    ...options,
  })

// Ответ на сообщение
export const SupportIdMessagePostRequest = (
  id: number,
  body: MessageStore,
  options?: any,
) =>
  request<TicketMessageResource>(`/support/${id}/message`, {
    body,
    method: 'POST',
    ...options,
  })

// Загрузка файла
export const UploadPostRequest = (body: UploadFile, options?: any) =>
  request<MediaResource>('/upload', { body, method: 'POST', ...options })

// Загрузка файла по чанкам
export const UploadChunkPostRequest = (body: UploadFileChunk, options?: any) =>
  request<MediaChunkResource>('/upload/chunk', {
    body,
    method: 'POST',
    ...options,
  })

// Информация о пользователе
export const UserGetRequest = (options?: any) =>
  request<SelfResource>('/user', { method: 'GET', ...options })

// Установить флаг для текущего пользователя
export const UserSetFlagPatchRequest = (body: UserFlagStore, options?: any) => request<SelfResource>('/user/set-flag', { body, method: 'PATCH', ...options })
