






























import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import Dialog from '@/components/modals/Dialog.vue'
import KissAnimation from '@/components/animations/KissAnimation.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'

@Component({
  components: {
    Dialog,
    KissAnimation,
    ModalWrapper,
  },
})
export default class LoveModal extends Vue {
  @Prop({ default: false })
  private visible!: boolean

  private handleClose() {
    setTimeout(() => {
      this.$emit('update:visible', false)
      this.$emit('openSelectAvatar')
    }, 3500)
  }
}
