


























import { Component, Prop } from 'vue-property-decorator'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import AvatarList from '@/components/AvatarList.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import ProfileModule from '@/store/modules/profile'
// utils
import { isNewYear, isShowLoveDay } from '@/utils/constants'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    AvatarList,
  },
})
export default class AvatarModal extends NotifyMixin {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ required: true })
  private isUserNewYearModalPassed!: boolean

  @Prop({ required: true })
  private isUserLoveDayModalPassed!: boolean

  private selectedAvatar: number | null = null

  private get isNewYear() {
    return isNewYear
  }

  private get isShowLoveDay() {
    return isShowLoveDay
  }

  private get settings() {
    if (this.isNewYear)
      return {
        skin: 'teal',
        title: 'Выбери новогоднее настроение!',
      }

    if (this.isShowLoveDay)
      return {
        skin: 'pink',
        title: 'Выбери свое настроение!',
      }

    return {
      skin: 'teal',
      title: 'Выбери свое настроение!',
    }
  }

  private mounted() {
    if (!this.isUserNewYearModalPassed)
      AuthModule.completeNewYearModal()
        .catch(this.notifyError)

    if (!this.isUserLoveDayModalPassed)
      AuthModule.completeLoveDayModal()
        .catch(this.notifyError)

    if (!ProfileModule.avatarList.length) {
      ProfileModule.fetchAvatarList()
        .then(() => {
          this.selectedAvatar = ProfileModule.avatarSelected
        })
        .catch(this.notifyError)
    } else {
      this.selectedAvatar = ProfileModule.avatarList.find(avatar => avatar.isSelected)?.id ?? 0
    }
  }

  private handleClose() {
    this.$emit('update:visible', false)
  }

  private handleConfirm() {
    if (!this.selectedAvatar) {
      this.notifyError('Выберите аватар')
    } else {
      ProfileModule.setNewYearAvatar(this.selectedAvatar)
        .then(response => {
          AuthModule.setSelf(response)
          this.$bus.$emit('updateNewYearAvatar', this.selectedAvatar)
          this.handleClose()
        })
        .catch(this.notifyError)
    }
  }
}
