





import { Component, Vue } from 'vue-property-decorator'

/* eslint-disable-next-line */
/* @ts-ignore:next-line */
import * as tsParticles from '@/utils/plugins/tsparticles.engine'

@Component
export default class KissAnimation extends Vue {
  private animation: any = null

  private mounted() {
    this.particlesInit()
  }

  private icon1 = require('/src/assets/images/icons/animations/noto_kiss-mark.png')
  private icon2 = require('/src/assets/images/icons/animations/noto_kiss-mark_2.png')
  private icon3 = require('/src/assets/images/icons/animations/noto_kiss-mark_3.png')
  private icon4 = require('/src/assets/images/icons/animations/noto_kiss-mark_4.png')
  private icon5 = require('/src/assets/images/icons/animations/noto_kiss-mark_5.png')
  private icon6 = require('/src/assets/images/icons/animations/noto_kiss-mark_6.png')

  private opt: any = {
    fullScreen: {
      zIndex: 500,
    },
    interactivity: {
      detectOn: 'canvas',
      events: {
        onHover: {
          enable: false, // Отключаем взаимодействие при наведении
        },
        onClick: {
          enable: true,
          mode: 'push', // При клике добавляем частицы
        },
        resize: true,
      },
      modes: {
        push: {
          quantity: 30, // Количество частиц при клике
        },
      },
    },
    particles: {
      color: {
        value: ['#FF0000', '#00FF00', '#0000FF'], // Разные цвета частиц
      },
      life: {
        duration: {
          sync: true,
          value: 10,
        },
        count: 1,
      },
      lineLinked: {
        enable: false, // Отключаем соединения между частицами
      },
      move: {
        enable: true,
        speed: 15, // Скорость движения
        direction: 'none',
        random: true,
        straight: false,
        outModes: { default: 'destroy' }, // Частицы уничтожаются при выходе за пределы холста
        attract: {
          enable: false,
        },
      },
      number: {
        value: 0, // Начинаем с 0 частиц
      },
      // opacity: {
      //   value: 1,
      //   random: false,
      //   anim: {
      //     enable: true,
      //     speed: 0.5,
      //     opacity_min: 0,
      //     sync: false,
      //   },
      // },
      opacity: {
        value: {
          min: 0,
          max: 1,
        },
        animation: {
          enable: true,
          speed: 0.5,
          sync: true,
          startValue: 'max',
          count: 1,
          destroy: 'min',
        },
        // count: 0,
        // enable: true,
        // speed: 1,
        // decay: 0.1,
        // delay: 0,
        // sync: false,
        // mode: 'auto',
        // startValue: 'random',
        // destroy: true,
      },
      shape: {
        type: 'image',
        options: {
          image: [
            {
              height: 112,
              particles: {
                opacity: {
                  // value: {
                  //   max: 1,
                  //   min: 0.1,
                  // },
                  // value: 1,
                },
                size: {
                  value: {
                    max: 132,
                    min: 30,
                  },
                },
              },
              src: this.icon1,
              width: 112,
            },
            {
              height: 58,
              particles: {
                opacity: {
                  // value: {
                  //   max: 1,
                  //   min: 0.1,
                  // },
                  // value: 1,
                },
                size: {
                  value: {
                    max: 78,
                    min: 30,
                  },
                },
              },
              src: this.icon2,
              width: 58,
            },
            {
              height: 54,
              particles: {
                opacity: {
                  // value: {
                  //   max: 1,
                  //   min: 0.1,
                  // },
                  // value: 1,
                },
                size: {
                  value: {
                    max: 74,
                    min: 30,
                  },
                },
              },
              src: this.icon3,
              width: 54,
            },
            {
              height: 84,
              particles: {
                opacity: {
                  // value: {
                  //   max: 1,
                  //   min: 0.1,
                  // },
                  // value: 1,
                },
                size: {
                  value: {
                    max: 104,
                    min: 30,
                  },
                },
              },
              src: this.icon4,
              width: 84,
            },
            {
              height: 86,
              particles: {
                opacity: {
                  // value: {
                  //   max: 1,
                  //   min: 0.1,
                  // },
                  // value: 1,
                },
                size: {
                  value: {
                    max: 106,
                    min: 30,
                  },
                },
              },
              src: this.icon5,
              width: 86,
            },
            {
              height: 38,
              particles: {
                opacity: {
                  // value: {
                  //   max: 1,
                  //   min: 0.1,
                  // },
                  // value: 1,
                },
                size: {
                  value: {
                    max: 58,
                    min: 30,
                  },
                },
              },
              src: this.icon6,
              width: 38,
            },
          ],
        },
      },
      size: {
        value: {
          min: 50,
          max: 100,
        },
        random: false,
        animation: {
          enable: true,
          speed: 20,
          sync: true,
          startValue: 'min',
          destroy: 'max,',
          mode: 'increase',
        },
      },
    },
    retina_detect: true,
  }

  private async particlesInit() {
    await tsParticles.loadFull(tsParticles.tsParticles)

    this.animation = await tsParticles.tsParticles.load({
      id: 'tsparticles-kiss',
      options: this.opt,
    })
  }
}
